import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import next1 from "../../assets/img/partials/next-1.png";
import LayoutTwo from "../../layouts/LayoutTwo";

class ParaMedicion extends Component {
    render() {

        return (
            <>
                <LayoutTwo></LayoutTwo>
                <div class="container-fluid mx-auto">
                    <div class="row">
                        <div class="col s11 m11 l11">
                            <ul class="collapsible">
                                <li class="active tab1 bg-four">
                                    <div class="collapsible-header bg-four">
                                        <div className="flex">
                                            <h3 className="font-bold fr text-primary title-4 pad30">Parametros de
                                                medición</h3>
                                        </div>
                                    </div>
                                    <div class="collapsible-body">
                                        <div class="row pad40">
                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="card-white">
                                                        <div class="box-form">
                                                            <p class="title-18n text-primary">Suelo</p>
                                                            <div class="input-field">
                                                                <input id="ph" class="input-2" type="text"/>
                                                                <label for="ph">PH: </label>
                                                            </div>
                                                            <div class="input-field">
                                                                <input id="conduct" className="input-2" type="text"/>
                                                                <label for="conduct">Conductividad: </label>
                                                            </div>
                                                            <div class="input-field">
                                                                <input id="hum" className="input-8" type="text"/>
                                                                <label for="hum">Humedad : </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="card-white">
                                                        <div class="box-form">
                                                            <p class="title-18n text-primary ">Sustrato</p>
                                                            <div class="input-field">
                                                                <input id="ph1" class="input-2" type="text"/>
                                                                <label for="ph1">PH: </label>
                                                            </div>
                                                            <div class="input-field">
                                                                <input id="conduc" className="input-2" type="text"/>
                                                                <label for="conduc">Conductividad: </label>
                                                            </div>
                                                            <div class="input-field">
                                                                <input id="vd" className="input-7" type="text"/>
                                                                <label for="vd">Volumen del drenaje: </label>
                                                            </div>
                                                            <div class="input-field">
                                                                <input id="vf" className="input-7" type="text"/>
                                                                <label for="vf">Volumen de fibra: </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row  pad30">
                                            <div class="col s2 m2 l2">
                                                <div class="box-padding-30">
                                                    <p class="text-primary">Adjuntar testigos</p>
                                                </div>
                                            </div>
                                            <div class="col s6 m6 l6">
                                                <div class="col s2 m2 l2">
                                                    <div class="box-padding-30">
                                                        <label class="label">
                                                            <i class="material-icons p-2">add</i>
                                                            <input type="file"/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col s2 m2 l2">
                                                    <div class="box-padding-30">
                                                        <label class="label">
                                                            <i class="material-icons p-2">add</i>
                                                            <input type="file"/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col s2 m2 l2">
                                                    <div class="box-padding-30">
                                                        <label class="label">
                                                            <i class="material-icons p-2">add</i>
                                                            <input type="file"/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col s2 m2 l2">
                                                    <div class="box-padding-30">
                                                        <label class="label">
                                                            <i class="material-icons p-2">add</i>
                                                            <input type="file"/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="tab2 bg-six">
                                    <div class="collapsible-header bg-six">
                                        <div className="flex">
                                            <h3 className="font-bold fl text-primary title-4 pad30">Análisis
                                                fisicoquímicos</h3>
                                        </div>
                                    </div>
                                    <div class="collapsible-body">
                                        <div class="mleft30">
                                            <div className="flex">
                                                <p class="title-5 text-primary">Selecciona un recuadro para adjuntar
                                                    documentos con los resultados de los análisis, puedes pasar a la
                                                    siguiente sección y terminar este parte posteriormente.</p>
                                            </div>
                                        </div>
                                        <div class="row pad50">
                                            <div class="col s6 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Agua</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Fibra</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Suelo</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Fertilidad</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="tab3 bg-seven">
                                    <div class="collapsible-header bg-seven">
                                        <div className="flex">
                                            <h3 className="font-bold fl text-primary title-4 pad30">Análisis
                                                fitopatológicos</h3>
                                        </div>
                                    </div>
                                    <div class="collapsible-body">
                                        <div class="mleft30">
                                            <div className="flex">
                                                <p class="title-5 text-primary">Selecciona un recuadro para adjuntar
                                                    documentos con los resultados de los análisis, puedes pasar a la
                                                    siguiente sección y terminar este parte posteriormente. </p>
                                            </div>
                                        </div>
                                        <div class="row pad50">
                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Agua</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary">Planta</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s12 m6 l6">
                                                <div className="box-space">
                                                    <div class="row">
                                                        <div class="col s2 m2 l2">
                                                            <div class="pad30">
                                                                <p class="text-primary"> Suelo</p>
                                                            </div>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                        <div class="col s2 m2 l2">
                                                            <label class="label">
                                                                <i class="material-icons p-2">add</i>
                                                                <input type="file"/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <Link to="/parametro-medicion">
                            <div class="superponer">
                                <img src={next1} className="next-1"/>
                            </div>
                        </Link>
                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(ParaMedicion)