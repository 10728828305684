import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '10%',
      left                  : '20%',
      right                 : '20%',  
      background            : '#90ec90'
    }
  };

class AppPlagas extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            products : [],
            tipo_aplicacion: '',
            efecto:'',
            etapa: '',
            mes: '',
            no_aplicacion:'',
            semana:'',
            tcultivo: '',
            cultivos: [],
            cultivo: '',
            id:'',
            editing: false,
            item: '',
            control: false,
            list: [],
            open: 0,
            plings: [],
            inputList: [{
                dosis_por_HA: "", 
                unidad_medida_dosis_por_HA: "",
                intervalo_seguridad: "",
                kg_lt_tambo: "",
                tambos_por_HA:"",
                plagas_enfermedades_id: "",
                producto_nutricion_id: "" 
            }]
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
                
        const res = await fetch(`${url_api}aplicacion_plagas_enfermedades/`, options);
        
        const apps = await res.json();
        
        if(!apps.detail){     
            this.setState({apps:apps}) 
            fetch(`${url_api}productos_nutricion/`, options)
                .then(res => res.json())
                .then(products => this.setState({products}))  
            
            fetch(`${url_api}cultivos/`, options)
                .then(res => res.json())
                .then(cultivos => this.setState({cultivos}))
        }
        

          
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.inputList];
        list[index][name] = value;
        this.setState({inputList: list});
    };
     
    // handle click event of the Remove button
    handleRemoveClick = index => {
        const list = [...this.state.inputList];
        list.splice(index, 1);
        console.log(list);
        this.setState({inputList: list});
    };
     
    // handle click event of the Add button
    handleAddClick = () => {
        this.setState((prevState) => ({
            inputList: [...prevState.inputList, {
                dosis_por_HA: "", 
                unidad_medida_dosis_por_HA: "",
                intervalo_seguridad: "",
                kg_lt_tambo: "",
                tambos_por_HA:"",
                plagas_enfermedades_id: "",
                producto_nutricion_id: "" 
            }],
        }));
    };

    //crear y editar
    submit= async (e) =>{
        e.preventDefault();
        if (this.state.editing===false) {
            const res = await fetch(`${url_api}aplicacion_plagas_enfermedades/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    tipo_aplicacion: this.state.tipo_aplicacion,
                    efecto: this.state.efecto,
                    etapa: this.state.etapa,
                    mes: this.state.mes,
                    no_aplicacion: this.state.no_aplicacion,
                    semana: this.state.semana,
                    cultivo_id: this.state.tcultivo,
                    plagas_enfermedades_ingredientes: this.state.inputList
                })
            }) 
            const data = await res.json();
            console.log(data)
            if(data.id){
                let timerInterval
                MySwal.fire({
                    title: 'Guardando Aplicación...',
                    showConfirmButton: false,
                    timer: 2000,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Creo Correctamente la Aplicación Plagas y Enfermedades',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                },
                            })                        
                        }
                })
                this.componentDidMount();

                this.setState({
                    tipo_aplicacion: '',
                    efecto:'',
                    etapa: '',
                    mes: '',
                    no_aplicacion:'',
                    semana:'',
                    tcultivo: ''
                })
        
                this.setState({
                    inputList: [{
                        dosis_por_HA: "", 
                        unidad_medida_dosis_por_HA: "",
                        intervalo_seguridad: "",
                        kg_lt_tambo: "",
                        tambos_por_HA:"",
                        plagas_enfermedades_id: "",
                        producto_nutricion_id: "" 
                    }]
                })
            }else{
                MySwal.fire({
                    icon: 'warning',
                    title: 'Revisa tus Datos',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                    },
                  })
            } 
        } else {
            const id=this.state.id;
            
            const res = await fetch(`${url_api}aplicacion_plagas_enfermedades/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    tipo_aplicacion: this.state.tipo_aplicacion,
                    efecto: this.state.efecto,
                    etapa: this.state.etapa,
                    mes: this.state.mes,
                    no_aplicacion: this.state.no_aplicacion,
                    semana: this.state.semana,
                    cultivo_id: this.state.tcultivo,
                    plagas_enfermedades_ingredientes: this.state.inputList
                }),
              });
              const data = await res.json();
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Actualizando Aplicación...',
                      showConfirmButton: false,
                      timer: 2000,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Actualizo Correctamente la Aplicación Plagas y Enfermedades',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                  },
                              })                        
                          }
                  })
                  this.componentDidMount();

                   this.setState({
                        tipo_aplicacion: '',
                        efecto:'',
                        etapa: '',
                        mes: '',
                        no_aplicacion:'',
                        semana:'',
                        tcultivo: ''
                    })
            
                    this.setState({
                        inputList: [{
                            dosis_por_HA: "", 
                            unidad_medida_dosis_por_HA: "",
                            intervalo_seguridad: "",
                            kg_lt_tambo: "",
                            tambos_por_HA:"",
                            plagas_enfermedades_id: "",
                            producto_nutricion_id: "" 
                        }]
                    })
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: 'Revisa tus Datos',
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                      },
                    })
              }
            console.log(data);
            this.state.editing = false;
            this.state.id= "";
        }     
        

        
    }

    // muetra datos para editar
    editNutricion = async (id) => {
        
        const res = await fetch(`${url_api}aplicacion_plagas_enfermedades/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.control = true;
        this.state.id= id;
        const list = data.plagas_enfermedades_ingredientes;
        
        this.setState({
            tipo_aplicacion: data.tipo_aplicacion,
            efecto: data.efecto,
            etapa: data.etapa,
            mes: data.mes,
            no_aplicacion: data.no_aplicacion,
            semana: data.semana,
            tcultivo: data.cultivo.id
        })
        /*let list = this.state.plings.filter(item_ing => item_ing.plagas_enfermedades_id == data.id);*/

        list.map((x) => {
            var id = x.producto.id;
            
            x.producto_id=id;
        }) 
        
       console.log(list);

        this.setState({inputList: list, list: list})
       
     
        
    };

    //elimina datos
    deleteNutricion = async (id) => {
        this.state.control = true;
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}aplicacion_plagas_enfermedades/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                
                MySwal.fire({
                    icon: 'Success',
                    title: 'Eliminado!',
                    text: 'El Registro ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  });
                this.componentDidMount();
            }
            this.componentDidMount();
          })
          
    };

    //muestra elemento
    getNutricion = async (id) => {
        const res = await fetch(`${url_api}aplicacion_plagas_enfermedades/${id}/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const item = await res.json(); 
        
        const list = item.plagas_enfermedades_ingredientes;
        const cultivo = item.cultivo;
        /*let list = this.state.plings.filter(item_ing => item_ing.plagas_enfermedades_id == item.id);
        
        list.map((x) => {
            let listit = this.state.products.filter(product => product.id == x.producto);
               
            let id= x.producto;
            x.productob = listit ;  

        })*/

        this.setState({item: item, list: list, open: id, cultivo:cultivo});
        if(this.state.control === false){
            this.setState({ showModal: true });
        }else{
            this.state.control = false;
        } 
        
    };
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render() {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Aplicaciones Plagas y Enfermedades </h2>
                    <div className="grid grid-cols-12 gap-4 ">
                        <div className="bg-white col-span-12 rounded ">
                            <div className="form bg-white rounded shadow-md py-8">
                                <h3 className="font-bold text-primary m-4">Crear Aplicación Plagas y Enfermedades</h3>
                                <form onSubmit={this.submit}>
                                    <div className=" form bg-white rounded grid grid-cols-3 gap-4 my-2 mx-6 overflow-hidden flex">
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Tipo Aplicación:   </label>
                                            <input
                                                id="tipo_aplicacion"
                                                name="tipo_aplicacion"
                                                type="text"
                                                value={this.state.tipo_aplicacion} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Tipo aplicacion ej. Aplicación de drench"
                                                required
                                                title="Favor de Agregar el Tipo de Aplicación. ej. Aplicación de drench"
                                               
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Efecto:   </label>
                                            <input
                                                id="efecto"
                                                name="efecto"
                                                type="text"
                                                value={this.state.efecto} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Efecto ej. Maleza"
                                                required
                                                title="Favor de Agregar el Efecto de la Aplicación. ej. Maleza"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Etapa:   </label>
                                            <input
                                                id="etapa"
                                                name="etapa"
                                                type="text"
                                                value={this.state.etapa} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Etapa ej. Plantación"
                                                required
                                                title="Favor de Agregar la Etapa de la Aplicación. ej. Plantación"
                                            />                                                
                                        </div>
                                    </div>
                                    <div className=" form bg-white rounded grid grid-cols-3 gap-4 my-2 mx-6 overflow-hidden flex">    
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Mes:   </label>
                                            <input
                                                id="mes"
                                                name="mes"
                                                type="text"
                                                value={this.state.mes} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Fecha ej. 2021-02-25"
                                                required
                                                title="Favor de Agregar la Fecha de la Aplicación. ej. 2021-02-25"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">No. Aplicacion:   </label>
                                            <input
                                                id="no_aplicacion"
                                                name="no_aplicacion"
                                                type="text"
                                                value={this.state.no_aplicacion} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="No. Aplicación ej. 1"
                                                required
                                                title="Favor de Agregar No. de Aplicación . ej. 1"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Tipo Cultivo:   </label>
                                            <select id="tcultivo" name="tcultivo" value={this.state.tcultivo}  onChange={this.handleChange} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                title="Favor de Seleccionar un Cultivo de la Lista">
                                                <option value="">Seleciona un Cultivo</option>
                                                {this.state.cultivos.map((cultivo)=> {
                                                    return (
                                                    <option key={cultivo.id} value={cultivo.id}>{cultivo.nombre}</option>
                                                    );
                                                })}
                                            </select>                                                 
                                        </div>
                                    </div>

                                    <div className="flex  ">
                                        <table  className="form bg-white rounded overflow-hidden inline">
                                            <thead>
                                                <tr className="table-res bg-green-300">
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Dosis por HA</th>
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Unidad medida dosis por HA</th>
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Intervalo Seguridad</th>
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">kg lt Tambo</th>
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Tambos por HA</th>
                                                    <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">producto</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="flex-col items-center justify-between overflow-y-scroll w-full">
                                                {this.state.inputList.map((x, i) => {
                                                    return (
                                                        <tr key={i} className="border-none" >
                                                            <td className="nut-table-ing">
                                                                <input
                                                                    id="dosis_por_HA"
                                                                    name="dosis_por_HA"
                                                                    type="text"
                                                                    value={x.dosis_por_HA} 
                                                                    onChange={e => this.handleInputChange(e, i)}
                                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                    placeholder="Dosis por Hectárea ej. 12.0"
                                                                    required                                                                    
                                                                    title="Favor de Agregar la Dosis por Hectárea. ej. 12.0"
                                                                />  
                                                            </td>  
                                                            <td className="nut-table-ing">
                                                                <input
                                                                    id="unidad_medida_dosis_por_HA"
                                                                    name="unidad_medida_dosis_por_HA"
                                                                    type="text"
                                                                    value={x.unidad_medida_dosis_por_HA} 
                                                                    onChange={e => this.handleInputChange(e, i)}
                                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                    placeholder="Unidad medida dosis por Hectárea ej. lt"
                                                                    required                                                                    
                                                                    title="Favor de Agregar la Unidad medida dosis por Hectárea. ej. lt"
                                                                /> 
                                                            </td>  
                                                            <td className="nut-table-ing">
                                                                <input
                                                                    id="intervalo_seguridad"
                                                                    name="intervalo_seguridad"
                                                                    type="text"
                                                                    value={x.intervalo_seguridad} 
                                                                    onChange={e => this.handleInputChange(e, i)}
                                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                    placeholder="Intervalo Seguridad ej. 12 Días"                                                           
                                                                    required
                                                                    title="Favor de Agregar el Intervalo de Seguridad ej. 12 Días"
                                                                /> 
                                                            </td>   
                                                            <td className="nut-table-ing">
                                                                <input
                                                                    id="kg_lt_tambo"
                                                                    name="kg_lt_tambo"
                                                                    type="text"
                                                                    value={x.kg_lt_tambo} 
                                                                    onChange={e => this.handleInputChange(e, i)}
                                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                    placeholder="kg lt Tambo ej. 0.5"                                                           
                                                                    required
                                                                    title="Favor de Agregar los kg lt por Tambo ej. 0.5"
                                                                />
                                                            </td>   
                                                            <td className="nut-table-ing">
                                                                <input
                                                                    id="tambos_por_HA"
                                                                    name="tambos_por_HA"
                                                                    type="text"
                                                                    value={x.tambos_por_HA} 
                                                                    onChange={e => this.handleInputChange(e, i)}
                                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                    placeholder="Tambos por Hectárea ej. 2.5"                                                           
                                                                    required
                                                                    title="Favor de Agregar los Tambos por Hectárea ej. 2.5"
                                                                />  
                                                            </td>   
                                                            <td className="nut-table-ing">
                                                                <select id="producto_id" name="producto_id" value={x.producto_id} onChange={e => this.handleInputChange(e, i)} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                title="Favor de Seleccionar un Producto de la Lista">
                                                                    <option value="">Seleciona un Producto</option>
                                                                    {this.state.products.map((product)=> {
                                                                        return (
                                                                        <option key={product.id} value={product.id}>{product.nombre}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </td>  
                                                            <td className="nut-table-ing">
                                                                {this.state.inputList.length !== 1 && <button
                                                                className="inline-flex bg-red-600 text-white rounded-full h-8 px-3 py-2 m-1 justify-center items-center "
                                                                onClick={() => this.handleRemoveClick(i)}>x</button>}
                                                            </td>    
                                                        </tr>
                                                        
                                                    );
                                                    
                                                })}    
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <div className="p-4 btn-box flex">
                                        {this.state.inputList.map((x, i) => {
                                            return (
                                                <div key={i}>
                                                    {this.state.inputList.length - 1 === i && <button className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-blue-400  shadow ripple hover:shadow-lg hover:bg-blue-600 focus:outline-none" onClick={this.handleAddClick}>Agregar Ingredentes</button>}
                                                </div>
                                            );
                                        })}
                                        <div>
                                            <button type="submit" className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none">{this.state.editing ? "Actualizar Aplicación" : "Guardar Aplicación"}</button>
                                        </div>
                                    </div>
                                </form>
                                    <Modal 
                                    isOpen={this.state.showModal}
                                    ariaHideApp={false}
                                    contentLabel="Modal"
                                    onRequestClose={this.handleCloseModal}
                                    style={customStyles}
                                    >
                                    <div  className="flex justify-between bg-white">
                                        <h3 className="font-bold text-primary m-4">Detalles Plagas y Enfermedades</h3>
                                        <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 m-4" onClick={this.handleCloseModal}>Cerrar</button>
                                    </div>    
                                    
                                    <div className="shadow-md divide-y divide-green-400 bg-white">
                                        <div className=" form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Tipo Aplicación: </h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.tipo_aplicacion}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Efecto:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.efecto}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Etapa:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.etapa}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Mes:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.mes}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">No. Aplicacion:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.no_aplicacion}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Semana:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.semana}</p></div>
                                            <div className="flex"><h6 className="pl-title my-1 mx-2">Tipo Cultivo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.cultivo.nombre}</p></div>
                                        </div>
                                        
                                        <div className="form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <table className="">
                                                <thead>
                                                    <tr className="table-res bg-green-300 ">
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Dosis por HA</th>
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Unidad medida dosis por HA</th>
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Intervalo Seguridad</th>
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">kg lt Tambo</th>
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Tambos por HA</th>
                                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">producto</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="flex-col justify-between overflow-y-scroll w-full">
                                                    {this.state.list.map((x, i) => {
                                                        return (  
                                                            <tr key={x.id} className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                    {x.dosis_por_HA} 
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.unidad_medida_dosis_por_HA}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.intervalo_seguridad}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.kg_lt_tambo}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.tambos_por_HA}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    
                                                                    
                                                                    {x.producto.nombre}
                                                                </td>
                                                            </tr>
                                                    
                                                        );
                                                                        
                                                    })}     
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Modal> 
                            </div>
                        </div>
                        <div className="col-span-12 mb-20">
                            <h3 className="font-bold text-primary m-4">Lista de Aplicaciones Plagas y Enfermedades</h3><br></br>
                            <div className="col-span-12 grid grid-cols-4 gap-4">
                                {this.state.apps.map((app, i)=> {
                                    return (
                                        <a title="Oprime para Ver los Detalles" onClick={(e) => this.getNutricion(app.id)}  key={app.id} id={app.id} className={`cursor-pointer bg-white rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32 ${this.state.open === app.id ? 'activeclass' : ''}`}>
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Etapa  Fenologica</h6>
                                                <p className="pl-value my-1">{app.etapa} </p>
                                                <h6 className="pl-title my-1 mt-2">No. Semana</h6>
                                                <p className="pl-value nmy-1">{app.semana}</p>
                                            </div>
                                            <div  className="col-span-6">
                                                <h6 className="pl-value my-1">Mes</h6>
                                                <p className="pl-value my-1">{app.mes}</p>
                                                <div>
                                                <button onClick={(e) => this.editNutricion(app.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteNutricion(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })}  
                            </div>  
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(AppPlagas))

