import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

import infoMedi from "../../assets/img/partials/info-medicion.svg";
import next1 from "../../assets/img/partials/next-1.png";
import LayoutTwo from "../../layouts/LayoutTwo";
import infoCulti from "../../assets/img/partials/info-culti.svg";

class InfoCultivo extends Component {

    // por revisar

    /*  constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth
        };

        this.submit = this.submit.bind(this);
        }

        submit(event) {
        event.preventDefault()
       }*/

    render() {

        return (
            <>
                <LayoutTwo></LayoutTwo>
                <div class="container mx-auto">
                    <div class="row">
                        <div class="col s11 m12 l12">
                            <div className="flex">
                                <h3 className="font-bold fr text-primary title-1">Medición: 10 Enero 2020</h3>
                            </div>
                            <div className="flex justify-center">
                                <img src={infoMedi} className=""/>
                                <p className="med-1">6thr</p>
                                <p className="med-2">6 m</p>
                                <p className="med-3">63</p>
                                <p className="med-4">64</p>
                                <p className="med-5">65</p>
                                <p className="med-6">66</p>
                                <p className="med-7">67</p>
                                <p className="med-8">68</p>
                                <p className="med-9">69</p>
                                <p className="med-10">70</p>
                                <p className="med-11">88</p>
                            </div>
                        </div>
                        <div class="col s12 m12 l12">

                            <div class="row pad40">

                                <div class="col s12 m4 l4">
                                    <div className="flex justify-center">
                                        <img src={infoCulti} class="plant1"/>
                                        <div class="card-medicion">
                                            <div className="box-padding-50">
                                                <p class="text-six title-18">Fresa</p>
                                            </div>
                                            <div className="box-padding-20">
                                                <p class="text-six title-14">Origen francés. Precoz. Fruto medio grueso,
                                                    cónico alargado, color rojo.</p>
                                            </div>
                                            <div className="box-space-sm flex justify-center">
                                                <button type="submit" class="outline-yellow">Opcion 1</button>
                                            </div>
                                            <div className="box-space-sm flex justify-center">
                                                <button type="submit" class="outline-yellow">Opcion 1</button>
                                            </div>
                                            <div className="box-space-sm flex justify-center">
                                                <button type="submit" class="outline-yellow">Opcion 1</button>
                                            </div>
                                            <div className="box-space-sm flex justify-center">
                                                <button type="submit" class="outline-yellow">Opcion 1</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m8 l8">

                                    <div className="form-layout">
                                        <div className="box-space">
                                            <span className="font-bold text-primary title-3">Temporada</span>
                                            <div class="row">
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 1</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 2</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 3</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 4</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="box-space">
                                            <span className="font-bold text-primary title-3">Cultivo</span>
                                            <div class="row">
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 1</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 2</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 3</button>
                                                    </div>
                                                </div>
                                                <div class="col s6 m3 l3">
                                                    <div className="box-space-sm">
                                                        <button type="submit" class="outline">Opcion 4</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="box-space">
                                            <span className="font-bold text-primary title-3">Sectores</span>
                                            <div class="row">
                                                <div class="input-field col s6 m4 l4">
                                                    <input id="medio" className="input-2" type="text"/>
                                                    <label for="medio">Medio : </label>
                                                </div>

                                                <div class="input-field col s6 m4 l4">
                                                    <input id="suelo" className="input-2" type="text"/>
                                                    <label for="suelo">Suelo : </label>
                                                </div>

                                                <div class="input-field col s6 m4 l4">
                                                    <input id="sustrato" className="input-2" type="text"/>
                                                    <label for="sustrato">Sustrato : </label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="box-space">
                                            <span className="font-bold text-primary title-3">Fechas</span>
                                            <div class="row">
                                                <div class="input-field col s6 m6 l6">
                                                    <div className="box-space-sm">
                                                        <input id="plantacion" className="input-3" type="date"/>
                                                    </div>
                                                    <label for="plantacion">Plantacion : </label>
                                                </div>

                                                <div class="input-field col s6 m6 l6">
                                                    <div className="box-space-sm">
                                                        <input id="poda1" className="input-3" type="date"/>
                                                    </div>
                                                    <label for="poda1">Poda : </label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="box-space">
                                            <div class="row">
                                                <div class="col s12 m6 l6">
                                                    <div className="justify-center">
                                                        <span
                                                            className="font-bold text-primary title-3">Superficie</span>
                                                        <div class="input-field col s12">
                                                            <div className="box-space-sm">
                                                                <input id="plantacion" className="input-3" type="text"/>
                                                            </div>
                                                            <label for="plantacion">Plantacion : </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col s12 m6 l6">
                                                    <div className="justify-center">
                                                        <span className="font-bold text-primary title-3">Plantas por hectárea</span>
                                                        <div class="input-field col s12">
                                                            <div className="box-space-sm">
                                                                <input id="poda" className="input-3" type="text"/>
                                                            </div>
                                                            <label for="poda">Poda : </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="box-space">
                                            <div class="row">
                                                <div class="col s12 m6 l6">
                                                    <div className="justify-center">
                                                        <span className="font-bold text-primary title-3">Distancia entre plantas</span>
                                                        <div class="input-field col s12">
                                                            <div className="box-space-sm">
                                                                <input id="plantacion" className="input-3" type="text"/>
                                                            </div>
                                                            <label for="plantacion">Plantacion : </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col s12 m6 l6">
                                                    <div className="justify-center">
                                                        <span className="font-bold text-primary title-3">Distancia entre camas</span>
                                                        <div class="input-field col s12">
                                                            <div className="box-space-sm">
                                                                <input id="poda2" className="input-3" type="text"/>
                                                            </div>
                                                            <label for="poda2">Poda : </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col s12 m12 l12">
                                                    <div className="justify-center">
                                                        <span
                                                            className="font-bold text-primary title-3">Largo de cama</span>
                                                        <div class="input-field col s12">
                                                            <div className="box-space-sm">
                                                                <input id="poda3" className="input-5" type="text"/>
                                                            </div>
                                                            <label for="poda3">Poda : </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <Link to="/sistema-riego">
                    <div className="">
                        <img src={next1} className="next-1"/>
                    </div>
                </Link>
            </>
        )
    }
}

export default withRouter(InfoCultivo)