import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import axios from 'axios';
import paisaje from '../../assets/img/menu/paisaje.png';
import {url_api} from "../../constants";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '5%',
      left                  : '10%',
      right                 : '10%',
      background            : '#90ec90'
    }
  };

class Nutrientes extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            cultivos:[],
            cultivo:'',

            nombre: '',
            etapa: '',
            deficiencia: '',
            deficiencia_img: '',
            imagePreviewUrlDef: '',
            exceso: '',
            exceso_img: '',
            imagePreviewUrlExc: '',
            cultivo_id: '',

            id:'',
            editing: false,

            item: '',
            control: false,
            list: [],

            open: 0,

            img1: '',
            img2: '', 
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);        
        this.handleImageDef = this.handleImageDef.bind(this);       
        this.handleImageExc = this.handleImageExc.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }

        const res = await fetch(`${url_api}nutrientes/`, options);
    
        const apps = await res.json();
        if(!apps.detail){    
            const cul = await fetch(`${url_api}cultivos/`, options);
            const cultivos = await cul.json(); 

            this.setState({apps:apps,cultivos:cultivos}) 
        }              
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }
    handleImageDef(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let file = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            deficiencia_img: file,
            imagePreviewUrlDef: reader.result
          });
        }
    
        reader.readAsDataURL(file)
    }

    handleImageExc(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let fileo = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            exceso_img: fileo,
            imagePreviewUrlExc: reader.result
          });
        }
    
        reader.readAsDataURL(fileo)
    }
    
    clickImage(e) {
        if(e === '1'){
            document.querySelector('#foto1').click();
        }else{
            document.querySelector('#foto2').click();
         }
    }

  
    //crear y editar
    submit= async (e) =>{
        e.preventDefault();
       
            if (this.state.editing===false) {
                    let form_data = new FormData();
                    form_data.append('deficiencia_img', this.state.deficiencia_img);
                    form_data.append('exceso_img', this.state.exceso_img);
                    form_data.append('nombre', this.state.nombre);
                    form_data.append('etapa', this.state.etapa);
                    form_data.append('deficiencia', this.state.deficiencia);
                    form_data.append('exceso', this.state.exceso);
                    form_data.append('cultivo_id', this.state.cultivo_id);

                    axios.post(`${url_api}nutrientes/`, form_data, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': `Token ${this.props.token}`
                        }
                    })
                    .then(res => {
                       let timerInterval
                       MySwal.fire({
                           title: 'Guardando Registro...',
                           showConfirmButton: false,
                           timer: 2000,
                           buttonsStyling: false,
                           customClass: {
                               title:'title-class',
                               popup:'popup-class',
                           },
                           
                           willOpen: () => {
                               MySwal.showLoading()
                                                      
                           },
                           willClose: () => {
                               clearInterval(timerInterval)
                           }
                       }).then((result) => {
                               if (
                                   result.dismiss === MySwal.DismissReason.timer
                               ) {
                                   MySwal.fire({
                                       icon: 'success',
                                       title: 'Se Creo Correctamente el Registro de Nutrientes',
                                       showConfirmButton: true,
                                       buttonsStyling: false,
                                       customClass: {
                                           title:'title-class',
                                           popup:'popup-class',
                                           confirmButton: 'confirmbutton-class',
                                       },
                                   })                        
                               }
                       })
                       
                
                        this.componentDidMount();
                        this.setState({
                            nombre: '',
                            etapa: '',
                            deficiencia: '',
                            deficiencia_img: '',
                            imagePreviewUrlDef: '',
                            exceso: '',
                            exceso_img: '',
                            imagePreviewUrlExc: '',
                            cultivo_id: '',
                        })
                       
                    }).catch(
                       function (error) {
                           console.log(error.response);
                           MySwal.fire({
                               icon: 'warning',
                               title: 'Algo Salio mal!!',
                               text: [error.response.data.nombre],
                               showConfirmButton: true,
                               buttonsStyling: false,
                               customClass: {
                                   title:'title-class',
                                   popup:'popup-class',
                                   confirmButton: 'confirmbutton-class',
                               },
                             })
                       }
                     )
                    
            } else {
                const id=this.state.id;
                const object = 'object';

                
                const a = this.state.deficiencia_img;
                if (a != null){
                    const type =typeof (this.state.deficiencia_img);
                    console.log('a',type);
                    if (type === object){this.state.img1 = this.state.deficiencia_img;}
                }
                
                
                const b = this.state.exceso_img;
                if (b != null){
                    const typeb =typeof (this.state.exceso_img);
                    console.log('b',typeb);
                    if (typeb === object){this.state.img2 = this.state.exceso_img;}
                }
                let form_data = new FormData();
                form_data.append('nombre', this.state.nombre);
                form_data.append('deficiencia_img', this.state.img1);
                form_data.append('deficiencia', this.state.deficiencia);
                form_data.append('exceso_img', this.state.img2); 
                form_data.append('exceso', this.state.exceso);
                form_data.append('etapa', this.state.etapa);
                form_data.append('cultivo_id', this.state.cultivo_id);
                
                axios.put(`${url_api}nutrientes/${id}/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Token ${this.props.token}`
                    }
                }).then(res => {
                    console.log(res);
                   let timerInterval
                   MySwal.fire({
                       title: 'Actualizando Registro...',
                       showConfirmButton: false,
                       timer: 2000,
                       customClass: {
                           title:'title-class',
                           popup:'popup-class',
                       },
                       willOpen: () => {
                           MySwal.showLoading()
                                                  
                       },
                       willClose: () => {
                           clearInterval(timerInterval)
                       }
                   }).then((result) => {
                           if (
                               result.dismiss === MySwal.DismissReason.timer
                           ) {
                               MySwal.fire({
                                   icon: 'success',
                                   title: 'Se Actualizo Correctamente',
                                   showConfirmButton: true,
                                   buttonsStyling: false,
                                   customClass: {
                                       title:'title-class',
                                       popup:'popup-class',
                                       confirmButton: 'confirmbutton-class',
                                   },
                               })                        
                           }
                   })
                   
            
                   this.componentDidMount();
                   this.setState({
                        nombre: '',
                        etapa: '',
                        deficiencia: '',
                        deficiencia_img: '',
                        imagePreviewUrlDef: '',
                        exceso: '',
                        exceso_img: '',
                        imagePreviewUrlExc: '',
                        cultivo_id: '',
                        img1:'',
                        img2:''
                       
                   })
                   
                }).catch(function (error) {
                       console.log(error);
                       
                       MySwal.fire({
                           icon: 'warning',
                           title: [error.response],
                           showConfirmButton: true,
                           buttonsStyling: false,
                           customClass: {
                               title:'title-class',
                               popup:'popup-class',
                               confirmButton: 'confirmbutton-class',
                               cancelButton: 'cancelbutton-class'
                           },
                        })
                        
                        this.editNutrientes(id);                
                   }.bind(this)
                 )
                this.state.editing = false;
                this.state.id= "";

                
            }     
    }

    // muetra datos para editar
    editNutrientes = async (id) => {
        
        const res = await fetch(`${url_api}nutrientes/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.control = true;
        this.state.id= id;
        
        this.setState({
            nombre: data.nombre,
            deficiencia_img: data.deficiencia_img,
            imagePreviewUrlDef: data.deficiencia_img,
            deficiencia: data.deficiencia,
            exceso_img: data.exceso_img,
            imagePreviewUrlExc: data.exceso_img,
            exceso: data.exceso,
            etapa: data.etapa, 
            cultivo_id: data.cultivo.id
        })
        
        
    };

    //elimina datos
    deleteNutrientes = async (id) => {
        this.state.control = true;
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}nutrientes/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                
                MySwal.fire({
                    icon: 'Success',
                    title: 'Eliminado!',
                    text: 'El Registro ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  });
                
            }
            this.componentDidMount();
          });
    };

    //muestra elemento
    getNutricion = async (id) => {
        const res = await fetch(`${url_api}nutrientes/${id}/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const item = await res.json(); 
        const cultivo = item.cultivo;

        this.setState({item: item, open: id, cultivo:cultivo});
        if(this.state.control === false){
            this.setState({ showModal: true });
        }else{
            this.state.control = false;
        } 
        
    };
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render() {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        let {imagePreviewUrlDef, imagePreviewUrlExc} = this.state;
        let $imagePreviewDef = null;
        let $imagePreviewExc = null;
        if (imagePreviewUrlDef) {
            $imagePreviewDef = (<img alt="plagas" src={imagePreviewUrlDef} />);
        } else {
            $imagePreviewDef = (<div className="previewText">Selecciona una foto de Deficiencia:</div>);
        }
        if (imagePreviewUrlExc) {
            $imagePreviewExc = (<img src={imagePreviewUrlExc} />);
        } else {
            $imagePreviewExc = (<div className="previewText">Selecciona una foto de Exceso:</div>);
        }

        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Nutrientes </h2>
                    <div className="grid grid-cols-12 gap-4 ">
                        <div className="bg-white col-span-12 rounded ">
                            <div className="form bg-white rounded shadow-md py-8">
                                <h3 className="font-bold text-primary m-4">Crear Nutrientes</h3>
                                <form className="m-6" onSubmit={this.submit}>
                                    <div className=" form bg-white rounded grid grid-cols-12 gap-8 overflow-hidden flex">
                                        <div className="my-1 mx-2 col-span-3">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nombre:   </label>
                                            <input
                                                id="nombre"
                                                name="nombre"
                                                type="text"
                                                value={this.state.nombre} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nombre ej. Mancha Angular"
                                                required
                                                title="Favor de Agregar el Nombre de la plaga y Enfermedad. ej. Mancha Angular"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2 col-span-3">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Etapa:   </label>
                                            <input
                                                id="etapa"
                                                name="etapa"
                                                type="text"
                                                value={this.state.etapa} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Etapa ej. Mancha Angular"
                                                required
                                                title="Favor de Agregar la Etapa de Nutrientes. ej. Mancha Angular"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2 col-span-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Tipo de cultivo</label>
                                                <select id="cultivo_id" name="cultivo_id" value={this.state.cultivo_id}  onChange={this.handleChange} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res" 
                                                title="Favor de Seleccionar un Cultivo de la Lista.">
                                                    <option value="">Selecciona un Cultivo</option>
                                                    {this.state.cultivos.map((cultivo,i)=> {
                                                        return (
                                                        <option  key={i}  value={cultivo.id}>{cultivo.nombre}</option>
                                                        );
                                                    })}
                                                </select>                                                
                                        </div>
                                    </div>
                                    
                                    <div className=" form bg-white rounded grid grid-cols-2 gap-8 overflow-hidden flex">
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Deficiencia:   </label>
                                            <input
                                                id="deficiencia"
                                                name="deficiencia"
                                                type="text"
                                                value={this.state.deficiencia} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Deficiencia ej. Mancha Angular"
                                                required
                                                title="Favor de Agregar la Deficiencia del Nutriente . ej. Mancha Angular"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Exceso:   </label>
                                            <input
                                                id="exceso"
                                                name="exceso"
                                                type="text"
                                                value={this.state.exceso} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Exceso ej. En Follaje"
                                                required
                                                title="Favor de Agregar el Exceso de la  Nutricion. ej. En Follaje"
                                            />                                                
                                        </div>
                                    </div>
                                    
                                    <div className=" form bg-white rounded grid grid-cols-2 gap-8 overflow-hidden flex">
                                        <div className="custom-input-pl my-1 mx-2">
                                            <div className="custom-input-file-pl">
                                                <input className="file-input" 
                                                    type="file" 
                                                    name="deficiencia_img"
                                                    id="foto1"
                                                    title="Favor de Agregar Foto de deficiencia"
                                                    onChange={this.handleImageDef} />  
                                                  <div className="imgPreview">
                                                    {$imagePreviewDef}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage('1') } className="icon-img-pl inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                    
                                        <div className="custom-input-pl my-1 mx-2">
                                            <div className="custom-input-file-pl">
                                                <input className="file-input" 
                                                type="file" 
                                                name="exceso_img"
                                                id="foto2"
                                                title="Favor de Agregar una Foto del Exceso"
                                                onChange={this.handleImageExc} />
                                                <div className="imgPreview">
                                                    {$imagePreviewExc}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage('2') } className="icon-img-pl inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                    </div>

                                   
                                    
                                    <div className="p-4 btn-box flex">
                                        
                                        <div>
                                            <button type="submit" className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none">{this.state.editing ? "Actualizar Nutriente" : "Guardar Nutriente"}</button>
                                        </div>
                                    </div>
                                </form>
                                    <Modal 
                                    isOpen={this.state.showModal}
                                    ariaHideApp={false}
                                    contentLabel="Modal"
                                    onRequestClose={this.handleCloseModal}
                                    style={customStyles}
                                    >
                                    <div  className="flex justify-between bg-white">
                                        <h3 className="font-bold text-primary m-4">Detalles Nutrientes</h3>
                                        <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 m-4" onClick={this.handleCloseModal}>Cerrar</button>
                                    </div>    
                                    
                                    <div className="shadow-md divide-y divide-green-400 bg-white">
                                        <div className=" form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nombre: </h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nombre}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Etapa:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.etapa}</p></div>                                            
                                            <div className=""><h6 className="pl-title my-1 mx-2">Cultivo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.cultivo.nombre}</p></div>
                                        </div>
                                        <div className=" form bg-white rounded grid grid-cols-5 gap-4 px-2 py-8 overflow-hidden flex">
                                            <div className=" col-span-3"><h6 className="pl-title my-1 mx-2">Deficiencia: </h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.deficiencia}</p></div>      
                                            <div className="col-span-2 text-center justify-self-center"><h6 className="pl-title my-1 mx-2">Foto Deficiencia:</h6>
                                            <p className="pl-value my-1 mx-2">
                                                {this.state.item.deficiencia_img === null  ? 
                                                    <img className="detail-img-pl mx-5" src={paisaje}/>:
                                                    <img className="detail-img-pl mx-5" src={this.state.item.deficiencia_img}/>}
                                            </p></div>
                                        </div>
                                        <div className=" form bg-white rounded grid grid-cols-5 gap-4 px-2 py-8 overflow-hidden flex">
                                            <div className="col-span-3"><h6 className="pl-title my-1 mx-2">Exceso: </h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.exceso}</p></div>      
                                            <div className="col-span-2  text-center justify-self-center"><h6 className="pl-title my-1 mx-2">Foto Exceso:</h6>
                                            <p className="pl-value my-1 mx-2">
                                                {this.state.item.exceso_img === null  ? 
                                                    <img className="detail-img-pl mx-5" src={paisaje}/>:
                                                    <img className="detail-img-pl mx-5" src={this.state.item.exceso_img}/>}
                                            </p></div>
                                        </div>
                                        
                                    </div>
                                </Modal> 
                            </div>
                        </div>
                        <div className="col-span-12 mb-20">
                            <h3 className="font-bold text-primary m-4">Lista Nutrientes</h3><br></br>
                            <div className="col-span-12 grid grid-cols-4 gap-4">
                                {this.state.apps.map((app, i)=> {
                                    return (
                                        <a title="Oprime para Ver los Detalles" onClick={(e) => this.getNutricion(app.id)}  key={app.id} id={app.id} className={`cursor-pointer bg-white rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32 ${this.state.open === app.id ? 'activeclass' : ''}`}>
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Nombre:</h6>
                                                <p className="pl-value my-1">{app.nombre} </p>
                                                <h6 className="pl-title my-1 mt-2">Etapa</h6>
                                                <p className="pl-value nmy-1">{app.etapa}</p>
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editNutrientes(app.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteNutrientes(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })}  
                            </div>  
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Nutrientes))

