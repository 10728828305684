export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('auth');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            if(response.status === 204) {
                const datos = {
                    logout: true
                };
                return datos;
            }
            return data;
        }


    });
}