import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';

const MySwal = withReactContent(Swal)
class Dolar extends Component {
    

    constructor() {
        super();
        this.state = {
            dolar : [],
            valor: '',
            user_id: '',
            id:'',
            editing: false,
            item: '',
            list: [],
            open: 0,
        }
        this.submitDolar = this.submitDolar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}precio_dolar/`, options);
        const dolar = await res.json();
        if(!dolar.detail){     
            const user =this.props.user.id;
            this.setState({dolar:dolar, user_id: user}) 
        }              
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

   
    //crear y editar cultivos
    submitDolar= async (e) =>{
        e.preventDefault();

        if (this.state.editing===false) {
            const res = await fetch(`${url_api}precio_dolar/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    valor: this.state.valor,
                    user_id: this.state.user_id
                })
            }) 
            
            const data = await res.json();
            console.log(data);
                       
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Precio...',
                      showConfirmButton: false,
                      timer: 2000,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente el Precio del Dólar',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                  },
                              })                        
                          }
                  })
                  this.componentDidMount();

                  this.setState({
                      valor: '',
                      user_id: '',
                  })
          
              }else{
                  MySwal.fire({
                      icon: 'warning',                      
                      text: data.valor,
                      text: data.detalle,
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                      },
                    })
              } 

        } else {
            const id=this.state.id;
            
            const res = await fetch(`${url_api}precio_dolar/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({                   
                    valor: this.state.valor,
                    user_id: this.state.user_id
                }),
              });
            const data = await res.json();
            console.log(data);
            if(data.id){
                let timerInterval
                MySwal.fire({
                    title: 'Actualizando Precio...',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente el Precio del Dólar',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                },
                            })                        
                        }
                })
                this.componentDidMount();

                this.setState({                    
                    valor: '',
                    user_id: '',
                })
        
                this.state.editing = false;
                this.state.id= "";
            }else{
                MySwal.fire({
                    icon: 'warning',
                    text: data,
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                    },
                  })
            } 

          
        }     
        

        
    }

    // muetra datos para editar cultivos
    editDolar = async (id) => {
        
        const res = await fetch(`${url_api}precio_dolar/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        

        this.setState({
            valor: data.valor,
        })
       
               
    };

    //elimina datos
    deleteDolar = async (id) => {
        
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then( async(result) => {
            if (result.isConfirmed) {
                
                await fetch(`${url_api}precio_dolar/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                MySwal.fire({
                    icon: 'Success',
                    title: 'Eliminado!',
                    text: 'El Registro ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                    },
                  });
            }
            this.componentDidMount();
          })
    };


    
    render() {
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 rounded pt-10">
                            <h2 className="font-bold text-primary my-4">Precio del Dollar</h2>
                            <div className="bg-white rounded pt-10">
                            
                                    <form onSubmit={this.submitDolar} className="form bg-white rounded grid grid-cols-10 shadow-md py-8 mr-2">
                                        <div className=" form bg-white rounded col-span-10 text-center overflow-hidden ">
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Precio:   </label>
                                                <input
                                                    id="valor"
                                                    name="valor"
                                                    type="text"
                                                    value={this.state.valor} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Valor del Dollar ej. 20.50"
                                                    required
                                                    title="Favor de Agregar el Valor del Dolar. ej. 20.50"
                                                />                                                
                                            </div>
                                            
                                        </div>                
                                        

                                        

                                        <div className="flex flex justify-between col-span-12  grid grid-cols-1  gap-4">  
                                            

                                            <div className='text-center ' >
                                                <button type="submit" title="Guarda el Precio del Dólar" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 m-5 justify-center items-center  ">{this.state.editing ? "Actualizar Precio" : "Guardar Precio"}</button>
                                            </div>

                                            
                                        </div>  
                                    </form>
                                    
                           </div>
                            <div className="col-span-12 grid grid-cols-4  gap-4 mt-6">
                                {this.state.dolar.map((x, i)=> {
                                    return (
                                        <div key={i} id={x.id} className="rounded bg-white shadow-md grid grid-cols-12  gap-4 p-4 ">
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Valor del Dolar</h6>
                                                <p className="pl-value my-1">{x.valor} </p>
                                               
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editDolar(x.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteDolar(x.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}    
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Dolar))