import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

import cardPlant from "../../assets/img/partials/card-plant.svg";
import infoMedi from "../../assets/img/partials/info-medicion.svg";
import next1 from "../../assets/img/partials/next-1.png";
import line from "../../assets/img/partials/line.png";
import figureLeft from "../../assets/img/partials/figure-left.svg";
import figureRigth from "../../assets/img/partials/figure-rigth.svg";
import LayoutTwo from "../../layouts/LayoutTwo";

class SistemaRiego extends Component {
    render() {

        return (
            <>
                <LayoutTwo></LayoutTwo>
                <div class="container-fluid mx-auto">
                    <div class="row">
                        <div class="col s11 m11 l11">
                            <div className="flex">
                                <h3 className="font-bold fr text-primary title-1">Medición: 10 Enero 2020</h3>
                            </div>
                            <div className="flex justify-center">
                                <img src={infoMedi} className=""/>
                                <p className="med-1">6thr</p>
                                <p className="med-2">6 m</p>
                                <p className="med-3">63</p>
                                <p className="med-4">64</p>
                                <p className="med-5">65</p>
                                <p className="med-6">66</p>
                                <p className="med-7">67</p>
                                <p className="med-8">68</p>
                                <p className="med-9">69</p>
                                <p className="med-10">70</p>
                                <p className="med-11">88</p>
                            </div>
                        </div>
                        <div class="col s11 m11 l11">
                            <div class="row pad40">
                                <div class="col s12 m6 l6">
                                    <div class="row">
                                        <div class="col s12">
                                            <span className="font-bold text-primary title-3">Días de aplicación de fertiriego por semana</span>
                                            <div className="box-space">
                                                <div class="row">
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">1</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">2</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">3</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">4</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">5</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">6</button>
                                                        </div>
                                                    </div>
                                                    <div class="col s1 m1 l1">
                                                        <div className="box-space-sm">
                                                            <button type="submit" class="outline-2">7</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s6">
                                            <span className="font-bold text-primary title-3">Tipo de agua</span>
                                            <div className="box-space">
                                                <select class="browser-default ">
                                                    <option value="" disabled selected>Poso Profundo</option>
                                                    <option value="1">Option 1</option>
                                                    <option value="2">Option 2</option>
                                                    <option value="3">Option 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col s6">
                                            <span className="font-bold text-primary title-3">Tipo de Regante</span>
                                            <div className="box-space">
                                                <select class="browser-default ">
                                                    <option value="" disabled selected>Cintilla</option>
                                                    <option value="1">Option 1</option>
                                                    <option value="2">Option 2</option>
                                                    <option value="3">Option 3</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Tipo de sistema de riego</span>
                                            </div>
                                        </div>

                                        <div class="col s12 m12 l12">
                                            <div className="box-space-sm">
                                                <button type="submit" class="big-rounded">Opcion 1</button>
                                                <button type="submit" class="big-rounded">Opcion 1</button>
                                                <button type="submit" class="big-rounded">Opcion 1</button>
                                            </div>
                                        </div>

                                        <div class="col s12 m12 l12">
                                            <div className="box-space-sm">
                                                <button type="submit" class="big-rounded">Opcion 1</button>
                                                <button type="submit" class="big-rounded">Opcion 1</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col s12">
                                            <div className="box-space">
                                                <span
                                                    className="font-bold text-primary title-3">Flujo por superficie</span>
                                            </div>
                                        </div>

                                        <div class="input-field col s12">
                                            <input id="nombres" className="input-3" type="text"/>
                                            <label for="nombres">Inicio del primer pulso : </label>
                                        </div>

                                        <div class="input-field col s12">
                                            <input id="tlf" className="input-3" type="text"/>
                                            <label for="tlf">Termino del ultimo pulso : </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col s12 m6 l6">
                                    <div class="row pad40">
                                        <div class="col s12">
                                            <div className="box-space">
                                                <span
                                                    className="font-bold text-primary title-3">Flujo por superficie</span>
                                                <div className="box-space">
                                                    <p class="text-five">Litros por hora</p>
                                                </div>
                                                <form action="#">
                                                    <p class="range-field">
                                                        <input type="range" id="test5" min="0" max="100"/>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col s12">
                                            <div class="row">
                                                <div class="col s12 m6 l6">
                                                    <div className="box-space">
                                                        <p class="text-five">Distancia entre goteros</p>
                                                        <div className="box-space">
                                                            <div class="col s2">
                                                                <img src={figureLeft} className="icon-goteros"/>
                                                            </div>
                                                            <div class="col s4">
                                                                <p>0 metros</p>
                                                                <img src={line} className="icon-line"/>
                                                            </div>
                                                            <div class="col s2">
                                                                <img src={figureRigth} className="icon-goteros"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col s12 m6 l6">
                                                    <div className="box-space">
                                                        <p class="text-five">Cintas por cama</p>
                                                        <div className="box-space">
                                                            <div class="col s2">
                                                                <img src={figureLeft} className="icon-goteros"/>
                                                            </div>
                                                            <div class="col s4">
                                                                <p>0 metros</p>
                                                                <img src={line} className="icon-line"/>
                                                            </div>
                                                            <div class="col s2">
                                                                <img src={figureRigth} className="icon-goteros"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col s12">
                                            <div class="row">
                                                <div class="col s12 m6 l6">
                                                    <div className="box-space">
                                                        <p class="text-five">Distancia entre goteros</p>
                                                        <div className="box-space">
                                                            <div class="col s2">
                                                                <img src={figureLeft} className="icon-goteros"/>
                                                            </div>
                                                            <div class="col s4">
                                                                <p>0 metros</p>
                                                                <img src={line} className="icon-line"/>
                                                            </div>
                                                            <div class="col s2">
                                                                <img src={figureRigth} className="icon-goteros"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Flujo por planta</span>
                                                <div className="box-space">
                                                    <p class="text-five">Litros por hora</p>
                                                </div>
                                                <form action="#">
                                                    <p class="range-field">
                                                        <input type="range" id="test5" min="0" max="100"/>
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <div className="box-space">
                                                <div class="input-field s12">
                                                    <input id="tlf" className="input-3" type="text"/>
                                                    <label for="tlf">Numero de goteros : </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s12">
                                            <div className="box-space-sm">
                                                <div className="flex justify-center">
                                                    <img src={cardPlant} className=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/parametro-medicion">
                        <div className="">
                            <img src={next1} className="next-1"/>
                        </div>
                    </Link>
                </div>
            </>
        )
    }
}

export default withRouter(SistemaRiego)