import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import Cultivo from "./Cultivo";

const MySwal = withReactContent(Swal)
class ParametrosHumedad extends Component {
    

    constructor() {
        super();
        this.state = {
            humedad : [],
            cultivos: [],
            rango: '',
            unidad_de_medida: 'Kpa',
            tipo: '',
            efectividad: '', 
            cultivo_id: '', 
            user_id: '',
            id:'',
            editing: false,
            item: '',
            list: [],
            open: 0,
        }
        this.submitRango = this.submitRango.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}rangos/`, options);
        const humedad = await res.json();

        if(!humedad.detail){     
            this.setState({humedad:humedad}) 
        }

        const rescul = await fetch(`${url_api}cultivos/`, options);
        const cultivos = await rescul.json();
        if(!cultivos.detail){     
            this.setState({cultivos:cultivos}) 
        }

    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

   
    //crear y editar 
    submitRango= async (e) =>{
        e.preventDefault();

        if (this.state.editing===false) {
            const res = await fetch(`${url_api}rangos/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    rango: this.state.rango,
                    unidad_de_medida: this.state.unidad_de_medida,
                    tipo: this.state.tipo,
                    efectividad: this.state.efectividad,
                    cultivo_id: this.state.cultivo_id, 
                })
            }) 
            
            const data = await res.json();
            console.log(data);
                       
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Rango...',
                      showConfirmButton: false,
                      timer: 2000,
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente el Parametro de Humedad',
                                  showConfirmButton: true,
                              })                        
                          }
                  })
                  this.componentDidMount();

                  this.setState({
                    rango: '',
                    tipo: '',
                    efectividad: '',
                    cultivo_id: '', 
                  })
          
              }else{
                  MySwal.fire({
                      icon: 'warning',                      
                      text: data,
                      //text: data.detalle,
                      showConfirmButton: true
                    })
              } 

        } else {
            const id=this.state.id;
            
            const res = await fetch(`${url_api}rangos/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({   
                    rango: this.state.rango,
                    unidad_de_medida: this.state.unidad_de_medida,
                    tipo: this.state.tipo,
                    efectividad: this.state.efectividad,
                    cultivo_id: this.state.cultivo_id,
                }),
              });
            const data = await res.json();
            console.log(data);
            if(data.id){
                let timerInterval
                MySwal.fire({
                    title: 'Actualizando Precio...',
                    showConfirmButton: false,
                    timer: 2000,
                    willOpen: () => {
                        MySwal.showLoading()                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente el Parametro de Humedad',
                                showConfirmButton: true,
                            })                        
                        }
                })
                this.componentDidMount();

                this.setState({                    
                    rango: '',
                    tipo: '',
                    efectividad: '',
                    cultivo_id: '',
                })
        
                this.state.editing = false;
                this.state.id= "";
            }else{
                MySwal.fire({
                    icon: 'warning',
                    text: data,
                    //text: data.detalle,
                    showConfirmButton: true
                  })
            } 

          
        }     
        

        
    }

    // muetra datos para editar 
    editRango = async (id) => {
        
        const res = await fetch(`${url_api}rangos/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        

        this.setState({
            rango: data.rango,
            unidad_de_medida: data.unidad_de_medida,
            tipo: data.tipo,
            efectividad: data.efectividad,
            cultivo_id: data.cultivo.id
        })
       console.log(data)
               
    };

    //elimina datos
    deleteRango = async (id) => {
        
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!'
          }).then( async(result) => {
            if (result.isConfirmed) {
                
                await fetch(`${url_api}rangos/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                MySwal.fire(
                        'Eliminado!',
                        'El Registro ha Sido Eliminado.',
                        'success'
                )
            }
            this.componentDidMount();
          })
    };


    
    render() {
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes h-screen';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        console.log(this.state.cultivos)
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 rounded pt-10">
                            <h2 className="font-bold text-primary my-4">Parametros de Humedad</h2>
                            <div className="bg-white rounded pt-10">
                            
                                    <form onSubmit={this.submitRango} className="form bg-white rounded grid grid-cols-4 shadow-md py-8 mr-2">
                                        <div className=" form bg-white rounded col-span-10 text-center overflow-hidden flex ">
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Rango:   </label>
                                                <input
                                                    id="rango"
                                                    name="rango"
                                                    type="text"
                                                    value={this.state.rango} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Rango ej. 70"
                                                    required
                                                    title="Favor de Agregar el rango. ej. 70"
                                                />                                                
                                            </div>
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Unidad de medida:   </label>
                                                <input
                                                    id="unidad_de_medida"
                                                    name="unidad_de_medida"
                                                    type="text"
                                                    value={this.state.unidad_de_medida}                                                     
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="unidad_de_medida ej. Kpa"
                                                    disabled
                                                    title="Favor de Agregar la unidad de medida. ej. Kpa"
                                                />    
                                            </div>
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Tipo:   </label>
                                                <input
                                                    id="tipo"
                                                    name="tipo"
                                                    type="text"
                                                    value={this.state.tipo} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="tipo de Rango ej. capacidad"
                                                    required
                                                    title="Favor de Agregar el Tipo de rango. ej. capacidad"
                                                />                                                
                                            </div>
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Efectividad:   </label>
                                                <input
                                                    id="efectividad"
                                                    name="efectividad"
                                                    type="text"
                                                    value={this.state.efectividad} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="efectividad del Rango ej. -10"
                                                    required
                                                    title="Favor de Agregar la efectividad del rango. ej. -10"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2 col-span-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Tipo de cultivo</label>
                                                <select id="cultivo_id" name="cultivo_id" value={this.state.cultivo_id}  onChange={this.handleChange} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res" 
                                                title="Favor de Seleccionar un Cultivo de la Lista.">
                                                    <option value="">Selecciona un Cultivo</option>
                                                    {this.state.cultivos.map((cultivo,i)=> {
                                                        return (
                                                        <option  key={i}  value={cultivo.id}>{cultivo.nombre}</option>
                                                        );
                                                    })}
                                                </select>                                                
                                        </div>                                          
                                            
                                        </div>                
                                        

                                        

                                        <div className="flex flex justify-between col-span-12  grid grid-cols-1  gap-4">  
                                            

                                            <div className='text-center ' >
                                                <button type="submit" title="Guarda el Rango" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 m-5 justify-center items-center  ">{this.state.editing ? "Actualizar Parametro" : "Guardar Parametro"}</button>
                                            </div>

                                            
                                        </div>  
                                    </form>
                                    
                           </div>
                            <div className="col-span-12 grid grid-cols-4  gap-4 mt-6">
                                {this.state.humedad.map((x, i)=> {
                                    return (
                                        <div key={i} id={x.id} className="rounded bg-white shadow-md grid grid-cols-12  gap-4 p-4 ">
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Rango</h6>
                                                <p className="pl-value my-1">{x.rango} </p>
                                                <h6 className="pl-title my-1">Unidad de medida</h6>
                                                <p className="pl-value my-1">{x.unidad_de_medida} </p>                                                
                                                <h6 className="pl-title my-1">Tipo</h6>
                                                <p className="pl-value my-1">{x.tipo} </p>                                                
                                                <h6 className="pl-title my-1">Efectividd</h6>
                                                <p className="pl-value my-1">{x.efectividad} </p>
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editRango(x.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteRango(x.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}    
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(ParametrosHumedad))