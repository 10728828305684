import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '10%',
      left                  : '20%',
      right                 : '20%',
      bottom                : '20%',   
      background            : '#90ec90'
    }
  };
class Nutricion extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            products :[],
            
            typeapp: '',
            femologico:'',
            mes: '',
            precio_semana:'',
            napp:'',
            tcultivo: '',
            garrafa: '',
            
            id:'',
            editing: false,
            item: '',
            list: [],
            open: 0,
            cultivo: '', 
            cultivos: [],
            nutings: [],
            control: false,
            inputList: [
                { 
                    producto_nutricion_id :"",
                }
            ]
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}aplicacion_nutricion/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`
            },
        });
        const apps = await res.json(); 
        if(!apps.detail){
                
            this.setState({apps:apps})

            fetch(`${url_api}productos_nutricion/`, options)
                .then(res => res.json())
                .then(products => this.setState({products})) 
            
            fetch(`${url_api}cultivos/`, options)
                .then(res => res.json())
                .then(cultivos => this.setState({cultivos}))
        }
        

    }
      

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
        console.log({[nam]: val});
    }

    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.inputList];
        list[index][name] = value;
        this.setState({inputList: list});
        console.log({inputList: list});
    };
     
    // handle click event of the Remove button
    handleRemoveClick = index => {
        const list = [...this.state.inputList];
        list.splice(index, 1);
        this.setState({inputList: list});
    };
     
    // handle click event of the Add button
    handleAddClick = () => {
        this.setState((prevState) => ({
            inputList: [...prevState.inputList, { 
                producto_nutricion_id :"",
            }],
        }));
    };

    //crear y editar
    submit= async (e) =>{
        e.preventDefault();
        if (this.state.editing===false) {
           const res = await fetch(`${url_api}aplicacion_nutricion/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    tipo_aplicacion: this.state.typeapp,
                    etapa_fenologica: this.state.femologico,
                    mes: this.state.mes,
                    precio_semana: this.state.precio_semana,
                    no_aplicacion: this.state.napp,
                    cultivo_id: this.state.tcultivo,
                    garrafa50_lt_HA: this.state.garrafa,
                    nutricion_ingredientes: this.state.inputList
                    
                })
            }) 
            
            const data = await res.json();
            console.log(data);
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                    title: 'Guardando Aplicación...',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                                
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente la Aplicación Nutricion',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                      cancelButton: 'cancelbutton-class'
                                  },
                              })                        
                          }
                  })
                  this.componentDidMount();

                    this.setState({
                        typeapp: '',
                        femologico:'',
                        mes: '',
                        precio_semana:'',
                        napp:'',
                        tcultivo: '',
                        garrafa: ''
                    })
                    this.setState({
                        inputList: [{ 
                            producto_nutricion_id :"",
                        }]
                    })
              }else{
                  MySwal.fire({
                        icon: 'warning',
                        title: 'Revisa tus Datos',
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                        },
                    })
              }   
            
        } else {
            const id=this.state.id;

            const res = await fetch(`${url_api}aplicacion_nutricion/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    tipo_aplicacion: this.state.typeapp,
                    etapa_fenologica: this.state.femologico,
                    mes: this.state.mes,
                    precio_semana: this.state.precio_semana,
                    no_aplicacion: this.state.napp,
                    cultivo_id: this.state.tcultivo,
                    garrafa50_lt_HA: this.state.garrafa,
                    nutricion_ingredientes: this.state.inputList
                }),
              })
           
              const data = await res.json();
              console.log(data);
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                    title: 'Actualizando Aplicación...',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                                
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente la Aplicación Nutricion',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                              })                        
                          }
                  })
                  this.componentDidMount();

                    this.setState({
                        typeapp: '',
                        femologico:'',
                        mes: '',
                        precio_semana:'',
                        napp:'',
                        tcultivo: '',
                        garrafa: ''
                    })
                    this.setState({
                        inputList: [{ 
                            producto_nutricion_id :"",
                        }]
                    })
              }else{
                  MySwal.fire({
                        icon: 'warning',
                        title: 'Revisa tus Datos',
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                            buttonsStyling: false,
                            customClass: {
                                title:'title-class',
                                popup:'popup-class',
                                confirmButton: 'confirmbutton-class',
                                cancelButton: 'cancelbutton-class'
                            },
                        },
                    })
              }
            this.state.editing = false;
            this.state.id= "";
        }     
        

         

    }

    // muetra datos para editar
    editNutricion = async (id) => {
        this.state.control = true;
        const res = await fetch(`${url_api}aplicacion_nutricion/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        const list = data.nutricion_ingredientes;
        
        this.setState({
            typeapp: data.tipo_aplicacion,
            femologico: data.etapa_fenologica,
            mes: data.mes,
            precio_semana: data.precio_semana,
            napp: data.no_aplicacion,
            tcultivo: data.cultivo.id,
            garrafa: data.garrafa50_lt_HA,
        })
        /*let list = this.state.plings.filter(item_ing => item_ing.plagas_enfermedades_id == data.id);*/
        const newlist = [];
        list.map((x) => {
            const id = x.producto_nutricion.id;
            var myJSON = JSON.stringify(id);
            newlist.push({producto_nutricion_id: myJSON});
            
        }) 
        
       console.log(newlist);

        this.setState({inputList: newlist})
    };

    //elimina datos
    deleteNutricion = async (id) => {
        this.state.control = true;
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                const resu = fetch(`${url_api}aplicacion_nutricion/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                }).then(res => {
                    console.log(res.status);
                    if(res.status === 500){
                        MySwal.fire({
                            icon: 'warning',
                            title: 'No se puede Eliminar!',
                            text: 'La Aplicación esta Siendo Utilzada por otro Componente',
                            showConfirmButton: true,
                            buttonsStyling: false,
                            customClass: {
                                title:'title-class',
                                popup:'popup-class',
                                confirmButton: 'confirmbutton-class',
                            },
                          });
                    }else{
                         MySwal.fire({
                            icon: 'Success',
                            title: 'Eliminado!',
                            text: 'El Registro ha sido Eliminado Correctamente.',
                            showConfirmButton: true,
                            buttonsStyling: false,
                            customClass: {
                                title:'title-class',
                                popup:'popup-class',
                                confirmButton: 'confirmbutton-class',
                                cancelButton: 'cancelbutton-class'
                            },
                          });
                    }
                   this.componentDidMount();
                })
                
                
                
            }
            this.componentDidMount();
          })
    };

    //muestra elemento
    getNutricion = async (id) => {
        const res = await fetch(`${url_api}aplicacion_nutricion/${id}/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const item = await res.json(); 
        
        //let list = this.state.nutings.filter(nuting => nuting.aplicacion_nutricion == item.id);
        const list = item.nutricion_ingredientes;
        const cultivo = item.cultivo;
        /*list.map((x) => {
            let listit = this.state.products.filter(product => product.id == x.producto);
               
            let id= x.producto;
            x.productob = listit ;  

        })*/
        this.setState({item: item, list:list, open: id, cultivo:cultivo});   
        if(this.state.control === false){
            this.setState({ showModal: true });
        }else{
            this.state.control = false;
        }
        
      };
    
    handleOpenModal () {
        this.setState({ showModal: true });
    }
      
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render() {
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        
    
        
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Nutrición</h2>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="bg-white col-span-12 rounded">
                            <div className="form bg-white rounded shadow-md py-8">
                                <h3 className="font-bold text-primary m-4">Crear Aplicacion Nutrición</h3>
                                <form  className="m-6" onSubmit={this.submit}>
                                    <div className=" form bg-white rounded grid grid-cols-4 gap-4 my-2 overflow-hidden flex">
                                                                   
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title">Tipo de Aplicacion</label>
                                                <input
                                                    id="typeapp"
                                                    name="typeapp"
                                                    type="text"
                                                    value={this.state.typeapp} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="Tipo Aplicación ej. Desinfectante 30 dias"
                                                    required
                                                    title="Favor de Agregar el Tipo de Aplicación . ej. Desinfectante 30 dias"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Etapa Fenologica</label>
                                                <input
                                                    id="femologico"
                                                    name="femologico"
                                                    type="text"
                                                    value={this.state.femologico} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="Etapa Fenologica ej. Desinfección"
                                                    required
                                                    title="Favor de Agregar la Etapa Fenologica. ej. Desinfección"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Mes</label>
                                                <input
                                                    id="mes"
                                                    name="mes"
                                                    type="text"
                                                    value={this.state.mes} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="Fecha ej. 2021-01-21"
                                                    required
                                                    title="Favor de Agregar la Fecha. ej. 2021-01-21"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Precio Semana</label>
                                                <input
                                                    id="precio_semana"
                                                    name="precio_semana"
                                                    type="text"
                                                    value={this.state.precio_semana} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="Precio Semana ej. 1"
                                                    required
                                                    title="Favor de Agregar el Precio Semana de la Aplicación. ej. 1"
                                                />                                                
                                            </div>

                                    </div> 
                                    <div className=" form bg-white rounded grid grid-cols-4 gap-4 my-2 overflow-hidden flex">
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">No. Aplicacion</label>
                                                <input
                                                    id="napp"
                                                    name="napp"
                                                    type="text"
                                                    value={this.state.napp} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="No. Aplicación ej. 1"
                                                    required
                                                    title="Favor de Agregar el No. de Aplicación. ej. 1"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Garrafa 50lt HA</label>
                                                <input
                                                    id="garrafa"
                                                    name="garrafa"
                                                    type="text"
                                                    value={this.state.garrafa} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="Garrafa 50lt HA ej. 5"
                                                    required
                                                    title="Favor de Agregar la Cantidad de Garrafas. ej. 5"
                                                />                                                
                                            </div>
                                            <div className="my-1 mx-2">
                                                    <label htmlFor="name" className="self-center nut-title-res">Tipo de cultivo</label>
                                                    <select id="tcultivo" name="tcultivo" value={this.state.tcultivo}  onChange={this.handleChange} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res" 
                                                    title="Favor de Seleccionar un Cultivo de la Lista.">
                                                        <option value="">Selecciona un Cultivo</option>
                                                        {this.state.cultivos.map((cultivo,i)=> {
                                                            return (
                                                            <option  key={i}  value={cultivo.id}>{cultivo.nombre}</option>
                                                            );
                                                        })}
                                                    </select>                                                
                                            </div>
                                    </div>
                                    <h4 className="font-bold text-primary m-4">Ingredientes de la Aplicación Nutrición</h4>
                                    <div className="flex items-center justify-center ">
                                       
                                        <table  className="form bg-white rounded grid-cols-12 overflow-hidden inline ">
                                            <thead>
                                                <tr className="table-res">
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Producto</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="flex-col items-center justify-between overflow-y-scroll w-full">
                                                {this.state.inputList.map((x, i) => {
                                                    return (
                                                        <tr key={i} className="border-none" >
                                                              
                                                            <td className="nut-table-ing">
                                                                <select id="producto_nutricion_id" name="producto_nutricion_id" value={x.producto_nutricion_id} onChange={e => this.handleInputChange(e, i)} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                                title="Favor de Seleccionar un Producto de la Lista">
                                                                    <option value="">Selecciona un Producto</option>
                                                                    {this.state.products.map((product, i)=> {
                                                                        return (
                                                                        <option key={i}  value={product.id}>{product.nombre}</option>
                                                                        );
                                                                    })}
                                                                </select>   
                                                            </td>  
                                                            <td className="nut-table-ing">
                                                                {this.state.inputList.length !== 1 && <button
                                                                className="inline-flex bg-red-600 text-white rounded-full h-8 px-3 py-2 m-1 justify-center items-center "
                                                                onClick={() => this.handleRemoveClick(i)}>x</button>}
                                                            </td>    
                                                        </tr>
                                                        
                                                    );
                                                    
                                                })}    
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="p-4 btn-box flex">
                                        {this.state.inputList.map((x, i) => {
                                            return (
                                                <div key={i} >
                                                    {this.state.inputList.length - 1 === i && <button className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-blue-400  shadow ripple hover:shadow-lg hover:bg-blue-600 focus:outline-none" onClick={this.handleAddClick}>Agregar Ingredentes</button>}
                                                </div>
                                            );
                                        })}
                                        <div>
                                            <button type="submit" className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none">{this.state.editing ? "Actualizar Aplicación" : "Guardar Aplicación"}</button>
                                        </div>
                                    </div>
                                </form>
                                <Modal 
                                    isOpen={this.state.showModal}
                                    ariaHideApp={false}
                                    contentLabel="Modal"
                                    onRequestClose={this.handleCloseModal}
                                    style={customStyles}
                                    >
                                    <div  className="flex justify-between bg-white">
                                        <h3 className="font-bold text-primary m-4">Detalles Nutrición</h3>
                                        <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 m-4" onClick={this.handleCloseModal}>Cerrar</button>
                                    </div>    
                                    <div className="shadow-md divide-y divide-green-400 bg-white">
                                        
                                        
                                        <div className="flex items-center justify-center pb-6">
                                            <table className="form bg-white rounded grid-cols-12 overflow-hidden inline">
                                                <thead>
                                                    <tr className="table-res">
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Tipo de Aplicacion</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Etapa Fenologica</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Codigo</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Mes</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Semana</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Precio Semana</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">No. Aplicacion</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Tipo Cultivo</th>
                                                        <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Garrafa 50lt HA</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="flex-col items-center justify-between  w-full">
                                                    <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                            {this.state.item.tipo_aplicacion}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                        {this.state.item.etapa_fenologica}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.codigo}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.mes}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.semana}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.precio_semana}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.no_aplicacion}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.cultivo.nombre}
                                                        </td>
                                                        <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                            {this.state.item.garrafa50_lt_HA}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex items-center justify-center pb-6">
                                            <table className="form bg-white rounded grid-cols-12 overflow-hidden inline ">
                                                <thead>
                                                    <tr className="table-res">
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Producto</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">kg lt HA</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Precio Unitario</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Precio mn</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Tonelada usd</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Importe 100</th>
                                                        <th className="w-full text-center lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">Unidad de Medida</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="flex-col items-center justify-between overflow-y-scroll w-full">
                                                    {this.state.list.map((x, i) => {
                                                        return (  
                                                            <tr key={i} className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.nombre}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.kg_lt_ha} 
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.precio_unitario}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.precio_mn}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.usd_tonelada}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.importe_100}
                                                                </td>
                                                                <td className="nut-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                    {x.producto_nutricion.inventario.unidad_medida}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}     
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Modal>  
                            </div>
                        </div>
                        <div className="col-span-12 mb-20">
                            <h3 className="font-bold text-primary m-4">Lista de Aplicaciones Nutrición</h3><br></br>
                            <div className="col-span-12 grid grid-cols-4 gap-4">
                                {this.state.apps.map((app, i)=> {
                                    return (
                                        <a title="Oprime para Ver los Detalles" onClick={(e) => this.getNutricion(app.id)} key={app.id} id={app.id} className={`cursor-pointer bg-white rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32 ${this.state.open === app.id ? 'activeclass' : ''}`}>
                                            <div  className="col-span-6 ">
                                                <h6 className="nut-title my-1">Tipo de Aplicacion</h6>
                                                <p className="nut-value my-1">{app.tipo_aplicacion} </p>
                                                <h6 className="nut-title my-1 mt-2">Etapa  Fenologica</h6>
                                                <p className="nut-value nmy-1">{app.etapa_fenologica}</p>
                                            </div>
                                            <div  className="col-span-6">
                                                <h6 className="nut-value my-1">Codigo</h6>
                                                <p className="nut-value my-1">{app.codigo}</p>
                                                <div>
                                                    <button onClick={(e) => this.editNutricion(app.id)} className=" z-50 inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                        <i className="material-icons">edit</i>
                                                    </button>
                                                    <button onClick={(e) => this.deleteNutricion(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                        <i className="material-icons">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                        
                                    );
                                })}    
                            </div>
                        </div>

                        
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Nutricion))