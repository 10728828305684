import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux'
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

import LayoutTwo from "../../layouts/LayoutTwo";

import './styles.css';
import {url_api} from "../../constants";
import Calendar from 'react-calendar'
import './Calendar.css';


import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '20%',
      left                  : '20%',
      right                 : '20%',
    }
  };

class CrearCita extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campo: [],
            error: {},
            user: {
                is_administrador: false,
                is_productor: true,
                is_ingeniero_de_Campo: false,
                is_chofer: false,
                is_quimico: false,
                is_cobranza: false,
                is_administrativo: false,
                factor: 0,
            },
         
            title: 'Agenda técnico',
            
            activecard: 0,
            switch:false,
            dateState:new Date(), 
            setDateState:new Date(),
            citas: [],

            lugar: '',
            fecha: '',
            hora: '',
            tipo_monitoreo: '',
            sector_id: '',
            monitor_responsable_id: '',

            citadetail: '',
            monitor:'',
            cards:{
                card1: false,
                card2: false,
                card3: false,
                card4: false,
                card5: false,
                card6: false,
                card7: false,
                card8: false,
            },
            showModal: false,
            monitoreo: [
                {nombre: 'ENP'},
                {nombre: 'NUTRICION'},
                {nombre: 'MPE'},
            ],
            lugares: [
                {nombre: 'SAN ANTONIO'},
                {nombre: 'TANGANCICUARO'},
                {nombre: 'CHAVINDA'},
                {nombre: 'CAÑADA'},
                {nombre: 'LOS REYES'},
            ],
        };

        this.validateData = this.validateData.bind(this)
        this.saveCita = this.saveCita.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cardChange = this.cardChange.bind(this);        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);  
        
    }
    
    /** pasos
     * Cambia los pasos
     **/
    componentDidMount= async () =>  {
        navigator.geolocation.watchPosition((position) => {
            if (position.coords !== undefined) {
                //console.log('entra', position.coords.latitude, position.coords.longitude);
                this.setState({ latitud: position.coords.latitude, longitud: position.coords.longitude });
            }
        });
        
        const options = {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            }
        }
        const rescitas= await fetch(`${url_api}agenda_citasTP/`, options);
        const citas = await rescitas.json();
        console.log(citas)
        
    }
    changeDate = async (e) => {
         
        const formatYmd = date => date.toISOString().slice(0, 10);
        const newdate = formatYmd(e);
        const setDateState =e;
        
        const options = {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            }
        }
        const rescitas= await fetch(`${url_api}agenda_citasTP/`, options);
        const citas = await rescitas.json();
        let resultcitas = citas.filter(cita => cita.fecha === newdate );
        
        this.setState({citas:resultcitas, setDateState:setDateState }) 
        
    }

    cardChange= async (e) =>  {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            }
        }
        const rescita= await fetch(`${url_api}agenda_citas/${e}`, options);
        const cita = await rescita.json();
        console.log(cita)
        const monitor= cita.monitor_responsable;
       this.setState({activecard:e, citadetail:cita, monitor:monitor  });  
    }

    /**alta productor */
    saveCita= async (e) =>{
        e.preventDefault();
        /*const validate = this.validateUserInformation();
        if(validate) {*/
            const monitor= this.props.authentication.user.id;
            const res = await fetch(`${url_api}agenda_citasTP/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.authentication.token}`
                },
                body: JSON.stringify({                   
                    lugar: this.state.lugar,
                    fecha: this.state.fecha,
                    hora: this.state.hora,
                    tipo_monitoreo: this.state.tipo_monitoreo,
                    sector_id: '29',
                    monitor_responsable_id: monitor
                })
            }) 
            
            const data = await res.json();
            
            console.log('1',data);
                if (data.id !== undefined) {
                                     
                    let timerInterval
                    MySwal.fire({
                        title: 'Guardando cita...',
                        showConfirmButton: false,
                        timer: 2000,
                        willOpen: () => {
                            MySwal.showLoading()
                                                    
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                            if (
                                /* Read more about handling dismissals below */
                                result.dismiss === MySwal.DismissReason.timer
                            ) {
                                MySwal.fire({
                                    icon: 'success',
                                    title: 'Se Creo Correctamente la Cita',
                                    showConfirmButton: true,
                                })                        
                            }
                    })
                    this.componentDidMount();

                    this.setState({
                        lugar: '',
                        fecha: '',
                        hora: '',
                        tipo_monitoreo: '',
                        sector_id: '',
                        monitor_responsable_id: '',
                        showModal: false
                    })

                } else {
                     
                    MySwal.fire({
                        icon: 'warning',
                        title: 'Algo Salio mal!!',
                        text: 'revisa tus datos',
                        showConfirmButton: true
                        })
                }         

        
    }

    validateData() {
            /*let error = {};
            let {user} = this.state;
            let validUser = true;
            //first_names
            if (!user["first_name"]) {
                validUser = false;
                error["first_name"] = "Por favor, ingresa tus Nombres.";
                this.setState({error})
            }

            if (typeof user["first_name"] !== "undefined") {
                if (!user["first_name"].match(/^[a-zA-Z ]+$/)) {
                    validUser = false;
                    error["first_name"] = "Solo letras";
                    this.setState({error})
                }
            }

            // last_names
            if (!user["last_name"]) {
                validUser = false;
                error["last_name"] = "Por favor, ingresa tus Apellidos.";
                this.setState({error})
            }

            if (typeof user["last_name"] !== "undefined") {
                if (!user["last_name"].match(/^[a-zA-Z ]+$/)) {
                    validUser = false;
                    error["last_name"] = "Solo letras";
                    this.setState({error})
                }
            }

            // razonSocial
            if (!user["razonSocial"]) {
                validUser = false;
                error["razonSocial"] = "Por favor, ingresa razon social.";
                this.setState({error})
            }

            // rfc
            if (!user["rfc"]) {
                validUser = false;
                error["rfc"] = "Por favor, ingresa rfc.";
                this.setState({error})
            }

            // Email
            if (!user["email"]) {
                validUser = false;
                error["email"] = "Por favor, ingresa tu correo.";
                this.setState({error})
            }
            
            if (typeof user["email"] !== "undefined") {
                let posicionArroba = user["email"].lastIndexOf('@');
                let posicionPunto = user["email"].lastIndexOf('.');

                if (!(posicionArroba < posicionPunto && posicionArroba > 0 && user["email"].indexOf('@@') === -1 && posicionPunto > 2 && (user["email"].length - posicionPunto) > 2)) {
                    validUser = false;
                    error["email"] = "Por favor, ingresa un correo válido.";
                    this.setState({error})
                }
            }

            
            return validUser;*/
    }
    
    handleChange(e) {
        let nam = e.target.name;
        let val = e.target.value;
        this.setState({[nam]: val});
        console.log({[nam]: val});
    }
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }
    
    
    render() {
        var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
        var diasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado");
        var f=new Date(this.state.setDateState);
        const fecha = f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear();
        const dia = diasSemana[f.getDay()] 
        console.log(this.props.authentication.user.id)
        return (
            <>
                <LayoutTwo title={this.state.title} progress="true" classText="alta-prod">
                    <div className="grid grid-cols-12">
                        <div className="col-start-2 col-span-5">
                            <h2 className="font-bold text-primary my-8">Agenda citas con el productor </h2>
                            <Calendar 
                                value={this.state.dateState}
                                onChange={ (e) => this.changeDate(e)}
                            />
                            <h2 className="font-bold text-primary mt-8 mb-4">Información de la cita: </h2>
                            <div>
                                <div  className="flex">
                                    <p className="green-color mr-2 mb-2">Tipo de monitoreo:</p>
                                    <p className="green-color mr-2 mb-2">{this.state.citadetail.tipo_monitoreo}</p>
                                </div>
                                <div  className="flex">
                                    <p className="green-color mr-2 mb-2">ID productor:</p>
                                    <p className="green-color mr-2 mb-2">{this.state.monitor.id}</p>
                                </div>
                                <div  className="flex">
                                    <p className="green-color mr-2 mb-2">Nombre del productor:</p>
                                    <p className="green-color mr-2 mb-2">{this.state.monitor.first_name} {this.state.monitor.last_name}</p>
                                </div>                                
                                <div  className="flex">
                                    <p className="green-color mr-2 mb-2">Razón social:</p>
                                    <p className="green-color mr-2 mb-2">{this.state.monitor.razonSocial}</p>
                                </div>                                                               
                                <div  className="flex">
                                    <p className="green-color mr-2 mb-2">Responsable del cultivo:</p>
                                    <p className="green-color mr-2 mb-2">{this.state.monitor.razonSocial}</p>
                                </div>
                            </div>   
                        </div>
                        <div className="col-span-6">
                            <div className="flex my-8">
                                <h3 className="font-bold text-five title-2 m-8">{fecha}</h3>
                                <h6 className="text-green-700 m-2">{dia}</h6>
                                <button onClick={this.handleOpenModal} className="inline-block  py-1 px-8 mx-4 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                    Agregar
                                </button>
                            </div>
                            <div className="flex my-16 items-center">
                                <p className="mx-8">7:00 am</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '07:00:00' ? 
                                                x.hora < '08:00:00'? 
                                                <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ? 
                                                            "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                            "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                    <div>{x.lugar}</div>
                                                    <div>Productor: {x.monitor_responsable.first_name}</div>
                                                    <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">8:00 am</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '08:00:00' ? 
                                              x.hora < '09:00:00'? 
                                                <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                            "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                            "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                    <div>{x.lugar}</div>
                                                    <div>Productor: {x.monitor_responsable.first_name}</div>
                                                    <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                </div>: ''
                                              :''}
                                            </div>
                                        )
                                    })}
                                </div>    
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">9:00 am</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '09:00:00' ? 
                                                x.hora < '10:00:00'? 
                                                    <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                        <div>{x.lugar}</div>
                                                        <div>Productor: {x.monitor_responsable.first_name}</div>
                                                        <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">10:00 am</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '10:00:00' ? 
                                                x.hora < '11:00:00'? 
                                                    <div  onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                        <div>{x.lugar}</div>
                                                        <div>Productor: {x.monitor_responsable.first_name}</div>
                                                        <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>    
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">11:00 am</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '11:00:00' ? 
                                                x.hora < '12:00:00'? 
                                                    <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                        <div>{x.lugar}</div>
                                                        <div>Productor: {x.monitor_responsable.first_name}</div>
                                                        <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>    
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">12:00 pm</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '12:00:00' ? 
                                                x.hora < '13:00:00'? 
                                                    <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ? 
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                    <div>{x.lugar}</div>
                                                    <div>Productor: {x.monitor_responsable.first_name}</div>
                                                    <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>    
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">1:00 pm</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '13:00:00' ? 
                                                x.hora < '14:00:00'? 
                                                    <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                        <div>{x.lugar}</div>
                                                        <div>Productor: {x.monitor_responsable.first_name}</div>
                                                        <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>   
                            </div>  
                            <div className="flex my-16 items-center">
                                <p className="mx-8">2:00 pm</p>
                                <div className="grid">
                                    {this.state.citas.map((x, i)=> {
                                        return (
                                            <div className="my-2" key={i}>{x.hora >= '14:00:00' ? 
                                                x.hora < '15:00:00'? 
                                                    <div onClick={(e) => this.cardChange(x.id)}  className={this.state.activecard !== x.id ?
                                                                "cursor-pointer inline-block py-2 px-12 mx-4 green-color transition bg-white border-2 border-green-color rounded-2xl shadow ripple hover:shadow-lg bg-green-color-hover focus:outline-none":
                                                                "inline-block py-2 px-12 mx-4 text-white transition bg-green-color rounded-2xl ripple"}>
                                                        <div>{x.lugar}</div>
                                                        <div>Productor: {x.monitor_responsable.first_name}</div>
                                                        <div>Tipo de monitoreo: {x.tipo_monitoreo}</div>
                                                    </div>: ''
                                                :''}
                                            </div>
                                        )
                                    })}
                                </div>    
                            </div>                          
                        </div>
                        <Modal 
                        isOpen={this.state.showModal}
                        ariaHideApp={false}
                        contentLabel="Modal #1 Global Style Override Example"
                        onRequestClose={this.handleCloseModal}
                        style={customStyles}
                        >
                        <div  className="flex justify-between">
                            <p>Agregar Cita</p>
                            <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 " onClick={this.handleCloseModal}>X</button>
                        </div>    
                        <div >
                            <form  onSubmit={this.saveCita}>
                                <div className=" form bg-white rounded  overflow-hidden ">´
                                        
                                    <div className="input-field">
                                            <label htmlFor="lugar" className="active">Lugar</label>
                                            <select id="lugar" name="lugar" value={this.state.lugar}  onChange={this.handleChange} className="input-3 validate" 
                                            title="Favor de Seleccionar un lugar de la Lista.">
                                                <option value="">Selecciona un lugar</option>
                                                {this.state.lugares.map((lugar,i)=> {
                                                    return (
                                                    <option  key={i}  value={lugar.nombre}>{lugar.nombre}</option>
                                                    );
                                                })}
                                            </select>    
                                            <span className="helper-text">{this.state.error["lugar"]}</span>                                            
                                    </div>
                                    <div className="input-field">
                                            <label htmlFor="tipo_monitoreo" className="active">tipo_monitoreo</label>
                                            <select id="tipo_monitoreo" name="tipo_monitoreo" value={this.state.tipo_monitoreo}  onChange={this.handleChange} className="input-3 validate" 
                                            title="Favor de Seleccionar un tipo monitoreo de la Lista.">
                                                <option value="">Selecciona un tipo monitoreo</option>
                                                {this.state.monitoreo.map((x,i)=> {
                                                    return (
                                                    <option  key={i}  value={x.nombre}>{x.nombre}</option>
                                                    );
                                                })}
                                            </select>    
                                            <span className="helper-text">{this.state.error["monitoreo"]}</span>                                            
                                    </div>
                                    <div className="input-field">
                                            <label htmlFor="fecha" className="active">fecha</label>
                                            <input id="fecha"
                                                    className="input-3"
                                                    type="date"
                                                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                                    onChange={this.handleChange}
                                                    name="fecha"
                                                    value={this.state.fecha}/>    
                                            <span className="helper-text">{this.state.error["fecha"]}</span>                                            
                                    </div>                                    
                                    <div className="input-field">
                                            <label htmlFor="hora" className="active">hora</label>
                                            <input id="hora"
                                                    className="input-3"
                                                    type="time"
                                                    pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                    onChange={this.handleChange}
                                                    name="hora"
                                                    value={this.state.hora}/>    
                                            <span className="helper-text">{this.state.error["hora"]}</span>                                            
                                    </div>
                                </div>
                                                            
                                <div className="p-4">
                                    <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center  ">Guardar cita</button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    </div>
                </LayoutTwo>
                
            </>
        )

    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}


export default withRouter(connect(mapStateToProps)(GoogleApiWrapper({
    apiKey: 'AIzaSyBjmEetWBMYt7YMitfzk6wj_5BMqWrKfGg'
})(CrearCita)))