import React from "react";
import NavigationTwo from "../components/NavigationAprod.js";

function LayoutTwo(props) {
    const ruta =props.title;
    return (

        <div className="font-body">
            {ruta !== 'qr'? <NavigationTwo title={props.title} showProgress={props.progress} active={props.active} />: ''}
            <div className={ruta !== 'qr'? `dashboard ${props.classText}` : 'bg-qr'}>
                <div>
                    <div className="">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayoutTwo