import React, {Component} from 'react';
import {Link} from "react-router-dom";
import productor from '../../assets/img/menu/productor@2x.png';
import cultivo from '../../assets/img/menu/cultivo@2x.png';
import cita from '../../assets/img/menu/cita@2x.png';
import reportes from '../../assets/img/menu/reportes@2x.png';

class HomeIngenieros extends Component{
    
    render() {
        return (
            <div className="home-ingenieros">
                <div className="p-4 text-center mb-8">
                    <h1 className="font-bold text-3xl text-primary">¡Bienvenido!</h1>
                    <p className="text-secondary">Selecciona una opción para continuar </p>
                </div>
                <div className="grid grid-cols-4 gap-12">
                    <div className="item bg-third rounded-full">
                        <Link to="/altaproductor">
                            <div className="text-center">
                                <img src={productor} alt="Alta productor" className="mx-auto mb-4"/>
                                <p className="text-white">Dar de alta <br /> a un
                                    productor</p>
                            </div>
                        </Link>
                    </div>
                    <div className="item bg-secondary rounded-full">
                        <Link to="#">
                            <div className="text-center">
                                <img src={cultivo} alt="Cultivo" className="mx-auto mb-4"/>
                                <p className="text-white">Diagnóstico <br />de cultivos</p>
                            </div>
                        </Link>
                    </div>
                    <div className="item bg-secondary rounded-full">
                        <Link to="crearcita">
                            <div className="text-center">
                                <img src={cita} alt="Citas" className="mx-auto mb-2"/>
                                <p className="text-white">Crear una cita <br /> para pruebas</p>
                            </div>
                        </Link>
                    </div>
                    <div className="item bg-secondary rounded-full">
                        <Link to="#">
                            <div className="text-center">
                                <img src={reportes} alt="Descarga de reportes" className="mx-auto mb-4 mt-4"/>
                                <p className="text-white">Descarga de <br /> reportes</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeIngenieros;