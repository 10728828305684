import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import TabNav from './components/TabNav';
import Tab from './components/Tab';
import {url_api} from "../../constants";
import { Redirect } from "react-router-dom";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '20%',
      left                  : '20%',
      right                 : '20%',

    }
  };

class RepoProductor extends Component {
    

    constructor() {
        super();
        
        this.state = {
            users : [],
            selected: 'Home',
            item: '',
            id: '',
            status: '',
            orden:'',
            infocultivo: '',
            infouser:'',
            fertirriego: [],
            plagas: [],
            nutricion: [],
            acept: false,
            comentario: '',
            showModal: false,
            response: '', 
            factor:'',

            fechaord: '',
            idnext: '',
            datan: '',
            idprev:'',
            datap:'',

        }
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'https://testapi.green-up.xyz';
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.rechazarOrden = this.rechazarOrden.bind(this);
    }
   
    setSelected = (id) => {
       window.location.replace('/reporte-lab/' + id);
       //this.setState({ selected: tab });
    }

    componentDidMount= async () => {
        const {match} = this.props;
        const id = match.params.id;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}ordenes/${id}/`, options);
        const data = await res.json();

        if(!data.detail){
            let timerInterval
            MySwal.fire({
                title: 'Cargando Reporte...',
                showConfirmButton: false,
                timer: 2000,
                willOpen: () => {
                    MySwal.showLoading()
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
            })
            this.state.fechaord = data.orden.fecha_monitoreo;
            const resall = await fetch(`${url_api}ordenes/`, options);
            const datall = await resall.json();
            let list =datall.filter(order => order.status === 3 || order.status === 4 || order.status === 5);
            
            const idnext = list.find(element => element.id > id);
            if(idnext!==undefined){  
                const resn = await fetch(`${url_api}ordenes/${idnext.id}/`, options);
                const datan = await resn.json();
                const ordenext = datan.orden;
                this.setState({datan:ordenext});
            }

            let listpre = list.filter(item => item.id < id);
            if(listpre.length!==0){
                const idprev = Math.max(...listpre.map(item => item.id), 0);
                console.log('listpre',listpre, idprev);
            
                if(idprev!==undefined){  
                    const resp = await fetch(`${url_api}ordenes/${idprev}/`, options);
                    const datap = await resp.json();
                    const ordenprev = datap.orden;
                    this.setState({datap:ordenprev});
                }

            } 
            const orden = data.orden;
            const fechaord = data.orden.fecha_monitoreo;
            const infocultivo = data.orden.informacion_de_cultivo;
            const fertirriego = data.orden.fertirriego_complementos;
            const infouser = infocultivo.sector.rancho.user;
            const plagas = data.plagas_enfermedades;
            const nutricion = data.orden.orden_details;
            const status = data.orden.status;
            var factor = infocultivo.sector.rancho.user.factor;
            
               
            const select=id+'-'+fechaord;
            this.setState({
                id: id, 
                orden: orden, 
                infocultivo:infocultivo, 
                infouser:infouser,
                fertirriego: fertirriego, 
                plagas:plagas, 
                nutricion:nutricion, 
                status:status,
                factor:factor,
                list:list,
                selected:select
            });
        }
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
        console.log({[nam]: val});
    }

    aceptarOrden = async (id) => {
        const res = await fetch(`${url_api}orden_status/${id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.props.authentication.token}`
            },
            body: JSON.stringify({
              status: this.state.status+1,
              comentarios: [
                {       
                  comentario: '',
                  user_id: this.props.user.id
                }
              ]
            })
        }) 
        const response = await res.json();
        const acept = true;

        this.setState({response: response, acept: acept});
        
     
    };

    rechazarOrden = async (e) => {
        e.preventDefault();
        const id = this.state.id;
        const res = await fetch(`${url_api}orden_status/${id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.props.token}`
            },
            body: JSON.stringify({
              status: this.state.status+1,
              comentarios: [
                {       
                  comentario: this.state.comentario,
                  user_id: this.props.user.id
                }
              ]
            })
        }) 
        const response = await res.json();
        
        const acept = true;

        this.setState({response: response, acept: acept});
    };

    handleOpenModal () {
        this.setState({ showModal: true });
      }
      
    handleCloseModal () {
        this.setState({ showModal: false });
      }
      
    
    render() {
        
        let clase = '';
         
        if(this.props.user.is_administrador) {
            clase = 'repo-productor';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        if(this.props.user.is_quimico) {
            clase = 'repo-productor';
        }
        let $buttona = null;
        let $buttonb = null;

        if (this.state.status === 6) {
            
        } else {
            $buttona = (<button className="tab-link text-white  bg-green-500 rounded-full p-3 m-2" onClick={(e) => this.aceptarOrden(this.state.id)}>Aprobar</button>);
            $buttonb = (<button className="tab-link text-white  bg-red-500  rounded-full p-3 m-2"  onClick={this.handleOpenModal}>Rechazar</button>);
        }

        if (this.state.acept===true)
           return <Redirect to={"/home"} />

        const orden =this.state.id+'-'+this.state.fechaord;
        const ido =this.state.id;
        let ordenn = '';
        let ordenp = '';
        let ordenes = [];
        ordenes.push(ido,orden);
        const idn =this.state.datan.id;
        const idp =this.state.datap.id;
        if (this.state.datan !== ''){
           ordenn= this.state.datan.id+'-'+this.state.datan.fecha_monitoreo;
           ordenes.push(idn, ordenn); 
        }else{
           ordenn= null;
           ordenes.push(ordenn,ordenn);
        } 
        if (this.state.datap !== ''){
            ordenp= this.state.datap.id+'-'+this.state.datap.fecha_monitoreo;
            ordenes.push(idp,ordenp);
            //ordenes = [orden, ordenn];
        }else{
            ordenp= null;
            ordenes.push(ordenp,ordenp);
        }    
        return (

            <Layout className={clase} classText={clase}>
                
                
                <div className=" mb-4">
                    <div className="flex items-center">
                        <h2 className="font-bold text-primary">Productor </h2>
                        {$buttona}
                        {$buttonb}
                    </div>
                    <Modal 
                        isOpen={this.state.showModal}
                        ariaHideApp={false}
                        contentLabel="Modal #1 Global Style Override Example"
                        onRequestClose={this.handleCloseModal}
                        style={customStyles}
                        >
                        <div  className="flex justify-between">
                            <p>Agrega un Comentario</p>
                            <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 " onClick={this.handleCloseModal}>X</button>
                        </div>    
                        <div >
                            <form onSubmit={this.rechazarOrden} >
                                <div className=" form bg-white rounded  overflow-hidden ">´
                                    <div className="my-1 mx-2">
                                        <textarea id="comentario"
                                            name="comentario"
                                            type="textarea"
                                            rows="10"
                                            cols="10"
                                            value={this.state.comentario} 
                                            onChange={this.handleChange}
                                            className="validate w-full border-b text-green-500 focus:outline-none pl-value-res-form"
                                            placeholder="comentario ej. Falta información"
                                            required
                                            title="Favor de Agregar un comentario del problema por el cual se rechaza. ej. Falta Información"
                                        />                                             
                                    </div>
                                </div>
                                                            
                                <div className="p-4">
                                    <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center  ">Enviar Comentario</button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    <div className="grid grid-cols-12 gap-4 ">
                        
                        <div className="App mt-4 col-span-10">
                                <TabNav tabs={ordenes} selected={ this.state.selected } setSelected={ this.setSelected }>
                                    <Tab isSelected={ this.state.selected === orden}>
                                        <div> <img className="qr-img mx-5" src=""/><p>ID Productor</p><p>0000001</p></div>
                                       
                                        <div className="flex col-span-2 grid grid-cols-2 gap-4">
                                            <div>
                                                <h5 className="py-2 pr-4 text-green-400">Cultivo</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Variedad</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Nombre</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Ubicacion</h5>
                                            </div>
                                            <div className="content-rp">
                                                <h5 className="rp-content p-1 w-full text-green-400">{this.state.infocultivo.cultivo}</h5>
                                                <h5 className="rp-content p-1 w-full text-green-400">{this.state.infocultivo.variedad}</h5>
                                                <h5 className="rp-content p-1 w-full text-green-400">{this.state.infouser.first_name} {this.state.infouser.last_name}</h5>
                                                <h5 className="rp-content p-1 w-full text-green-400"></h5>
                                            </div>
                                        </div>
                                        <div className="flex col-span-2 grid grid-cols-2 gap-4">
                                            <div>
                                                <h5 className="py-2 pr-4 text-green-400">Fecha Monitoreo</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Huerto</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Superficie</h5>
                                                <h5 className="py-2 pr-4 text-green-400">Densidad Plantas</h5>
                                            </div>
                                            <div className="content-rp">
                                                <h5 className="rp-content p-1 w-full text-green-400">{this.state.orden.fecha_monitoreo}</h5>
                                                <h5 className="rp-content p-1 w-full text-green-400"></h5>               
                                                <h5 className="rp-content p-1 w-full text-green-400">{this.state.infocultivo.superficie_ha} HA</h5>
                                                <h5 className="rp-content p-1 w-full text-green-400"></h5>
                                            </div>
                                        </div>

                                    </Tab>
                                    
                                </TabNav>
                            </div>
                        
                    <div> 
                       {/* <div className="container mx-auto col-span-12">
                            <div className="pt-8">
                                <div>
                                <h1 className="flex-1 font-bold text-white">Indicaciones</h1>
                                </div>
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Indicaciones</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Lunes</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Martes</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Miercoles</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Jueves</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Viernes</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Sabado</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Domingo</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Costo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                             
                                                <tr>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">Fertirriego 1</p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap"></p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">A</p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap"></p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">A</p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap"></p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap"></p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap"></p>
                                                    </td>
                                                    <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-gray-900 whitespace-no-wrap">$2,337.77</p>
                                                    </td>
                                                </tr>
                                                   
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                <div className="flex justify-end">
                                    <div className="total-ind col-span-6  ">
                                        <div className="total-text px-5 py-2">
                                            <h5>Total</h5>
                                            <h5>Costo Semanal</h5>
                                            <h5>Costo por HA</h5>
                                        </div>
                                        <div className="total-num px-5 py-2 text-white">
                                            <h5>$ 7.270,58</h5>
                                            <h5>$ 7.270,58</h5>
                                            <h5>$ 7.270,58</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div className="container mx-auto col-span-12">
                            <div className="pb-8">
                                <div>
                                <h1 className="flex-1 font-bold text-white">Complementos basicos por semana para fertirriego</h1>
                                </div>
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Componente</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Dosis HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">No. HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">HA x Semana</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Unidades</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Requerimiento<br /> Semanal</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Presentacion</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Costo</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Costo por Semana</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fertirriego.map((x, i) => {
                                                        return (
                                                        <tr  key={x.id}>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.producto.nombre}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.dosis_HA}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.unidades_HA_semana}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.HA_por_semana}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.unidad_medida_existencia}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.requerimiento_semanal}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.presentacion}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">$ {x.costo}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">$ {x.costo_por_semana}</p>
                                                            </td>
                                                        </tr>
                                                    );
                                                                    
                                                })} 
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                        

                        <div className="container mx-auto col-span-12">
                            <div className="pb-8">
                                <div>
                                <h1 className="flex-1 font-bold text-white">Aplicaciones foliares</h1>
                                </div>
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Semana</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">No. <br/> Aplicacion</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Agroinsumo</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Ingrediente Activo</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Dosis x HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Tambos por HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Unidades <br />tambos x HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Dosis <br/>total x HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">8 HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Unidades</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Modo de <br/> aplicacion</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Control</th>
                                                </tr>
                                            </thead>
                                            <tbody className="px-5 py-2  bg-white text-sm" >
                                            {this.state.plagas.map((x, i) => {
                                                return (
                                                    <tr className="px-5 py-2 border-b border-gray-200 bg-white text-sm" key={x.id} >

                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm " >
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.semana}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.no_aplicacion}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">0</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.producto.nombre}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.dosis_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.tambos_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.unidad_medida_dosis_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.tambos_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.unidad_medida_dosis_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <table className="border-detail">
                                                                <tbody  >
                                                                {x.plagas_enfermedades_ingredientes.map((y, i) => {
                                                                    return (
                                                                    <tr  key={y.id} className="border-none" >
                                                                        <td className="border-none bg-white text-sm">
                                                                            <p className="text-gray-900 whitespace-no-wrap">{y.tambos_por_HA}</p>
                                                                        </td>
                                                                    </tr>
                                                                    );
                                                                })} 
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <p className="text-gray-900 whitespace-no-wrap">8</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                            <p className="text-gray-900 whitespace-no-wrap">8</p>
                                                        </td>
                                                        
                                                    </tr>
                                                );
                                            })}     
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>*/}
                    </div>  
                        <div className="container mx-auto col-span-12">
                            <div className="pb-8">
                                <div>
                                <h1 className="flex-1 font-bold text-white">Nutrición</h1>
                                </div>
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Semana</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Fecha</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">No. <br/> Aplicacion</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Fertilizante</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Kg o Lt/HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Kg o Lt/HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">$ Precio Unitario</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">$ Precio MN</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">USD $$</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Tonelada <br/> Importe 100%</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Precio /<br/> Semana</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Kg o Lt/HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Ha-kg/<br/>Productor</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Garrafa <br/> 50 LT/HA</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Importe 100 %</th>
                                                </tr>
                                            </thead>
                                            <tbody className="px-5 py-2  bg-white text-sm" >
                                                {this.state.nutricion.map((x, i) => {
                                                    return (
                                                        <tr className="px-5 py-2 border-b border-gray-200 bg-white text-sm" key={x.id}  >

                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm " >
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.aplicacion_nutricion.semana}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm " >
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.aplicacion_nutricion.mes}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.aplicacion_nutricion.no_aplicacion}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{y.producto.nombre}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{y.kg_lt_HA_1}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        var numero = y.kg_lt_HA_1; 
                                                                        var conDecimal = numero.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{y.precio_unitario}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{y.precio_mn}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{y.factor_tonelada_usd}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        let $tonelada100 = y.kg_lt_HA_1*y.precio_unitario;
                                                                       
                                                                        var conDecimal = $tonelada100.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        var numero = y.kg_lt_HA_1; 
                                                                        var conDecimal = numero.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        let $kg_lt_HA_11 = y.kg_lt_HA_1*this.state.factor;
                                                                        
                                                                        var conDecimal = $kg_lt_HA_11.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        
                                                                        let $kg_lt_HA_11 = y.kg_lt_HA_1*this.state.factor;
                                                                        let $ha_productor = $kg_lt_HA_11*this.state.infocultivo.superficie_ha;
                                                                        var conDecimal = $ha_productor.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    
                                                                        <tr   className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{x.aplicacion_nutricion.garrafa50_lt_HA}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm" >
                                                                <table className="border-detail">
                                                                    <tbody  >
                                                                    {x.aplicacion_nutricion.nutricion_ingredientes.map((y, i) => {
                                                                        const tonelada100 = y.kg_lt_HA_1*y.precio_unitario;
                                                                        const factor = parseFloat(this.state.factor)+0.2; 
                                                                        let $importe100 =  factor*tonelada100;
                                                                        var conDecimal = $importe100.toFixed(2);
                                                                        return (
                                                                        <tr  key={y.id} className="border-none" >
                                                                            <td className="border-none bg-white text-sm">
                                                                                <p className="text-gray-900 whitespace-no-wrap">{conDecimal}</p>
                                                                            </td>
                                                                        </tr>
                                                                        );
                                                                    })} 
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}     
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    
                    </div>
                </div>
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(RepoProductor))
