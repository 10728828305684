import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux'
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';
import logo from '../../assets/img/logo.svg';
import LayoutTwo from "../../layouts/LayoutTwo";

import './styles.css';
import {url_api} from "../../constants";
import QrReader from 'react-qr-reader'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

class QR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campo: [],
            error: {},
            user: {
                is_administrador: false,
                is_productor: true,
                is_ingeniero_de_Campo: false,
                is_chofer: false,
                is_quimico: false,
                is_cobranza: false,
                is_administrativo: false,
                factor: 0,
            },
         
            title: 'qr',
            
            activecard: 0,
            switch:false,
            dateState:new Date(), 
            setDateState:new Date(),
            result: 'No result'
        };

        
        this.handleChange = this.handleChange.bind(this);
         
        
    }
    
    /** pasos
     * Cambia los pasos
     **/
    componentDidMount= async () =>  {
        navigator.geolocation.watchPosition((position) => {
            if (position.coords !== undefined) {
                //console.log('entra', position.coords.latitude, position.coords.longitude);
                this.setState({ latitud: position.coords.latitude, longitud: position.coords.longitude });
            }
        });
        
        
        
    }
    handleScan = data => {
        if (data) {
          this.setState({
            result: data
          })
        }
      }
      handleError = err => {
        console.error(err)
      }
    
    handleChange(e) {
        let nam = e.target.name;
        let val = e.target.value;
        this.setState({[nam]: val});
        console.log({[nam]: val});
    }
    
    
    
    render() {
      
        return (
            <>
               <LayoutTwo title={this.state.title} progress="true" classText="alta-prod">
                <div className="container box-border p-4 mx-auto">
                    <div className="p-4 text-center m-16 ">
                        <img src={logo} alt="logo" className="mx-auto mb-4"/>
                        <h1 className="font-bold text-xl text-white my-8">Escaneo QR </h1>
                        <h3 className="text-green-300 my-8">Por favor acerca el código al lector e intenta mantenerlo lo más centrado
                              posible para capturarlo correctamente. </h3>
                        <div  className="grid grid-cols-3">
                            <div className="col-start-2 col-span-1">
                            <QrReader
                            delay={100}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: '100%'}}
                            />
                            <p>{this.state.result}</p>
                            </div>
                        </div>    
                    </div>
                    
                </div>
                </LayoutTwo>
                
            </>
        )

    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}


export default withRouter(connect(mapStateToProps)(GoogleApiWrapper({
    apiKey: 'AIzaSyBjmEetWBMYt7YMitfzk6wj_5BMqWrKfGg'
})(QR)))