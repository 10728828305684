import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux'
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

import LayoutTwo from "../../layouts/LayoutTwo";
import infoprod from "../../assets/img/partials/info-prod.png";
import next1 from "../../assets/img/partials/next-1.png";
import iconpdf from "../../assets/img/partials/icon-pdf.png";

import './styles.css';
import {url_api} from "../../constants";
import {handleResponse} from "../../rcrx/helpers";
import infoCulti from "../../assets/img/partials/info-culti.svg";
import figureLeft from "../../assets/img/partials/figure-left.svg";
import line from "../../assets/img/partials/line.png";
import linever from "../../assets/img/partials/linever.png";
import figureRigth from "../../assets/img/partials/figure-rigth.svg";
import cardPlant from "../../assets/img/partials/card-plant.svg";
import Plant from "../../assets/img/partials/plant.png";
import axios from 'axios';
// Using an ES6 transpiler like Babel
import Slider from 'react-rangeslider'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TripOriginSharp } from "@material-ui/icons";

const MySwal = withReactContent(Swal)



class AltaProductor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campo: [],
            error: {},
            user: {
                is_administrador: false,
                is_productor: true,
                is_ingeniero_de_Campo: false,
                is_chofer: false,
                is_quimico: false,
                is_cobranza: false,
                is_administrativo: false,
                factor: 0,
            },
            
            directionDelivery: {},
            directionInvoicing: {},
            address: {
                direccion_factura: false,
                datos_entrega: false,
            },

            enviado: false,
            step1: true,
            step2: false,
            step6: false,
            step7: false,
            substep1: true,
            substep2: false,
            substep3: false,
            title: 'Alta productor',
            active: 1,
            productor_id: 38,
            latitud: '',
            longitud: '',
            productorResponse: [],

            cultivos: [],
            variedades: [],
            temporadas: [],
            statustem: false,

            medios: [
                {id: 1,
                nombre: 'Hidroponia'},
                {id: 2,
                nombre: 'Suelo'}
            ],

            ranchodata:{
                nombre: "", 
                empresa: "",
                rfc: "",
                superficie1: "",
                user: "",
                nombre_encargado: "",
                telefono_encargado: "",
                mapa: "",
                ine_anverso: "",
                ine_reverso: "",
                
            },

            geoposicion:{
                latitud: '',
                longitud: '',
            },
            imagePreviewUrlMapa: "",
            imagePreviewUrlIneA: "",
            imagePreviewUrlIneR: "",

            sectoresdata: [],

            itemsectordata: {},
            id_sector: 0,

            newsector: [{
                cant:""
            }],
            cant: 0,
            cant2: 0,
            
            listrancho: [],
            ranchocul : [],
            ranchosec: [],
            
            infoCultivo: {
                temporada_id: '',
                cultivo_id: '',
                superficie: '',
                variedad_id: '',
                medio: '',
                fecha_plantacion: '',
                fecha_poda: '',
                lineas_planta_cama:'',
                distancia_entre_plantas:'',
                distancia_entre_camas:'',
                largo_cama:'',
                distancia_por_hectarea:'',
                plantas_ha:'',
            },
            
            infocultivo_id:'',
            rancho_id: '',
            medio: '',
            infoRiego: {
                dias_aplicacion_fertirriego_por_semana: '',
                tipo_agua: '',
                tipo_sistema_riego: '',
                tipo_regante: '',
                volumen_por_gotero: 0,
                distancia_entre_gotero: '',
                goteros_por_planta: 0,
                numero_cintas_por_cama: '',
                tiempo_riego: '',
                volumen_por_planta: 0,
                numero_de_pulsos: 0,
                tiempo_por_pulsos: 0,
                intervalo_entre_pulsos: 0,
                volumen_por_ha: 0,
                inicio_primer_pulso: '00:00',
                termino_ultimo_pulso: '00:00',
                distancia_entre_camas: 0

            },

            paramMedicion:{
                suelo_ph: null,
                suelo_conductividad: null,
                suelo_humedad_rango_actual: null,
                suelo_vol_por_min_ha: null,
                suelo_vol_agua_por_dia: null,
                sustrato_vol: null,
                sustrato_ph: null,
                sustrato_ce: null,
                recom_num_por_pulsos: null,
                recom_tiempo_por_pulsos: null,
                recom_intervalo_entre_pulsos: null,
                exp_sustrato_vol: null,
                exp_sustrato_ph: null,
                exp_sustrato_ce: null,
                drenaje_vol: null,
                drenaje_ph: null,
                drenaje_ce: null
            },

            dias: [
                {id: 1,
                status: false},
                {id: 2,
                status: false},
                {id: 3,
                status: false},
                {id: 4,
                status: false},
                {id: 5,
                status: false},
                {id: 6,
                status: false},
                {id: 7,
                status: false},
            ],

            tiporegante: [
                {id:'1',nombre: 'Canal'},
                {id:'2',nombre: 'Pozo profundo'},
                {id:'3',nombre: 'Pozo + Membrana'},
                {id:'4',nombre: 'Pozo + Inyector'},
                {id:'5',nombre: 'Pozo + Membrana + Inyector'}
            ],
             
            humedad:'',

            
            analisisfisi: [{
                agua: '',
                fibra: '',
                suelo: '',
                fertibilidad:'',
                sector:'' 
            },{
                agua: '',
                fibra: '',
                suelo: '',
                fertibilidad:'',
                sector:'' 
            },{
                agua: '',
                fibra: '',
                suelo: '',
                fertibilidad:'',
                sector:'' 
            }],
            switch:false,
            estados: [
                {nombre: 'Aguascalientes'},
                {nombre: 'Baja California'},
                {nombre: 'Baja California Sur'},
                {nombre: 'Campeche'},
                {nombre: 'Chiapas'},
                {nombre: 'Chihuahua'},
                {nombre: 'Ciudad de México'},
                {nombre: 'Coahuila'},
                {nombre: 'Colima'},
                {nombre: 'Durango'},
                {nombre: 'Guanajuato'},
                {nombre: 'Guerrero'},
                {nombre: 'Hidalgo'},
                {nombre: 'Jalisco'},
                {nombre: 'Estado de México'},
                {nombre: 'Michoacán de Ocampo'},
                {nombre: 'Morelos'},
                {nombre: 'Nayarit'},
                {nombre: 'Nuevo León'},
                {nombre: 'Oaxaca'},
                {nombre: 'Puebla'},
                {nombre: 'Querétaro'},
                {nombre: 'Quintana Roo'},
                {nombre: 'San Luis Potosí'},
                {nombre: 'Sinaloa'},
                {nombre: 'Sonora'},
                {nombre: 'Tabasco'},
                {nombre: 'Tamaulipas'},
                {nombre: 'Tlaxcala'},
                {nombre: 'Veracruz'},
                {nombre: 'Yucatán'},
                {nombre: 'Zacatecas'},
                
            ],
        };

        this.validateUserInformation = this.validateUserInformation.bind(this)
        this.setAltaProductor = this.setAltaProductor.bind(this);
        this.changeInputUser = this.changeInputUser.bind(this);
        this.changeDirection = this.changeDirection.bind(this);
        this.setTemporada = this.setTemporada.bind(this);
        this.changeRiego = this.changeRiego.bind(this);
        //this.changeCultivo = this.changeCultivo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageMapa = this.handleImageMapa.bind(this);       
        this.handleImageIneA = this.handleImageIneA.bind(this);
        this.handleImageIneR = this.handleImageIneR.bind(this);
        this.setTabs = this.setTabs.bind(this);        
        this.changeParametros = this.changeParametros.bind(this);
        this.clearData = this.clearData.bind(this);
        this.handleFileAnFi = this.handleFileAnFi.bind(this);
    }
    
    /** pasos
     * Cambia los pasos
     **/
    componentDidMount= async () =>  {
        navigator.geolocation.watchPosition((position) => {
            if (position.coords !== undefined) {
                //console.log('entra', position.coords.latitude, position.coords.longitude);
                this.setState({ latitud: position.coords.latitude, longitud: position.coords.longitude });
            }
        });

        const res = await fetch(`${url_api}cultivos/`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const cultivos = await res.json();  
        if(!cultivos.detail){    
            this.setState({
                cultivos: cultivos, 
            }); 
        }
        
        const resr = await fetch(`${url_api}ranchos/`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const ranchos = await resr.json(); 
        
        if(!ranchos.detail){    
            let listrancho = ranchos.filter(rancho => rancho.user === this.state.productor_id);
            this.setState({
                listrancho: listrancho, 
            }); 
        } 


        const id_sector = this.state.id_sector;
        //console.log('idsector',id_sector)
        /*if(id_sector!==0){
            const cantsectores = this.state.sectoresdata;            

            let itemsectordata = cantsectores.find(sector => sector.id_sec === id_sector);
            //console.log('item',itemsectordata, 'cant', cantsectores )
            if(itemsectordata){
                if(itemsectordata.cultivo_id){
                    const resv = await fetch(`${url_api}cultivos/${itemsectordata.cultivo_id}`, {
                        headers: {
                            'Authorization': `Token ${this.props.authentication.token}`
                        },
                    });
                    const vari = await resv.json(); 
                    
                    if(!vari.detail){    
                        //let listrancho = ranchos.filter(rancho => rancho.user === this.state.productor_id);
                        const varires =vari.variedades;
                        
                        this.setState({
                            variedades: varires, 
                            statustem: true 
                        }); 
                    }
                }
                this.setState({
                    itemsectordata: itemsectordata, 
                    
                });
                //console.log(this.state.switch)
                if(this.state.switch === true){
                    this.setState({
                        switch:false, active: 3, step6: false, step4: true, rancho_id: this.state.rancho_id ,
                    });                    
                } 
            }else{
                Swal.fire({
                    title: '¿Desea Agregar otro Rancho?',
                    showDenyButton: true,
                    confirmButtonText: `Si`,
                    denyButtonText: `No, Salir`,                   
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                      this.clearRancho();
                    } else if (result.isDenied) {
                        window.location.replace('/home');
                    }
                })
            }  
        }*/


        const rest = await fetch(`${url_api}temporadas/`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const temporadas = await rest.json(); 
        if(!temporadas.detail){    
            this.setState({
                temporadas: temporadas                
            }); 
        } 
        if(this.state.infocultivo_id !== ''){
            const resic = await fetch(`${url_api}get_sectores/${this.state.infocultivo_id}`, {
                headers: {
                    'Authorization': `Token ${this.props.authentication.token}`
                },
            });
            const getcultivo = await resic.json(); 
            const infocult =getcultivo.sector; 
            const medio = infocult.medio;
            const cultivoid = infocult.cultivo !== undefined ? infocult.cultivo.id : ''; 
            if(medio!== undefined){
                const mediocultivo = medio.toUpperCase()
                this.setState({
                    medio: mediocultivo, 
                });
                //console.log(mediocultivo)
            }

            var vpg = this.state.infoRiego.volumen_por_gotero;
            var gpp = this.state.infoRiego.goteros_por_planta;
            var deg = this.state.infoRiego.distancia_entre_gotero;
            var ncpc = this.state.infoRiego.numero_cintas_por_cama;
            var ncpc = this.state.infoRiego.numero_cintas_por_cama;
            var tr  = this.state.infoRiego.tiempo_riego/60;
            var dec = infocult.distancia_entre_camas;
            const vpp = vpg*gpp*tr;
            var vpp2 = vpp.toFixed(6);    
            
            const div= 10000/dec/deg;
            
            const vph=div*ncpc*tr*vpg;
            var vph2 = vph.toFixed(2); 
            
            let {infoRiego, paramMedicion, humedad} = this.state;

            const rangos = await fetch(`${url_api}filter_rangos/?search=${cultivoid}`, {
                headers: {
                    'Authorization': `Token ${this.props.authentication.token}`
                },
            });
            const inforangos = await rangos.json();
            const ordenadosran=  inforangos.sort((a,b) => a.rango - b.rango)

            let rangohum = ordenadosran.filter(rango => parseInt(rango.rango) < parseInt(humedad) );
            //console.log('rangos',ordenadosran,'rangosb',rangohum)
            let lastItem = rangohum.pop();
            
            //console.log('last',lastItem)

            const volxmin0 =vph2/this.state.infoRiego.tiempo_riego;
            const volxmin = volxmin0.toFixed(2);
            const volagua1_1 = vph*this.state.infoRiego.numero_de_pulsos;
            var efectividad=lastItem!== undefined ? lastItem.efectividad : 0;
            const volagua1 =  parseFloat(volagua1_1.toFixed(2));
            const porcentaje = parseFloat(volagua1*efectividad/100);
            
            var signo =Math.sign(porcentaje);
            var volagua2_1 = (signo === 1) ? volagua1+porcentaje : volagua1+(porcentaje);
            //const volagua2_1 =  volagua1-porcentaje;
            const volagua2 = volagua2_1.toFixed(2);
            let txpul0 = (volagua2/this.state.infoRiego.numero_de_pulsos)/volxmin;
            let txpul = txpul0.toFixed(0);

            
            if (this.state.medio === 'HIDROPONIA'){
                //console.log('volagua',volagua2, 'volxmin',volxmin, 'pulsos',txpul0)
                infoRiego['tiempo_por_pulsos'] = txpul;
                infoRiego['volumen_por_planta'] = vpp2;
                infoRiego['distancia_entre_camas'] = dec;
                paramMedicion['suelo_humedad_rango_actual'] = humedad;
                paramMedicion['suelo_vol_por_min_ha'] = volxmin;
                paramMedicion['suelo_vol_agua_por_dia'] = volagua2;
                paramMedicion['recom_num_por_pulsos'] = infoRiego.numero_de_pulsos;
                paramMedicion['recom_tiempo_por_pulsos'] = txpul;
                paramMedicion['recom_intervalo_entre_pulsos'] = infoRiego.intervalo_entre_pulsos;
                this.setState({infoRiego});
                this.setState({paramMedicion});
            }
            infoRiego['volumen_por_ha'] = vph2;
            this.setState({infoRiego});
            //console.log('medio',this.state.medio)
            
            if(this.state.infoRiego.inicio_primer_pulso !== '' && this.state.infoRiego.intervalo_entre_pulsos!== '' && infoRiego.numero_de_pulsos!== ''){
                const inicio = this.state.infoRiego.inicio_primer_pulso;
                let txpul1 = parseInt(txpul);
                let minutos = parseInt(inicio.substr(3,2))+txpul1;
                let horas = parseInt(inicio.substr(0,2));
                const intp = parseInt(this.state.infoRiego.intervalo_entre_pulsos);
                const numpul = parseInt(this.state.infoRiego.numero_de_pulsos);
                
                for(var x=1; x<=numpul; x++){
                    if(minutos>=60){
                        const minutos2 = 0;
                        const minutos3 = minutos - 60;
                        const horas2 = horas+1;
                        if(horas2>=24){
                            const horas3=0;
                            const minutos4 = minutos2+minutos3;
                            var d = new Date(0,0,0,horas3, minutos4); // for now
                            const options = {hour: 'numeric', minute: 'numeric',};
                            const fin =new Intl.DateTimeFormat('es-MX', options).format(d)
                            infoRiego['inicio_primer_pulso'] = inicio;
                            infoRiego['termino_ultimo_pulso'] = fin;
                            this.setState({infoRiego});
                            //console.log(inicio, fin);
                            minutos= minutos4;
                            minutos += intp+txpul1;
                            horas = horas3;
                        }else{
                            const minutos4 = minutos2+minutos3;
                            var d = new Date(0,0,0,horas2, minutos4); // for now
                            const options = {hour: 'numeric', minute: 'numeric',};
                            const fin =new Intl.DateTimeFormat('es-MX', options).format(d)
                            infoRiego['inicio_primer_pulso'] = inicio;
                            infoRiego['termino_ultimo_pulso'] = fin;
                            this.setState({infoRiego});
                            //console.log(inicio, fin);
                            minutos= minutos4;
                            minutos += intp+txpul1;
                            horas = horas2;
                        }   
                    }else{
                        var d = new Date(0,0,0,horas, minutos); // for now
                        const options = {hour: 'numeric', minute: 'numeric',};
                        const fin =new Intl.DateTimeFormat('es-MX', options).format(d)
                        infoRiego['inicio_primer_pulso'] = inicio;
                        infoRiego['termino_ultimo_pulso'] = fin;
                        this.setState({infoRiego});
                        //console.log(inicio, fin);
                        minutos += intp+txpul1;
                    }
                } 
            }

            let dias = [];
            this.state.dias.map(item =>{
                if(item.status === true){
                    dias.push(item.id);
                    //newdias.join()
                    //console.log('dias',dias.join());
                    //return dias;
                    infoRiego['dias_aplicacion_fertirriego_por_semana'] = dias.join();
                    this.setState({
                        infoRiego
                    });
                }
            });
        }    
        
    }
    /**alta productor */
    setAltaProductor= async () => {

        
         /*this.setState({step1: false});
        this.setState({step2: true});
        this.setState({active: 2, productor_id: 35});
        this.componentDidMount();*/
       const validate = this.validateUserInformation();
        //console.log('validate',validate);
        if(validate) {
        
        
            const {user, directionDelivery, directionInvoicing} = this.state;
            user['direccion_facturacion'] = directionInvoicing;
            user['direccion_entrega'] = directionDelivery;
            console.log('user',user)

            const res = await fetch(`${url_api}users/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.authentication.token}`
                },
                body: JSON.stringify(user)
            }) 
            
            const data = await res.json();
            
            console.log('1',data);
                    if (data.id !== undefined) {
                    this.setState({productorResponse: data});
                    //directionDelivery['id_user'] = data.id;
                    //directionInvoicing['id_user'] = data.id;
                    localStorage.setItem('productor_id', data.id);
                    this.setState({productor_id: data.id});
                    this.componentDidMount();
                    
                    MySwal.fire({
                        icon: 'success',
                        title: 'Usuario Registrado Exitosamente',
                        showConfirmButton: false,
                        timer: 2000,
                    })
                        const active = 2;
                        this.setState({step1: false});
                        this.setState({step2: true});
                        this.setState({active: active});
                    } else {
                        
                        MySwal.fire({
                            icon: 'warning',
                            title: 'Algo Salio mal!!',
                            text: [data.email,'</br>', data.telefono ],
                            showConfirmButton: true
                          })
                    }         

        }
    }

    validateUserInformation() {
            let error = {};
            let {user} = this.state;
            let validUser = true;
            //first_names
            if (!user["first_name"]) {
                validUser = false;
                error["first_name"] = "Por favor, ingresa tus Nombres.";
                this.setState({error})
            }

            if (typeof user["first_name"] !== "undefined") {
                if (!user["first_name"].match(/^[a-zA-Z ]+$/)) {
                    validUser = false;
                    error["first_name"] = "Solo letras";
                    this.setState({error})
                }
            }

            // last_names
            if (!user["last_name"]) {
                validUser = false;
                error["last_name"] = "Por favor, ingresa tus Apellidos.";
                this.setState({error})
            }

            if (typeof user["last_name"] !== "undefined") {
                if (!user["last_name"].match(/^[a-zA-Z ]+$/)) {
                    validUser = false;
                    error["last_name"] = "Solo letras";
                    this.setState({error})
                }
            }

            // razonSocial
            if (!user["razonSocial"]) {
                validUser = false;
                error["razonSocial"] = "Por favor, ingresa razon social.";
                this.setState({error})
            }

            // rfc
            if (!user["rfc"]) {
                validUser = false;
                error["rfc"] = "Por favor, ingresa rfc.";
                this.setState({error})
            }

            // Email
            if (!user["email"]) {
                validUser = false;
                error["email"] = "Por favor, ingresa tu correo.";
                this.setState({error})
            }
            
            if (typeof user["email"] !== "undefined") {
                let posicionArroba = user["email"].lastIndexOf('@');
                let posicionPunto = user["email"].lastIndexOf('.');

                if (!(posicionArroba < posicionPunto && posicionArroba > 0 && user["email"].indexOf('@@') === -1 && posicionPunto > 2 && (user["email"].length - posicionPunto) > 2)) {
                    validUser = false;
                    error["email"] = "Por favor, ingresa un correo válido.";
                    this.setState({error})
                }
            }

            
            return validUser;
    }
    
    changeInputUser(e, field) {
        let user = this.state.user;
        user[field] = e.target.value;
        if (field === 'email') {
           user['username'] = e.target.value;
        }
        this.setState({
            user
        })
    }
    
    changeDirection(e, field, type) {
        let {directionInvoicing, directionDelivery, address} = this.state
        if (type === 'factura') {
            directionInvoicing[field] = e.target.value;
            this.setState({
                directionInvoicing
            })
        }
        if (type === 'entrega') {
            directionDelivery[field] = e.target.value;
            this.setState({
                directionDelivery
            })
        }

        if (type === 'cultivo') {
            address[field] = e.target.value;
            this.setState({
                address
            })
        }
    }
    handleImageMapa(event) {
        event.preventDefault();
        let ranchodata = this.state.ranchodata;
        let reader = new FileReader();
        let file = event.target.files[0];
        ranchodata['mapa'] = file;
        reader.onloadend = () => {
          this.setState({
            ranchodata,
            imagePreviewUrlMapa: reader.result
          });
        }
    
        reader.readAsDataURL(file)
    }
    handleImageIneA(event) {
        event.preventDefault();
        let ranchodata = this.state.ranchodata;
        let reader = new FileReader();
        let file = event.target.files[0];
        ranchodata['ine_anverso'] = file;
        reader.onloadend = () => {
          this.setState({
            ranchodata,
            imagePreviewUrlIneA: reader.result
          });
        }
    
        reader.readAsDataURL(file)
    }
    handleImageIneR(event) {
        event.preventDefault();
        let ranchodata = this.state.ranchodata;
        let reader = new FileReader();
        let file = event.target.files[0];
        ranchodata['ine_reverso'] = file;
        reader.onloadend = () => {
          this.setState({
            ranchodata,
            imagePreviewUrlIneR: reader.result
          });
        }
    
        reader.readAsDataURL(file)
    }
    clickImage(e) {
        console.log(e)
        if(e === '1'){
            document.querySelector('#foto1').click();
        }else if(e==='2'){
            document.querySelector('#foto2').click();
         }else if(e==='3'){
            document.querySelector('#mapa').click();
         }
    }
    
    /*alta rancho*/ 
    dataRancho =async(e) =>{
        e.preventDefault();
        const validate = this.validateRanchoInformation();
        if (validate) {
            this.setState({ step2: false, step3: true,})
            this.componentDidMount();
        } else {
                        
            MySwal.fire({
                icon: 'warning',
                title: 'Algo Salio mal!!',
                text: 'Favor de revisar la información',
                showConfirmButton: true
              })
        }      
        
    }
    submitRancho= async (e) =>{
        e.preventDefault();
                let form_data = new FormData();
                //form_data.append('mapa', this.state.file);
                form_data.append('nombre', this.state.ranchodata.nombre);
                form_data.append('empresa', this.state.ranchodata.empresa);
                form_data.append('rfc', this.state.ranchodata.rfc);
                form_data.append('superficie', this.state.ranchodata.superficie1);
                form_data.append('user', this.state.productor_id);
                form_data.append('nombre_encargado', this.state.ranchodata.nombre_encargado);
                form_data.append('telefono_encargado', this.state.ranchodata.telefono_encargado);
                form_data.append('ine_anverso', this.state.ranchodata.ine_anverso);
                form_data.append('ine_reverso', this.state.ranchodata.ine_reverso);
                form_data.append("geoposicion.latitud", this.state.latitud);
                form_data.append("geoposicion.longitud",this.state.longitud);
                
                axios.post(`${url_api}ranchos/`,  form_data , {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Token ${this.props.authentication.token}`
                    }
                }).then(res => {
                    console.log('res',res);
                    
                    if(res.data.id){
                        MySwal.fire({
                            icon: 'success',
                            title: 'Rancho Registrado Exitosamente',
                            showConfirmButton: false,
                            timer: 2000,
                        })
                        this.setState({ active: 3,step3: false, step4: true, rancho_id: res.data.id, id_sector:1 })
                        this.componentDidMount();
                    } 
                }).catch(
                   function (error) {
                       console.log('err',error.response);
                       MySwal.fire({
                            icon: 'warning',
                            title: 'Algo Salio mal!!',
                            text: 'Favor de revisar la información',
                            showConfirmButton: true
                          })
                    }
                )
                
        /*this.setState({active: 3, step3: false, step4: true, rancho_id: 27 , id_sector:1 })
        this.componentDidMount();*/
    }

    validateRanchoInformation() {
        let error = {};
        let {ranchodata,sectoresdata, cant} = this.state;
        let validRancho = true;
        
        if (!ranchodata["nombre"]) {
            validRancho = false;
            error["nombre"] = "Por favor, ingresa el Nombre.";
            this.setState({error})
        }
        if (!ranchodata["rfc"]) {
            validRancho = false;
            error["rfc"] = "Por favor, ingresa el RFC.";
            this.setState({error})
        }
        if (!ranchodata["superficie1"]) {
            validRancho = false;
            error["superficie1"] = "Por favor, ingresa la Superficie.";
            this.setState({error})

        }
        if (!ranchodata["empresa"]) {
            validRancho = false;
            error["empresa"] = "Por favor, ingresa la Empresa.";
            this.setState({error})
        }
        

        if (!ranchodata["nombre_encargado"]) {
            validRancho = false;
            error["nombre_encargado"] = "Por favor, ingresa el nombre del encarcado del cultivo.";
            this.setState({error})
        }

        if (!ranchodata["telefono_encargado"]) {
            validRancho = false;
            error["telefono_encargado"] = "Por favor, ingresa el telefono del encarcado del cultivo.";
            this.setState({error})
        }

        /*sectoresdata.map((x) => { 
            if (!x["superficie"]) {
                validRancho = false;
                error["superficie"] = "Por favor, ingresa la Superficie.";
                this.setState({error})

            }
            if (!x["cultivo_id"]) {
                validRancho = false;
                error["cultivo_id"] = "Por favor, selecciona el Cultivo.";
                this.setState({error})
            }
        })
        if (cant === 0) {
            validRancho = false;
            error["cant"] = "Por favor, el valor debe ser mayor a 0.";
            this.setState({error})
        }*/
        return validRancho;
    }
    
    changeRancho = (e) => {
        const { name, value } = e.target;
        let {ranchodata} = this.state
        //const list = [...this.state.ranchodata];
        ranchodata[name] = value;
        this.setState({ranchodata});
    };
    

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        
        if(val){
            
            const cant = parseFloat(val);
            this.setState({[nam]: cant });
            if(cant !== 0) {
                
                if(cant>this.state.cant2){
                    const total = cant-this.state.cant2;
                    
                    if(this.state.cant2===0){                          
                        for(var x=0; x<total; x++){
                            
                            const idres =x+1;
                            this.setState((prevState) => ({
                                sectoresdata: [...prevState.sectoresdata, {
                                    superficie: "",
                                    cultivo_id:"",
                                    id_sec: idres,
                                    status:false
                                }], 
                            })); 
                        } 
                        this.setState({cant2:cant });
                    }else{

                        const ids = this.state.sectoresdata[this.state.sectoresdata.length - 1].id_sec;
                        console.log('hi',ids)

                        for(var x=ids; x<total+this.state.cant2; x++){
                            
                            const idres =x+1;
                            this.setState((prevState) => ({
                                sectoresdata: [...prevState.sectoresdata, {
                                    superficie: "",
                                    cultivo_id:"",
                                    id_sec: idres,
                                    status:false
                                }], 
                            })); 
                        } 
                        this.setState({cant2:cant });
                    }
                }else{
                    const total = this.state.cant2-cant;
                    const list = [...this.state.sectoresdata];
                    const ultsec = list.length;
                    const canti = parseFloat(ultsec);           
                    list.splice(cant);
                    this.setState({sectoresdata: list});
                    this.setState({cant2:cant});
                }
                let error = {};
                error["cant"] = "";
                this.setState({error})
            }else{
                let error = {};
                error["cant"] = "Por favor, el valor debe ser mayor a 0.";
                this.setState({error})
                
            }
        }else{
            this.setState({[nam]: val });
        }       
    }   

    // handle click event of the Add button
    handleAddsectores = () => {
        
        const ids = this.state.sectoresdata[this.state.sectoresdata.length - 1].id_sec;
        console.log('hi',ids)
        const idres =ids+1;
        this.setState((prevState) => ({
            sectoresdata: [...prevState.sectoresdata, {
                superficie: "",
                cultivo_id:"",
                id_sec: idres,
                status:false
            }], 
        })); 
    };
    
    changeSectores = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.sectoresdata];
        list[index][name] = value;
        this.setState({sectoresdata: list});
    };

    sectorStep = async (id) =>{
        const res = this.state.sectoresdata.map(item =>{
            if(item.id_sec === id){
                return {...item, status:true};
            }else{
                return {...item, status:false};
            }
        });
        this.setState({sectoresdata: res});
    };

    /**alta geoposicion racho */
    setLocation= async () => {
      
            let form_data = new FormData();
            form_data.append('latitud', this.state.latitud);
            form_data.append('longitud', this.state.longitud);
            form_data.append('racho', this.state.rancho_id);
            return (
                axios.post(`${url_api}geoposicion/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Token ${this.props.authentication.token}`
                    }
                }).then(res => {
                    console.log('res',res);
                    this.setState({ step3: false, step4: true ,title: 'Información del Cultivo'})
                }).catch(
                   function (error) {
                       console.log('err',error.response);
                       
                   }
                 )
                
            );                                    
        /*if(this.state.latitud !== '' || this.state.longitud !== ''){ 
            this.setState({ step3: false, step4: true }) 
        }*/
    }
    
    /**alta informacion cultivo */
    saveCultivo= async () => {
        const validate = this.validateInfoCultivoInformation();
        if (validate) {
            const geoposicion ={};
            geoposicion['latitud']= this.state.latitud;
            geoposicion['longitud']= this.state.longitud;
            let {infoCultivo, productor_id, rancho_id} = this.state;
            infoCultivo['id_user']  = productor_id; 
            infoCultivo['rancho']  = rancho_id;
            infoCultivo['geoposicion']  = geoposicion;
            console.log(infoCultivo)
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.authentication.token}`
                },
                body: JSON.stringify(infoCultivo)
            }

            const res = await fetch(`${url_api}sectores/`, requestOptions)
        
            
            
            const data = await res.json();
            //console.log(data)
            if(data.id){
                MySwal.fire({
                    icon: 'success',
                    title: ['Registrado Exitosamente'],
                    showConfirmButton: false,
                    timer: 2000,
                })
                this.setState({ step4: false, step5: true, infocultivo_id: data.id, title:'Sistema de Riego' })
                this.componentDidMount();
            }else {
                        
                MySwal.fire({
                    icon: 'warning',
                    title: 'Algo Salio mal!!',
                    text: 'Favor de revisar la información',
                    showConfirmButton: true
                  })
            } 
        
        }
        /*this.setState({active: 4, step4: false, step5: true, infocultivo_id: 6 })
        this.componentDidMount();*/
    }

    validateInfoCultivoInformation() {
        let error = {};
        let {infoCultivo} = this.state;
        let validCultivo = true;
        if (!infoCultivo["temporada_id"]) {
            validCultivo = false;
            error["temporada_id"] = "Por favor, Seleciona una temporada.";
            this.setState({error})
        }
        if (!infoCultivo["cultivo_id"]) {
            validCultivo = false;
            error["cultivo_id"] = "Por favor, Seleciona un cultivo.";
            this.setState({error})
        }
        if (!infoCultivo["superficie"]) {
            validCultivo = false;
            error["superficie"] = "Por favor, Ingresa la supérficie.";
            this.setState({error})
        }
        if (!infoCultivo["medio"]) {
            validCultivo = false;
            error["medio"] = "Por favor, Selecciona un medio.";
            this.setState({error})
        }
        if (!infoCultivo["fecha_plantacion"]) {
            validCultivo = false;
            error["fecha_plantacion"] = "Por favor, ingresa la fecha de plantación.";
            this.setState({error})

        }
        if (!infoCultivo["fecha_poda"]) {
            validCultivo = false;
            error["fecha_poda"] = "Por favor, ingresa la fecha de poda.";
            this.setState({error})
        }
        if (!infoCultivo["lineas_planta_cama"]) {
            validCultivo = false;
            error["lineas_planta_cama"] = "Por favor, ingresa las lineas planta por cama.";
            this.setState({error})
        }
        if (!infoCultivo["distancia_entre_plantas"]) {
            validCultivo = false;
            error["distancia_entre_plantas"] = "Por favor, ingresa la distancia entre plantas.";
            this.setState({error})
        }
        if (!infoCultivo["distancia_entre_camas"]) {
            validCultivo = false;
            error["distancia_entre_camas"] = "Por favor, ingresa la distancia entre camas.";
            this.setState({error})
        }
        if (!infoCultivo["largo_cama"]) {
            validCultivo = false;
            error["largo_cama"] = "Por favor, ingresa el largo de camas.";
            this.setState({error})
        }
        
        /*if (!infoCultivo["sector"]) {
            validCultivo = false;
            error["sector"] = "Por favor, Selecciona un sector.";
            this.setState({error})
        }*/
        if (!infoCultivo["variedad_id"]) {
            validCultivo = false;
            error["variedad_id"] = "Por favor, Selecciona una variedad.";
            this.setState({error})
        }
        if (!infoCultivo["distancia_por_hectarea"]) {
            validCultivo = false;
            error["distancia_por_hectarea"] = "Por favor, ingresa la distancia por hectarea.";
            this.setState({error})
        }
        if (!infoCultivo["plantas_ha"]) {
            validCultivo = false;
            error["plantas_ha"] = "Por favor, ingresa las plantas por hectarea.";
            this.setState({error})
        }
        return validCultivo;
    }

    changeCultivo(e, field) {
        let { infoCultivo } = this.state;
        infoCultivo[field] = e.target.value;
        this.setState({
            infoCultivo
        })
    }

    setTemporada(id) {

        let { infoCultivo } = this.state;
        infoCultivo['temporada_id'] = id;
        this.setState({ infoCultivo });

    }
    
    setSector(id) {

        let { infoCultivo } = this.state;
        infoCultivo['sector'] = id;
        this.setState({ infoCultivo });

    }

    setCultivo = async (id) => {
        let { infoCultivo } = this.state;
        infoCultivo['cultivo_id'] = id;
        this.setState({ infoCultivo });

        //const vari = this.state.infoCultivo.cultivo;
        
        const resv = await fetch(`${url_api}cultivos/${id}`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const vari = await resv.json(); 
        
        if(!vari.detail){    
            //let listrancho = ranchos.filter(rancho => rancho.user === this.state.productor_id);
            const varires =vari.variedades;
            this.setState({
                variedades: varires, 
                statustem: true 
            }); 
        }
    }

    setVariedades(id) {
        let { infoCultivo } = this.state;
        infoCultivo['variedad_id'] = id;
        this.setState({ infoCultivo });
    }

    setMedio(id) {
        let { infoCultivo } = this.state;
        infoCultivo['medio'] = id;
        this.setState({ infoCultivo });
    }
     
    /*alta inforiego*/
    changeRiego(e, field) {
        let {infoRiego} = this.state;
        infoRiego[field] = e.target.value;
        this.setState({
            infoRiego
        });
        this.componentDidMount();
    }
    
    dataRiego= async () => {
        
        const validate = this.validateInfoRiegoInformation();
        if (validate) {
            this.setState({active:5, step5: false, step6: true })
            this.componentDidMount();
        }else {
                        
            MySwal.fire({
                icon: 'warning',
                title: 'Algo Salio mal!!',
                text: 'Favor de revisar la información',
                showConfirmButton: true
              })
        } 
    }

    validateInfoRiegoInformation() {
        let error = {};
        let {infoRiego} = this.state;
        let validRiego = true;
        if (!infoRiego["dias_aplicacion_fertirriego_por_semana"]) {
            validRiego = false;
            error["dias_aplicacion_fertirriego_por_semana"] = "Por favor, Seleciona los dias de aplicación.";
            this.setState({error})
        }
        if (!infoRiego["tipo_agua"]) {
            validRiego = false;
            error["tipo_agua"] = "Por favor, Selecciona el tipo agua.";
            this.setState({error})
        }
        if (!infoRiego["tipo_sistema_riego"]) {
            validRiego = false;
            error["tipo_sistema_riego"] = "Por favor, Selecciona el tipo sistema riego.";
            this.setState({error})
        }
        if (!infoRiego["tipo_regante"]) {
            validRiego = false;
            error["tipo_regante"] = "Por favor, Selecciona el tipo regante.";
            this.setState({error})
        }
        if (!infoRiego["volumen_por_gotero"]) {
            validRiego = false;
            error["volumen_por_gotero"] = "Por favor, Ingresa el volumen por gotero.";
            this.setState({error})
        }
        if (!infoRiego["distancia_entre_gotero"]) {
            validRiego = false;
            error["distancia_entre_gotero"] = "Por favor, Ingresa la distancia entre goteros.";
            this.setState({error})
        }
        if (!infoRiego["numero_cintas_por_cama"]) {
            validRiego = false;
            error["numero_cintas_por_cama"] = "Por favor, Ingresa el numero cintas por cama.";
            this.setState({error})
        }
        if (!infoRiego["tiempo_riego"]) {
            validRiego = false;
            error["tiempo_riego"] = "Por favor, Ingresa el tiempo riego.";
            this.setState({error})
        }

        if (this.state.medio === 'HIDROPONIA'){

            if (!infoRiego["goteros_por_planta"]) {
                validRiego = false;
                error["goteros_por_planta"] = "Por favor, Ingresa la cantidad de goteros por planta.";
                this.setState({error})
            }
        
            if (!infoRiego["numero_de_pulsos"]) {
                validRiego = false;
                error["numero_de_pulsos"] = "Por favor, Ingresa el numero de pulsos.";
                this.setState({error})
            }
            if (!infoRiego["intervalo_entre_pulsos"]) {
                validRiego = false;
                error["intervalo_entre_pulsos"] = "Por favor, Ingresa el intervalo entre pulsos.";
                this.setState({error})
            }
            if (!infoRiego["inicio_primer_pulso"]) {
                validRiego = false;
                error["inicio_primer_pulso"] = "Por favor, Ingresa el inicio primer pulso.";
                this.setState({error})
            }
        }
        return validRiego;
    }

    setDias(id) {

        const res = this.state.dias.map(item =>{
            if(item.id === id){
                if(item.status === false){
                   return {...item, status:true};
                }else{
                   return {...item, status:false};
                }
            }else{
                return {...item};
            }
        });
        this.setState({dias: res});
        this.componentDidMount();
    }

    setTipoRegante(id) {
        //console.log(id);
        let { infoRiego } = this.state;
        infoRiego['tipo_regante'] = id;
        this.setState({ infoRiego });
    }

    handleChangevolgot= (value) => {
        let {infoRiego} = this.state;
        infoRiego['volumen_por_gotero'] = value;
        this.setState({
            infoRiego
        })
    };

    saveMonitoreo= async (e) => {
        e.preventDefault();
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.props.authentication.token}`
            },
            body: JSON.stringify({
                sector: this.state.infocultivo_id,
                sistema_de_riego: this.state.infoRiego,
                parametros_de_medicion: this.state.paramMedicion,
            })
        }

        const res = await fetch(`${url_api}monitoreo_riego_medicion/`, requestOptions)       
        const data = await res.json();
        console.log(data)
        if(data.id){
            MySwal.fire({
                icon: 'success',
                title: ['Monitoreo se Registrado Exitosamente'],
                showConfirmButton: false,
                timer: 2000,
            })
                    
            //const sector=this.state.id_sector+1;
            this.setState({step7: true, step6:false, switch:true,
                infoCultivo: {
                    temporada_id: '',
                    variedad_id: '',
                    cultivo_id: '',
                    superficie: '',
                    medio: '',
                    fecha_plantacion: '',
                    fecha_poda: '',
                    lineas_planta_cama:'',
                    distancia_entre_plantas:'',
                    distancia_entre_camas:'',
                    largo_cama:'',
                    distancia_por_hectarea:'',
                    plantas_ha:'',
                },
                infoRiego: {
                    dias_aplicacion_fertirriego_por_semana: '',
                    tipo_agua: '',
                    tipo_sistema_riego: '',
                    tipo_regante: '',
                    volumen_por_gotero: 0,
                    distancia_entre_gotero: '',
                    goteros_por_planta: 0,
                    numero_cintas_por_cama: '',
                    tiempo_riego: '',
                    volumen_por_planta: 0,
                    numero_de_pulsos: 0,
                    tiempo_por_pulsos: 0,
                    intervalo_entre_pulsos: 0,
                    volumen_por_ha: 0,
                    inicio_primer_pulso: '00:00',
                    termino_ultimo_pulso: '00:00',
                    distancia_entre_camas: 0

                },
                dias: [
                    {id: 1,
                    status: false},
                    {id: 2,
                    status: false},
                    {id: 3,
                    status: false},
                    {id: 4,
                    status: false},
                    {id: 5,
                    status: false},
                    {id: 6,
                    status: false},
                    {id: 7,
                    status: false},
                ],
                paramMedicion:{
                    suelo_ph: null,
                    suelo_conductividad: null,
                    suelo_humedad_rango_actual: null,
                    suelo_vol_por_min_ha: null,
                    suelo_vol_agua_por_dia: null,
                    sustrato_vol: null,
                    sustrato_ph: null,
                    sustrato_ce: null,
                    recom_num_por_pulsos: null,
                    recom_tiempo_por_pulsos: null,
                    recom_intervalo_entre_pulsos: null,
                    exp_sustrato_vol: null,
                    exp_sustrato_ph: null,
                    exp_sustrato_ce: null,
                    drenaje_vol: null,
                    drenaje_ph: null,
                    drenaje_ce: null
                },
                humedad:'',
                infocultivo_id: '',
                medio:''
            })
            this.componentDidMount();
        }else {
                        
            MySwal.fire({
                icon: 'warning',
                title: 'Algo Salio mal!!',
                text: 'Favor de revisar la información',
                showConfirmButton: true
              })
        } 
        
    }

    setTabs = (id) => {
        console.log(id)
        if(id === 1){
            this.setState({
                substep1: true,
                substep2: false,
                substep3: false
            })
        }
        if(id === 2){
            this.setState({
                substep1: false,
                substep2: true,
                substep3: false
            })
        }
        if(id === 3){
            this.setState({
                substep1: false,
                substep2: false,
                substep3: true
            })
        }

    };
    changeParametros(e, field) {
        let { paramMedicion} = this.state;
        paramMedicion[field] = e.target.value;
        this.setState({
            paramMedicion
        })
        //console.log(parametrosmed)
    }
    changeRango(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
        this.componentDidMount();
    }
    
    clearData(id){

        if(id === 1){
        this.setState({
            step2: true, step7:false,
            ranchodata:{
                nombre: "", 
                empresa: "",
                rfc: "",
                superficie1: "",
                user: "",
                nombre_encargado: "",
                telefono_encargado: "",
                mapa: "",
                ine_anverso: "",
                ine_reverso: "",
                
            },

            geoposicion:{
                latitud: '',
                longitud: '',
            },
            imagePreviewUrlMapa: "",
            imagePreviewUrlIneA: "",
            imagePreviewUrlIneR: "",

        })
        }else if(id === 2){
            const sector = this.state.id_sector+1;
            this.setState({
                step4: true, step7:false, id_sector:sector
            })
        } else if (id === 3) {
            window.location.replace('/home');
        }
        this.setState({            
            infocultivo_id:'',
            medio: '',
            infoCultivo: {
                temporada_id: '',
                variedad_id: '',
                cultivo_id: '',
                superficie:'',
                medio: '',
                fecha_plantacion: '',
                fecha_poda: '',
                lineas_planta_cama:'',
                distancia_entre_plantas:'',
                distancia_entre_camas:'',
                largo_cama:'',
                distancia_por_hectarea:'',
                plantas_ha:'',
            },
            infoRiego: {
                dias_aplicacion_fertirriego_por_semana: '',
                tipo_agua: '',
                tipo_sistema_riego: '',
                tipo_regante: '',
                volumen_por_gotero: 0,
                distancia_entre_gotero: '',
                goteros_por_planta: 0,
                numero_cintas_por_cama: '',
                tiempo_riego: '',
                volumen_por_planta: 0,
                numero_de_pulsos: 0,
                tiempo_por_pulsos: 0,
                intervalo_entre_pulsos: 0,
                volumen_por_ha: 0,
                inicio_primer_pulso: '00:00',
                termino_ultimo_pulso: '00:00',
                distancia_entre_camas: 0

            },
            dias: [
                {id: 1,
                status: false},
                {id: 2,
                status: false},
                {id: 3,
                status: false},
                {id: 4,
                status: false},
                {id: 5,
                status: false},
                {id: 6,
                status: false},
                {id: 7,
                status: false},
            ],
            paramMedicion:{
                suelo_ph: null,
                suelo_conductividad: null,
                suelo_humedad_rango_actual: null,
                suelo_vol_por_min_ha: null,
                suelo_vol_agua_por_dia: null,
                sustrato_vol: null,
                sustrato_ph: null,
                sustrato_ce: null,
                recom_num_por_pulsos: null,
                recom_tiempo_por_pulsos: null,
                recom_intervalo_entre_pulsos: null,
                exp_sustrato_vol: null,
                exp_sustrato_ph: null,
                exp_sustrato_ce: null,
                drenaje_vol: null,
                drenaje_ph: null,
                drenaje_ce: null
            },
            humedad:'',

        })

        this.componentDidMount();
    }

    handleFileAnFi = (event) => {
        event.preventDefault();

        const { name, id } = event.target;
        var newid= parseInt(id)+1;
        const cant = this.state.analisisfisi.length;
        console.log('cant', cant, newid);
        /*if(cant < newid){
            this.setState((prevState) => ({
                analisisfisi: [...prevState.analisisfisi, {
                    agua: '',
                    fibra: '',
                    suelo: '',
                    fertibilidad:'',
                    sector:'' 
                }],
            }));
        }*/

        let reader = new FileReader();
        let file = event.target.files[0];
        const list = [...this.state.analisisfisi];
        list[id][name] = file;

        reader.onloadend = () => {
            this.setState({inputList: list});
        }
        console.log({inputList: list})
        reader.readAsDataURL(file)
    };
    saveInfoSectorFisi= async (e) => {
        e.preventDefault();
        console.log('err1');
        this.state.analisisfisi.map((x) => {
            if(x.agua!== '' || x.fibra !== '' || x.suelo !== '' || x.fertibilidad!==''){
                let form_data = new FormData();
                form_data.append('agua', x.agua);
                form_data.append('fibra', x.fibra);
                form_data.append('suelo', x.suelo);
                form_data.append('fertibilidad', x.fertibilidad);
                form_data.append('sector', this.state.id_sector);
                return (
                    axios.post(`${url_api}analisis_fisicoquimicos/`, form_data, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': `Token ${this.props.token}`
                        }
                    }).then(res => {
                        console.log('res',res);
                        this.componentDidMount();
                    }).catch(
                    function (error) {
                        console.log('err',error);
                        
                    }
                    )              
                );                                              
            }
        })
    }
    render() {
        let {imagePreviewUrlMapa,imagePreviewUrlIneA, imagePreviewUrlIneR,analisisfisi} = this.state;
        let $imagePreviewIneA = null;
        let $imagePreviewIneR = null;
        let $imagePreviewMapa = null;
        //console.log('cultivos',this.state.infoCultivo)
        if (imagePreviewUrlIneA) {
            $imagePreviewIneA = (<img alt="mapa" src={imagePreviewUrlIneA} />);
        } else {
            $imagePreviewIneA = (<div className="previewText">Selecciona un foto del Ine Anverso:</div>);
        }
        if (imagePreviewUrlIneR) {
            $imagePreviewIneR = (<img src={imagePreviewUrlIneR} />);
        } else {
            $imagePreviewIneR = (<div className="previewText">Selecciona una foto del Ine Reverso :</div>);
        }
        if (imagePreviewUrlMapa) {
            $imagePreviewMapa = ( <iframe width='100%' height='100%' src={imagePreviewUrlMapa}></iframe>);
           // $imagePreviewMapa = (<embed src={imagePreviewUrlMapa} type="application/pdf" width="100%" height="600px"/>);
        } else {
            $imagePreviewMapa = (<div className="previewText">Selecciona un pdf del mapa :</div>);
        }

        let $iconpdf = (<img className="iconpdf" src={iconpdf} />);        
        const data1 =this.state.analisisfisi[0]; 
        const data2 =this.state.analisisfisi[1]; 
        const data3 =this.state.analisisfisi[2]; 
        //console.log(this.state.infoRiego, this.state.paramMedicion)
        //const { volgot} = this.state
       
        return (
            <>
                <LayoutTwo title={this.state.title} progress="true" active={this.state.active} classText="alta-prod">
                    {this.state.step1 &&
                        <div className="container-fluid mx-auto">
                            <form>
                                <div className="row">
                                    <div className="col s11 m3 l3 offset-l1 offset-m1">
                                        <div className="flex justify-center">
                                            <div className="box-1">
                                                <h3 className="font-bold text-five title-2">Información de contacto</h3>
                                            </div>
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="card-productor">
                                                <div className="box-green">
                                                    <img src={infoprod} className="human1"/>
                                                    <div className="box-space-35">
                                                        <p className="text-six font-bold title-6">Nombre y Apellido</p>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <div className="input-field">
                                                            <input type="text"
                                                                className="input-new validate"
                                                                placeholder="José Alberto"
                                                                name="first_name"
                                                                onChange={ (e) => this.changeInputUser(e, "first_name")}
                                                                value={this.state.user["first_name"] || ''}
                                                                required/>
                                                            <span
                                                                className="helper-text">{this.state.error["first_name"]}</span>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="text"
                                                                className="input-new validate"
                                                                placeholder="Guerra Lopéz"
                                                                name="last_name"
                                                                onChange={ (e) => this.changeInputUser(e, "last_name")}
                                                                value={this.state.user["last_name"] || ''}
                                                                required/>
                                                            <span
                                                                className="helper-text">{this.state.error["last_name"]}</span>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="password"
                                                                name="password"
                                                                id="password"
                                                                className="input-new validate"
                                                                onChange={ (e) => this.changeInputUser(e, "password")}
                                                                value={this.state.user["password"] || ''}/>
                                                            <label htmlFor="password" className="text-white">Contraseña</label>
                                                            <span className="helper-text white-text">
                                                                Es para que el productor inicie sesión en su app (próximamente).
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col s12 m7 l7">
                                        <div className="form-layout">
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Razón Social</span>

                                                <div className="input-field">
                                                    <input type="text"
                                                        className="input-6 validate mayus"
                                                        placeholder="Ej. MEXICO TECHNOLOGY & SOFTWARE S.A. DE C.V."
                                                        onChange={ (e) => this.changeInputUser(e, "razonSocial")}
                                                        value={this.state.user["razonSocial"] || ''}
                                                        required/>
                                                    <span className="helper-text">{this.state.error["razonSocial"]}</span>
                                                </div>
                                            </div>

                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">RFC</span>

                                                <div className="input-field">
                                                    <input type="text"
                                                        className="input-6 validate mayus"
                                                        placeholder="Ej. EJSV121024TN8"
                                                        onChange={ (e) => this.changeInputUser(e, "rfc")}
                                                        value={this.state.user["rfc"] || ''}
                                                        maxLength="13"
                                                        minLength="12"
                                                    />
                                                    <span className="helper-text">{this.state.error["rfc"]}</span>
                                                </div>

                                            </div>

                                            <div className="box-space">
                                            <span
                                                className="font-bold text-primary title-3">Dirección de la facturación</span>
                                                <div className="row">
                                                    <div className="col s12 m6 l6">
                                                        <div className="input-field">
                                                            <input id="calle1"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'calle', 'factura')}
                                                                value={this.state.directionInvoicing["calle"] || ''}/>
                                                            <label htmlFor="calle1">Calle: </label>
                                                            <span className="helper-text">{this.state.error["calle"]}</span>
                                                        </div>

                                                        <div className="input-field">
                                                            <input id="colonia1"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'colonia', 'factura')}
                                                                value={this.state.directionInvoicing["colonia"] || ''}/>
                                                            <label htmlFor="colonia1">Colonia : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["colonia"]}</span>
                                                        </div>

                                                        <div className="input-field">
                                                            <input id="municipio1"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'municipio', 'factura')}
                                                                value={this.state.directionInvoicing["municipio"] || ''}/>
                                                            <label htmlFor="municipio1">Municipio : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["municipio"]}</span>
                                                        </div>

                                                        
                                                    </div>

                                                    <div className="col s12 m6 l6">

                                                        <div className="input-field">
                                                            <input id="numero1"
                                                                className="input-4 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'numero', 'factura')}
                                                                value={this.state.directionInvoicing["numero"] || ''}/>
                                                            <label htmlFor="numero1">Numero : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["numero"]}</span>
                                                        </div>

                                                        <div className="input-field">
                                                                <label htmlFor="estado1" className="active">Estado</label>
                                                                <select id="estado1" value={this.state.directionInvoicing["estado"] || ''}  onChange={ (e) => this.changeDirection(e, 'estado', 'factura')} className="input-3 validate" 
                                                                title="Favor de Seleccionar un Estado de la Lista.">
                                                                    <option value="">Selecciona un Estado</option>
                                                                    {this.state.estados.map((estado,i)=> {
                                                                        return (
                                                                        <option  key={i}  value={estado.nombre}>{estado.nombre}</option>
                                                                        );
                                                                    })}
                                                                </select>    
                                                                
                                                                <span
                                                                className="helper-text">{this.state.error["estado"]}</span>                                            
                                                        </div>
                                                        <div className="input-field">
                                                            <input id="cp1"
                                                                className="input-3 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'cp', 'factura')}
                                                                value={this.state.directionInvoicing["cp"] || ''}/>
                                                            <label htmlFor="cp1">CP : </label>
                                                            <span className="helper-text">{this.state.error["cp"]}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Datos de entrega</span>
                                                <div className="row">
                                                    <div className="col s12 m6 l6">

                                                        <div className="input-field">
                                                            <input id="calle2"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'calle', 'entrega')}
                                                                value={this.state.directionDelivery["calle"] || ''}/>
                                                            <label htmlFor="calle2">Calle: </label>
                                                            <span className="helper-text">{this.state.error["calle"]}</span>
                                                        </div>

                                                        <div className="input-field">
                                                            <input id="colonia2"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'colonia', 'entrega')}
                                                                value={this.state.directionDelivery["colonia"] || ''}/>
                                                            <label htmlFor="colonia2">Colonia : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["colonia"]}</span>
                                                        </div>

                                                        <div className="input-field">
                                                            <input id="municipio2"
                                                                className="input-2 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'municipio', 'entrega')}
                                                                value={this.state.directionDelivery["municipio"] || ''}/>
                                                            <label htmlFor="municipio2">Municipio : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["municipio"]}</span>
                                                        </div>
                                                        

                                                    </div>

                                                    <div className="col s12 m6 l6">
                                                        <div className="input-field">
                                                            <input id="numero2"
                                                                className="input-4 validate"
                                                                type="text"
                                                                max="10"
                                                                onChange={ (e) => this.changeDirection(e, 'numero', 'entrega')}
                                                                value={this.state.directionDelivery["numero"] || ''}/>
                                                            <label htmlFor="numero2">Numero : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["numero"]}</span>
                                                        </div>
                                                        <div className="input-field">
                                                                <label htmlFor="estado1" className="active">Estado</label>
                                                                <select id="estado1" value={this.state.directionDelivery["estado"] || ''}  onChange={ (e) => this.changeDirection(e, 'estado', 'entrega')} className="input-3 validate" 
                                                                title="Favor de Seleccionar un Estado de la Lista.">
                                                                    <option value="">Selecciona un Estado</option>
                                                                    {this.state.estados.map((estado,i)=> {
                                                                        return (
                                                                        <option  key={i}  value={estado.nombre}>{estado.nombre}</option>
                                                                        );
                                                                    })}
                                                                </select>    
                                                                
                                                                <span
                                                                className="helper-text">{this.state.error["estado"]}</span>                                            
                                                        </div>
                                                        <div className="input-field">
                                                            <input id="cp2"
                                                                className="input-3 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeDirection(e, 'cp', 'entrega')}
                                                                value={this.state.directionDelivery["cp"] || ''}/>
                                                            <label htmlFor="cp2">CP : </label>
                                                            <span className="helper-text">{this.state.error["cp"]}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 m6 l6">
                                                        <div className="input-field">
                                                            <input id="correo"
                                                                className="input-2 validate"
                                                                type="email"
                                                                onChange={ (e) => this.changeInputUser(e, "email")}
                                                                value={this.state.user["email"] || ''}/>
                                                            <label htmlFor="correo">Correo : </label>
                                                            <span className="helper-text">{this.state.error["email"]}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col s12 m6 l6">
                                                        <div className="input-field">
                                                            <input id="telfo"
                                                                className="input-3 validate"
                                                                type="text"
                                                                onChange={ (e) => this.changeInputUser(e, "telefono")}
                                                                value={this.state.user["telefono"] || ''}/>
                                                            <label htmlFor="telfo">Teléfono : </label>
                                                            <span
                                                                className="helper-text">{this.state.error["telefono"]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-space">
                                                
                                                <div className="col s12 m12 l12">
                                                    <div className="row">
                                                        <div className="col s6 m6 l6">
                                                            <span
                                                                className="font-bold text-primary title-3">Factor</span>
                                                            <div className="input-field">
                                                                <input name="campo" type="number" className="validate " step="10" min="60" max="100"
                                                                    onChange={ (e) => this.changeInputUser(e, "factor")}
                                                                    value={this.state.user["factor"] || ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <button className="bg-transparent float-right" onClick={(e) => this.setAltaProductor(e)} type="button">
                                    <div className="">
                                        <img src={next1} className="next-1"/>
                                    </div>
                                </button>
                            </form>
                        </div>
                    }

                    {this.state.step2 &&
                        <div className="container-fluid mx-auto">
                            <form>
                                <div className="row">
                                    <div className="col s11 m3 l3 offset-l1 offset-m1">
                                        <div className="flex justify-center">
                                            <div className="box-1">
                                                <h3 className="font-bold text-five title-2">Información de contacto</h3>
                                            </div>
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="card-productor">
                                                <div className="box-green">
                                                    <img src={infoprod} className="human1"/>
                                                    <div>
                                                        <p className="text-six font-bold title-6">ID Productor</p>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <div className="white-text text-center">
                                                            <p className="text-bold">{this.state.productor_id}</p>                                                               
                                                        </div>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <p className="text-six font-bold title-6">Nombre</p>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <div className="white-text text-center">
                                                            <p className="text-bold">{this.state.user.first_name} {this.state.user.last_name}</p>                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="flex justify-center">
                                            <div className="box-1 text-center">
                                                <button title="Agrega Varias Temporadas del Cultivo" className="inline-block py-4 px-4 text-center rounded-full text-white transition bg-yellow-400  shadow ripple hover:shadow-lg hover:bg-yellow-500 focus:outline-none"  onClick={(e) => this.submitRancho(e)} >Guardar Rancho</button>
                                                
                                            </div>
                                        </div>*/}
                                        <div className="block px-20 text-center">
                                            <span className="font-bold m-4 text-primary title-3">Ranchos</span>
                                            {this.state.listrancho.map((x, i) => {
                                                    return (
                                                        <div key={i} className="m-2 py-4 px-4 text-center rounded-full text-yellow-500 bg-white border-yellow-400 border-2">
                                                            {x.nombre}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                    <div className="col s12 m7 l7">
                                        <div className="form-layout">
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Alta de Rancho</span>
                                                
                                                
                                                        <div className="row">
                                                                <div className="col s12 m6 l6">
                                                                    <div className="input-field">
                                                                        <input id="nombre"
                                                                            name="nombre"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={ (e) => this.changeRancho(e)}
                                                                            value={this.state.ranchodata.nombre}/>
                                                                        <label htmlFor="nombre">Nombre: </label>
                                                                        <span className="helper-text">{this.state.error["nombre"]}</span>
                                                                    </div>
                                                                    
                                                                    <div className="input-field">
                                                                        <input id="rfc"
                                                                            name="rfc"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={ (e) => this.changeRancho(e)}
                                                                            value={this.state.ranchodata.rfc}/>
                                                                        <label htmlFor="rfc">RFC : </label>
                                                                        <span
                                                                            className="helper-text">{this.state.error["rfc"]}</span>
                                                                    </div>

                                                                    {/*<div className="input-field">
                                                                        <input id="user"
                                                                            name="user"
                                                                            className="input-3 validate"
                                                                            type="text"
                                                                            onChange={ (e) => this.changeRancho(e)}
                                                                            value={this.state.productor_id}
                                                                            disabled/>
                                                                        <label htmlFor="user" className="active">User ID : </label>
                                                                        <span className="helper-text">{this.state.error["user"]}</span>
                                                                    </div>
                                                                    
                                                                    
                                                                    <div className="input-field">
                                                                            <div className={imagePreviewUrlMapa ? "custom-input-file-ran-2":"custom-input-file-ran"}>
                                                                                <input className="file-input" 
                                                                                    type="file" 
                                                                                    name="mapa"
                                                                                    id="mapa"
                                                                                    accept=".pdf,.svg"
                                                                                    onChange={this.handleImageMapa} />  
                                                                                <div className="imgPreview">
                                                                                    {$imagePreviewMapa}
                                                                                </div>
                                                                            </div>
                                                                            <div  onClick={ (e) => this.clickImage('3') } className="icon-img-ran-map inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                                                            <i className="material-icons">edit</i>
                                                                            </div>
                                                                            
                                                                        </div>*/}
                                                                </div>                                                         
                                                            
                                                                <div className="col s12 m6 l6">

                                                                    <div className="input-field">
                                                                        <input id="empresa"
                                                                            name="empresa"
                                                                            className="input-4 validate"
                                                                            type="text" 
                                                                            onChange={ (e) => this.changeRancho(e)}
                                                                            value={this.state.ranchodata.empresa}/>
                                                                        <label htmlFor="empresa">Empresa : </label>
                                                                        <span
                                                                            className="helper-text">{this.state.error["empresa"]}</span>
                                                                    </div>

                                                                    <div className="input-field">
                                                                        <input id="superficie1"
                                                                            name="superficie1"
                                                                            className="input-4 validate"
                                                                            type="text"
                                                                            onChange={ (e) => this.changeRancho(e)}
                                                                            value={this.state.ranchodata.superficie1}/>
                                                                        <label htmlFor="superficie1">Superficie : </label>
                                                                        <span
                                                                            className="helper-text">{this.state.error["superficie1"]}</span>
                                                                    </div>
                                                                    
                                                                    

                                                                </div>

                                                                
                                                                <div className="col s12 m12 l12 pt-4">
                                                                    <span className="font-bold text-primary title-3">Responsable del rancho   </span>
                                                                    <div className="row">
                                                                        <div className="input-field col s6 m6 l6">
                                                                            <input id="nombrerespon"
                                                                                className="input-3"
                                                                                type="text"
                                                                                name="nombre_encargado"
                                                                                onChange={ (e) => this.changeRancho(e)}
                                                                                value={this.state.ranchodata.nombre_encargado}/>
                                                                                
                                                                            <label htmlFor="nombrerespon"> Nombre completo : </label>
                                                                            <span
                                                                                className="helper-text">{this.state.error["nombre_encargado"]}</span>
                                                                        </div>
                                                                        <div className="input-field col s6 m6 l6">
                                                                            <input id="tlfRespon"
                                                                                className="input-3"
                                                                                type="text"
                                                                                name="telefono_encargado"
                                                                                onChange={ (e) => this.changeRancho(e)}
                                                                                value={this.state.ranchodata.telefono_encargado}/>
                                                                            <label htmlFor="tlfRespon">Teléfono : </label>
                                                                            <span
                                                                                className="helper-text">{this.state.error["telefono_encargado"]}</span>
                                                                        </div>
                                                                        <div className="input-field col s6 m6 l6">
                                                                            <div className="custom-input-file-ran">
                                                                                <input className="file-input" 
                                                                                    type="file" 
                                                                                    name="ine_anverso"
                                                                                    id="foto1"
                                                                                    title="Favor de Agregar INE Anverso"
                                                                                    onChange={this.handleImageIneA} />  
                                                                                <div className="imgPreview">
                                                                                    {$imagePreviewIneA}
                                                                                </div>
                                                                            </div>
                                                                            <div  onClick={ (e) => this.clickImage('1') } className="icon-img-ran inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                                                            <i className="material-icons">edit</i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-field col s6 m6 l6">
                                                                            <div className="custom-input-file-ran">
                                                                                <input className="file-input" 
                                                                                    type="file" 
                                                                                    name="ine_reverso"
                                                                                    id="foto2"
                                                                                    title="Favor de Agregar INE Reverso"
                                                                                    onChange={this.handleImageIneR} />  
                                                                                <div className="imgPreview">
                                                                                    {$imagePreviewIneR}
                                                                                </div>
                                                                            </div>
                                                                            <div  onClick={ (e) => this.clickImage('2') } className="icon-img-ran inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                                                            <i className="material-icons">edit</i>
                                                                            </div>
                                                                        </div>

                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            

                                                            {/*<div className="box-space row">
                                                                <span className="font-bold text-primary title-3 col s12 m12 l12">Alta Sectores</span>
                                                                <div className="input-field col s5 m5 l5">
                                                                        <input
                                                                            name="cant"
                                                                            type="number"
                                                                            value={this.state.cant} 
                                                                            onChange={this.handleChange}
                                                                            className="input-4 validate"
                                                                            min="1"
                                                                        />  
                                                                        <label htmlFor="sector" className="active">Numero de Sectores : </label>
                                                                        <span className="helper-text">{this.state.error["cant"]}</span>
                                                                    </div>
                                                                <div className="box-space col s12 m12 l12">    
                                                                    this.state.cant !==0 ?
                                                                        <div  className="flex my-8 grid grid-cols-5 gap-4">  
                                                                            {this.state.sectoresdata.map((x, i) => {
                                                                                return (
                                                                                    <a key={i} 
                                                                                        title="Agrega Varias Temporadas del Cultivo" 
                                                                                        className={x.status === true ? 
                                                                                            "inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-yellow-400 shadow ripple "
                                                                                            : "cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-yellow-500 transition bg-white shadow ripple hover:shadow-lg hover:text-white hover:bg-yellow-500 focus:outline-none border-2 border-yellow-400"}
                                                                                            
                                                                                        onClick={(e) => this.sectorStep(x.id_sec,i)} >Sector {x.id_sec}</a>
                                                                                        
                                                                                );
                                                                                                                        
                                                                            })}
                                                                        </div>: ''
                                                                       
                                                                    {this.state.cant !=0 ?
                                                                        
                                                                        this.state.sectoresdata.map((x, i) => {
                                                                            return (
                                                                                
                                                                                    <div key={i} className="box-space col s12 m6 l6 my-2 my-2">
                                                                                        <div  className="flex my-8">  
                                                                                            <p className="font-bold text-yellow-500">
                                                                                                Sector {x.id_sec}
                                                                                            </p>                                                                               
                                                                                        </div>
                                                                                        <div className="">

                                                                                            <div className="input-field">
                                                                                                <input id="superficie"
                                                                                                    className="input-2 validate"
                                                                                                    type="text"
                                                                                                    onChange={ (e) => this.changeSectores(e,i)}
                                                                                                    value={x.superficie}
                                                                                                    name="superficie"/>
                                                                                                <label htmlFor="superficie" className="active">Superficie (HA): </label>
                                                                                                <span className="helper-text">{this.state.error["superficie"]}</span>
                                                                                            </div>
                                                                                            <div className="input-field">
                                                                                                <label htmlFor={'cultivo '} className="active">cultivo</label>
                                                                                                <select id="cultivo_id" name="cultivo_id" value={x.cultivo_id}  onChange={ (e) => this.changeSectores(e,i)} className="input-3 validate" 
                                                                                                title="Favor de Seleccionar un Cultivo de la Lista.">
                                                                                                    <option value="" disabled>Selecciona un Cultivo</option>
                                                                                                    {this.state.cultivos.map((cultivo,i)=> {
                                                                                                        return (
                                                                                                        <option  key={i}  value={cultivo.id}>{cultivo.nombre}</option>
                                                                                                        );
                                                                                                    })}
                                                                                                </select>  
                                                                                                <span className="helper-text">{this.state.error["cultivo_id"]}</span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                            );
                                                                                                                    
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="box-space">
                                                
                                                
                                                        
                                            </div>


                                            

                                            
                                        </div>
                                    </div>

                                </div>
                                <button className="bg-transparent float-right" onClick={(e) => this.dataRancho(e)} type="button">
                                    <div className="">
                                        <img src={next1} className="next-1"/>
                                    </div>
                                </button>
                            </form>
                        </div>
                    }

                    {this.state.step3 &&
                        <div className="container-fluid mx-auto">
                            <form>
                                <div className="row">
                                    <div className="col s11 m3 l3 offset-l1 offset-m1">
                                        <div className="flex justify-center">
                                            <div className="box-1">
                                                <h3 className="font-bold text-five title-2">Información de contacto</h3>
                                            </div>
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="card-productor">
                                                <div className="box-green">
                                                    <img src={infoprod} className="human1"/>
                                                    <div>
                                                        <p className="text-six font-bold title-6">ID Productor</p>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <div className="white-text text-center">
                                                            <p className="text-bold">{this.state.productor_id}</p>                                                               
                                                        </div>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <p className="text-six font-bold title-6">Nombre</p>
                                                    </div>
                                                    <div className="box-space-35">
                                                        <div className="white-text text-center">
                                                            <p className="text-bold">{this.state.user.first_name} {this.state.user.last_name}</p>                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col s12 m7 l7">
                                        <div className="form-layout">
                                            <span className="font-bold text-primary mb-2 title-3">Ubicación</span>
                                            <div className="container h-72">
                                                <Map
                                                    google={this.props.google}
                                                    zoom={17}
                                                    initialCenter={{
                                                        lat: this.state.latitud,
                                                        lng: this.state.longitud
                                                    }}
                                                    center={{
                                                        lat: this.state.latitud,
                                                        lng: this.state.longitud
                                                    }}
                                                    containerStyle={{width: '100%', height: '25rem', position: 'relative'}}
                                                    style={{width: '100%', height: '100%', position: 'relative'}}
                                                >
                                                    <Marker
                                                        position={{
                                                            lat: this.state.latitud,
                                                            lng: this.state.longitud
                                                        }}
                                                        name={this.state.user.razonSocial}
                                                        title={`Posicion del cultivo de ${this.state.user.razonSocial}`}
                                                    />
                                                </Map>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <button className="bg-transparent float-right" onClick={(e) => this.submitRancho(e)} type="button">
                                    <div className="">
                                        <img src={next1} className="next-1"/>
                                    </div>
                                </button>
                            </form>
                        </div>
                    }

                    {this.state.step4 &&
                        <div className="container-fluid mx-auto">
                            <div className="">
                                <div className="row pad40">
                                    <div className="col s12 m4 l4">
                                        <div className="flex justify-center">
                                            <img src={infoCulti} className="plant1"/>
                                            <div className="card-medicion">
                                                <div className="box-padding-50">
                                                    {this.state.cultivos.map((x, i) => {
                                                            return (
                                                                this.state.infoCultivo.cultivo_id === x.id ? 
                                                                <p key={i} className="text-six title-18">{x.nombre}</p>: ''
                                                            );
                                                        })}
                                                    
                                                </div>
                                                <div className="box-padding-20">
                                                    <p className="text-six title-14">Origen francés. Precoz. Fruto medio
                                                        grueso,
                                                        cónico alargado, color rojo.</p>
                                                </div>
                                                <div className="box-padding-20 text-center">
                                                    {this.state.variedades.map((x, i) => {
                                                            return (
                                                                <div key={i} type="button" className={this.state.infoCultivo.variedad_id === x.id ? 
                                                                    "cursor-default inline-block m-2 py-2 px-8 font-bold text-center rounded-full text-white bg-yellow-400 shadow ripple "
                                                                    :"cursor-default inline-block m-2 py-2 px-8 font-bold text-center rounded-full text-white bg-transparent shadow ripple border-2 border-yellow-400"}
                                                                   >{x.nombre}</div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col s12 m8 l8">
                                    
                                        <div className="form-layout">
                                            <span className="font-bold text-primary title-3">Sector Actual {this.state.id_sector}</span>
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Temporada</span>
                                                <div className="row">
                                                    <div className="">
                                                        <div className="flex my-2 grid grid-cols-4 gap-4 ">
                                                            {this.state.temporadas.map((x, i) => {
                                                                return (
                                                                    <button key={i} type="button" className={this.state.infoCultivo.temporada_id === x.id ? 
                                                                        "inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                                        :"cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-green transition bg-white shadow ripple hover:shadow-lg hover:text-white bg-green-ho focus:outline-none border-2 border-green"}
                                                                                     
                                                                    onClick={(e) => this.setTemporada(x.id)}>{x.nombre}</button>
                                                                );
                                                            })}
                                                            
                                                        </div>
                                                        <span className="helper-text">{this.state.error["temporada_id"]}</span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Cultivo</span>
                                                <div className="row">
                                                    <div className="">
                                                        <div className="flex my-2 grid grid-cols-4 gap-4 ">

                                                            {this.state.cultivos.map((x, i) => {
                                                                
                                                                return (
                                                                    <button key={i} type="button" className={this.state.infoCultivo.cultivo_id === x.id ? 
                                                                        "inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                                        :"cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-green transition bg-white shadow ripple hover:shadow-lg hover:text-white bg-green-ho focus:outline-none border-2 border-green"}
                                                                     onClick={(e) => this.setCultivo(x.id)}                 
                                                                    >{x.nombre}</button>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Variedades</span>
                                                <div className="row">
                                                    <div className="">
                                                        <div className="flex my-2 grid grid-cols-4 gap-4 ">

                                                           {this.state.infoCultivo.cultivo_id === ''?
                                                            <span className="font-bold text-primary title-3">Seleccione un cultivo</span> :
                                                            this.state.variedades.map((x, i) => {
                                                                    return (
                                                                        <button key={i} type="button" className={this.state.infoCultivo.variedad_id === x.id ? 
                                                                            "inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                                            :"cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-green transition bg-white shadow ripple hover:shadow-lg hover:text-white bg-green-ho focus:outline-none border-2 border-green"}
                                                                                        
                                                                        onClick={(e) => this.setVariedades(x.id)}>{x.nombre}</button>
                                                                    );
                                                                })
                                                            }
                                                            
                                                        </div>
                                                        <span className="helper-text">{this.state.error["variedad_id"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Medios</span>
                                                <div className="row">
                                                    <div className="">
                                                        <div className="flex my-2 grid grid-cols-4 gap-4 ">
                                                            {this.state.medios.map((x, i) => {
                                                                return (
                                                                    <button key={i} type="button" className={this.state.infoCultivo.medio === x.nombre ? 
                                                                        "inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                                        :"cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-green transition bg-white shadow ripple hover:shadow-lg hover:text-white bg-green-ho focus:outline-none border-2 border-green"}
                                                                                     
                                                                    onClick={(e) => this.setMedio(x.nombre)}>{x.nombre}</button>
                                                                );
                                                            })}
                                                            
                                                        </div>
                                                        <span className="helper-text">{this.state.error["medio"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className="box-space">
                                                <div className="row">
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                            <span className="font-bold text-primary title-3">Superficie (HE)</span>
                                                            <div className="input-field col s12">
                                                                <div className="box-space-sm">
                                                                    <input id="distancia" className="input-3"
                                                                           type="number"
                                                                           onChange={(e) => this.changeCultivo(e, 'superficie')}
                                                                           value={this.state.infoCultivo["superficie"]}
                                                                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0" title="Solo Numeros"
                                                                    />
                                                                </div>
                                                                <span className="helper-text">{this.state.error["superficie"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="box-space">
                                                <span className="font-bold text-primary title-3">Fechas</span>
                                                <div className="row">
                                                    <div className="input-field col s6 m6 l6">
                                                        <div className="box-space-sm">
                                                            <input id="plantacion"
                                                                   className="input-3"
                                                                   type="date"
                                                                   pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                                                   onChange={(e) => this.changeCultivo(e, 'fecha_plantacion')}
                                                                   value={this.state.infoCultivo["fecha_plantacion"]}/>
                                                        </div>
                                                        <label htmlFor="plantacion" className="active">Plantacion : </label>
                                                        <span className="helper-text">{this.state.error["fecha_plantacion"]}</span>
                                                    </div>

                                                    <div className="input-field col s6 m6 l6">
                                                        <div className="box-space-sm">
                                                            <input id="poda1"
                                                                   className="input-3"
                                                                   type="date"
                                                                   pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                                                   onChange={(e) => this.changeCultivo(e, 'fecha_poda')}
                                                                   value={this.state.infoCultivo["fecha_poda"]}/>
                                                        </div>
                                                        <label htmlFor="poda1" className="active">Poda : </label>
                                                        <span className="helper-text">{this.state.error["fecha_poda"]}</span>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="box-space">
                                                <div className="row">
                                                                                               
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                            <span className="font-bold text-primary title-3">Distancia entre plantas (metros)</span>
                                                            <div className="input-field col s12">
                                                                <div className="box-space-sm">
                                                                    <input id="plantacion" className="input-3"
                                                                           type="number"
                                                                           onChange={(e) => this.changeCultivo(e, 'distancia_entre_plantas')}
                                                                           value={this.state.infoCultivo["distancia_entre_plantas"]}
                                                                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0" title="Solo Numeros"
                                                                    />
                                                                </div>
                                                                <span className="helper-text">{this.state.error["distancia_entre_plantas"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                            <span className="font-bold text-primary title-3">Distancia entre camas (metros)</span>
                                                            <div className="input-field col s12">
                                                                <div className="box-space-sm">
                                                                    <input id="camas" className="input-3" 
                                                                           type="number"
                                                                           onChange={(e) => this.changeCultivo(e, 'distancia_entre_camas')}
                                                                           value={this.state.infoCultivo["distancia_entre_camas"]}
                                                                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"
                                                                    />
                                                                </div>
                                                                <span className="helper-text">{this.state.error["distancia_entre_camas"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                        <span
                                                            className="font-bold text-primary title-3">Largo de cama (metros)</span>
                                                            <div className="input-field col s12">
                                                                <div className="box-space-sm">
                                                                    <input id="poda3" className="input-5" type="number"
                                                                           onChange={(e) => this.changeCultivo(e, 'largo_cama')}
                                                                           value={this.state.infoCultivo["largo_cama"]}
                                                                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"
                                                                    />
                                                                </div>
                                                                <span className="helper-text">{this.state.error["largo_cama"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                        <span
                                                            className="font-bold text-primary title-3">Lineas de plantas por cama</span>
                                                            <div className="input-field col s12">
                                                                <div className="box-space-sm">
                                                                    <input id="poda4" className="input-5" type="number"
                                                                           onChange={(e) => this.changeCultivo(e, 'lineas_planta_cama')}
                                                                           value={this.state.infoCultivo["lineas_planta_cama"]}
                                                                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"
                                                                    />
                                                                </div>
                                                                <span className="helper-text">{this.state.error["lineas_planta_cama"]}</span>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                        <span
                                                            className="font-bold text-primary title-3">Distancia</span>
                                                            <div className="input-field col s12">
                                                                <input id="hectareas" className="input-3"
                                                                       type="number"
                                                                       onChange={(e) => this.changeCultivo(e, 'distancia_por_hectarea')}
                                                                       value={this.state.infoCultivo["distancia_por_hectarea"]}
                                                                       pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"/>
                                                                <span className="suffix">
                                                                    Hectáreas
                                                                </span>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <div className="justify-center">
                                                            <span className="font-bold text-primary title-3">Plantas por hectárea</span>
                                                            <div className="input-field col s12">
                                                                <input id="plantas" className="input-3" type="number"
                                                                       onChange={(e) => this.changeCultivo(e, 'plantas_ha')}
                                                                       value={this.state.infoCultivo["plantas_ha"]}
                                                                       pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"/>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <span className="helper-text">{this.state.error["distancia_por_hectarea"]}</span>
                                                    </div>
                                                    <div className="col s12 m6 l6">
                                                        <span className="helper-text">{this.state.error["plantas_ha"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <button className="bg-transparent float-right" onClick={(e) => this.saveCultivo(e)} type="button">
                                <div className="">
                                    <img src={next1} className="next-1"/>
                                </div>
                            </button>
                        </div>
                    }

                    {this.state.step5 &&
                        <div className="container-fluid mx-auto">
                            <div className="row">
                                <div className="col s11 m11 l11 offset-l1">
                                    <div className="row pad40">
                                        <div className="col s12 m6 l6">
                                            <div className="row">
                                                <div className="col s12">
                                                    <span className="font-bold text-primary title-3">Días de aplicación de fertirriego por semana</span>
                                                    <div className="box-space">
                                                        <div className="row">
                                                            <div className="col s12 m12 l12">
                                                                <div className="my-4">
                                                                    {this.state.dias.map((x, i) => {
                                                                        return (
                                                                            <button key={i} type="button" className={x.status === true ? 
                                                                                "inline-block mx-2 py-2 px-4 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                                                :"cursor-pointer inline-block mx-2 py-2 px-4 font-bold text-center rounded-full text-green transition bg-white shadow ripple hover:shadow-lg hover:text-white bg-green-ho focus:outline-none border-2 border-green"}
                                                                                onClick={(e) => this.setDias(x.id)}
                                                                            >{x.id}</button>
                                                                        );
                                                                    })}
                                                                    <span className="helper-text">{this.state.error["dias_aplicacion_fertirriego_por_semana"]}</span>
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col s6">
                                                    <span
                                                        className="font-bold text-primary title-3">Tipo de agua</span>
                                                    <div className="box-space">
                                                        <select  id="tipo_agua" value={this.state.infoRiego["tipo_agua"] || ''} onChange={(e) =>this.changeRiego(e, 'tipo_agua')} className="browser-default">
                                                            <option  value="" disabled>Selecciona una opción</option>
                                                            <option value="1">Pozo profundo</option>
                                                            <option value="2">Ósmosis</option>
                                                            <option value="3">Canal</option>
                                                        </select>
                                                        <span className="helper-text">{this.state.error["tipo_agua"]}</span>
                                                    </div>
                                                </div>
                                                <div className="col s6">
                                                    <span
                                                        className="font-bold text-primary title-3">Tipo de sistema de riego</span>
                                                    <div className="box-space">
                                                        <select id="tipo_regante" value={this.state.infoRiego["tipo_sistema_riego"] || ''} onChange={(e) =>this.changeRiego(e, 'tipo_sistema_riego')} className="browser-default ">
                                                            <option  value="" disabled>Selecciona una opción</option>
                                                            <option value="1">Rodado</option>
                                                            <option value="2">Cintilla</option>
                                                            <option value="3">Manguera</option>
                                                            <option value="4">Piquetas</option>
                                                            <option value="5">Serpentín</option>
                                                            <option value="6">Microaspersor</option>
                                                            <option value="7">Pivote central</option>
                                                            <option value="8">Pivote frontral</option>
                                                        </select>
                                                        <span className="helper-text">{this.state.error["tipo_sistema_riego"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="box-space">
                                                        <span className="font-bold text-primary title-3">Tipo de Regante</span>
                                                    </div>
                                                </div>

                                                <div className="col s12 m12 l12">
                                                    <div className="box-space-sm">
                                                        {this.state.tiporegante.map((x, i) => {
                                                            return (
                                                                <button key={i} type="button" className={x.id === this.state.infoRiego.tipo_regante ? 
                                                                    "big-rounded activebr m-4"
                                                                    :"big-rounded m-4"}
                                                                    onClick={(e) => this.setTipoRegante(x.id)}
                                                                >{x.nombre}</button>
                                                            );
                                                        })}
                                                        <span className="helper-text">{this.state.error["tipo_regante"]}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.medio === 'HIDROPONIA' ? 
                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="box-space">
                                                        <span className="font-bold text-primary title-3">Flujo por superficie</span>
                                                    </div>
                                                </div>

                                                <div className="input-field col s12">
                                                    <input id="pulso1"
                                                                   className="input-3"
                                                                   type="time"
                                                                   pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                                   onChange={(e) => this.changeRiego(e, 'inicio_primer_pulso')}
                                                                   value={this.state.infoRiego["inicio_primer_pulso"] || ''}/>
                                                    <label htmlFor="pulso1">Inicio del primer pulso : </label>
                                                    <span className="helper-text">{this.state.error["inicio_primer_pulso"]}</span>
                                                </div>

                                                <div className="input-field col s12">
                                                        <input id="pulso2"
                                                                   className="input-3"
                                                                   type="time"
                                                                   pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                                   value={this.state.infoRiego.termino_ultimo_pulso}
                                                                   disabled/>
                                                    <label htmlFor="pulso2">Termino del ultimo pulso (Automatico): </label>
                                                </div>
                                            </div>: ''}
                                        </div>

                                        <div className="col s12 m6 l6">
                                            <div className="row ">
                                                <div className="col s12">
                                                    <div className="box-space">
                                                        <span className="font-bold text-primary title-3">Volumen por gotero</span>
                                                        <div className="box-space">
                                                            <p className="text-five">Litros por hora</p>
                                                        </div>
                                                        {/*<form action="#">
                                                           <Slider
                                                                min={0}
                                                                max={10}
                                                                value={this.state.infoRiego.volumen_por_gotero}
                                                                labels={horizontalLabels}
                                                                format={formatL}
                                                                onChange={this.handleChangevolgot}
                                                            />
                                                        </form>*/}
                                                        <div className="col s5">
                                                            <div className="input-field flex">
                                                                <input id="volgot" className="input-3" type="number"
                                                                    onChange={(e) => this.changeRiego(e, 'volumen_por_gotero')}
                                                                    value={this.state.infoRiego["volumen_por_gotero"] || ''}
                                                                    pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"/>
                                                                <p className="preffix">Litros</p>
                                                            </div>  
                                                            <span className="helper-text">{this.state.error["volumen_por_gotero"]}</span>                                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="row">
                                                        <div className="col s12 m6 l6">
                                                            <div className="box-space">
                                                                <p className="text-five">Distancia entre goteros</p>
                                                                <div className="box-space">
                                                                    <div className="col s2">
                                                                        <img src={figureLeft} className="icon-goteros"/>
                                                                    </div>
                                                                    <div className="col s5">
                                                                        <div className="input-field flex">
                                                                            <input id="distancia" className="input-3" type="number"
                                                                                onChange={(e) => this.changeRiego(e, 'distancia_entre_gotero')}
                                                                                value={this.state.infoRiego["distancia_entre_gotero"] || ''}
                                                                                pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"/>
                                                                            <p className="preffix">Metros</p>
                                                                        </div>  
                                                                        <span className="helper-text">{this.state.error["distancia_entre_gotero"]}</span>                                                                      
                                                                    </div>
                                                                    <div className="col s2">
                                                                        <img src={figureRigth} className="icon-goteros"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col s12 m6 l6">
                                                            <div className="box-space">
                                                                <p className="text-five">Cintas por cama</p>
                                                                <div className="box-space">
                                                                    <div className="col s2 flex">
                                                                        <img src={linever} className="icon-linever m-1"/>
                                                                        <img src={linever} className="icon-linever m-1"/>
                                                                        <img src={linever} className="icon-linever m-1"/>
                                                                    </div>
                                                                    <div className="col s5">
                                                                        <div className="input-field flex">
                                                                            <input id="plantas" className="input-3" type="number"
                                                                                onChange={(e) => this.changeRiego(e, 'numero_cintas_por_cama')}
                                                                                value={this.state.infoRiego["numero_cintas_por_cama"] || ''}
                                                                                pattern="[0-9]+([\.,][0-9]+)?" step="0.01" min="0"/>
                                                                            
                                                                        </div>
                                                                        <span className="helper-text">{this.state.error["numero_cintas_por_cama"]}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="row">
                                                        <div className="col s12 m6 l6">
                                                            <div className="box-space">
                                                                <p className="text-five">Tiempo de riego</p>
                                                                <div className="box-space">
                                                                    <div className="col s5 ">
                                                                        <div className="input-field flex">
                                                                            <input id="tiempor" className="input-3" type="number"
                                                                                onChange={(e) => this.changeRiego(e, 'tiempo_riego')}
                                                                                value={this.state.infoRiego["tiempo_riego"] || ''}
                                                                                min="0"/>
                                                                            <p className="preffix">Minutos</p>
                                                                        </div>
                                                                        <span className="helper-text">{this.state.error["tiempo_riego"]}</span>
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                        {this.state.medio === 'HIDROPONIA' ?
                                                            <div className="col s12 m6 l6">
                                                                <div className="box-space">
                                                                    <p className="text-five">Humedad</p>
                                                                    <div className="box-space">
                                                                        <div className="col s5 ">
                                                                            <div className="input-field flex">
                                                                                <input id="tiempor" className="input-3" type="number"
                                                                                    name="humedad"
                                                                                    onChange={(e) => this.changeRango(e)}
                                                                                    value={this.state.humedad}
                                                                                    min="0"/>
                                                                            </div>
                                                                            <span className="helper-text">{this.state.error["humedad"]}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                            
                                                            </div>
                                                        :''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {this.state.medio === 'HIDROPONIA' ?
                                                    <div className="col s12">
                                                        <div className="box-space">
                                                            <div className="input-field s12">
                                                                <input id="gotxpl" className="input-3" type="number"
                                                                        onChange={(e) => this.changeRiego(e, 'goteros_por_planta')}
                                                                        value={this.state.infoRiego["goteros_por_planta"] || ''}
                                                                        min="0"/>
                                                                <label htmlFor="gotxpl">Goteros por planta: </label>
                                                                <span className="helper-text">{this.state.error["goteros_por_planta"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :''} 
                                                <div className="col s6">
                                                    {this.state.medio === 'HIDROPONIA' ?
                                                        <div>
                                                            <div className="box-space">
                                                                <span className="font-bold text-primary title-3">Volumen por planta</span>
                                                                <div className="box-space">
                                                                    <p className="text-five">Litros por hora</p>
                                                                </div>
                                                                <div className="input-field s12">
                                                                    <p className="preffix">{this.state.infoRiego.volumen_por_planta}</p>
                                                                </div>                                                        
                                                            </div>
                                                        </div>
                                                    :''} 
                                                    <div className="box-space">
                                                        <span className="font-bold text-primary title-3">Volumen por hectarea (Automatico)</span>
                                                        <div className="box-space">
                                                            <p className="text-five">Litros</p>
                                                        </div>
                                                        <div className="input-field s12">
                                                            <p className="preffix">{this.state.infoRiego.volumen_por_ha}</p>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                                <div className="col s6">
                                                    {this.state.medio === 'HIDROPONIA' ?
                                                        <div>
                                                            <div className="box-space">
                                                                <span className="font-bold text-primary title-3">Volumen por minuto/Ha (Automatico)</span>
                                                                <div className="input-field s12">
                                                                    <p className="preffix">{this.state.paramMedicion.suelo_vol_por_min_ha}</p>
                                                                </div>                                                        
                                                            </div>
                                                        
                                                            <div className="box-space">
                                                                <span className="font-bold text-primary title-3">Volumen agua por dia (Automatico)</span>
                                                                <div className="input-field s12">
                                                                    <p className="preffix">{this.state.paramMedicion.suelo_vol_agua_por_dia}</p>
                                                                </div>                                                        
                                                            </div>
                                                        </div>
                                                    :''} 
                                                </div>
                                            </div>

                                            
                                            {this.state.medio === 'HIDROPONIA' ?
                                            <div className="row">
                                                <div className="col s12">
                                                    <div className="box-space-sm">
                                                        <div className="flex justify-center pulsos">
                                                            
                                                            <div className="plant-pul">
                                                                <img src={Plant} />
                                                            </div>
                                                            <div>
                                                                <div className="input-field col s12 mt-2">
                                                                        <input id="npulso"
                                                                                className="input-3"
                                                                                type="text"
                                                                                pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                                                onChange={(e) => this.changeRiego(e, 'numero_de_pulsos')}
                                                                                value={this.state.infoRiego["numero_de_pulsos"] || ''}/>
                                                                    <label htmlFor="npulso">Número de pulsos : </label>
                                                                    <span className="helper-text">{this.state.error["numero_de_pulsos"]}</span>
                                                                </div>                                                                
                                                                <div className="input-field col s12">
                                                                        <input id="ipulso"
                                                                                className="input-3"
                                                                                type="text"
                                                                                pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                                                onChange={(e) => this.changeRiego(e, 'intervalo_entre_pulsos')}
                                                                                value={this.state.infoRiego["intervalo_entre_pulsos"] || ''}/>
                                                                    <label htmlFor="ipulso">Intervalo entre pulsos (Minutos): </label>
                                                                    <span className="helper-text">{this.state.error["intervalo_entre_pulsos"]}</span>
                                                                </div>
                                                                <div className="input-field col s12">
                                                                    <input id="tpulso"
                                                                            className="input-3"
                                                                            type="text"
                                                                            pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
                                                                            value={this.state.infoRiego.tiempo_por_pulsos}
                                                                            disabled/>
                                                                    <label htmlFor="tpulso" className="active">Tiempo por pulsos (Minutos): </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            :''}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="bg-transparent float-right" onClick={(e) => this.dataRiego(e)} type="button">
                                <div className="">
                                    <img src={next1} className="next-1"/>
                                </div>
                            </button>
                        </div>
                    }

                    {this.state.step6 &&
                        <div className="container-fluid mx-auto">
                            <div className="row font-tabs h-screen">
                                {this.state.substep1 === false?
                                    <div onClick={(e) => this.setTabs(1)} className="cursor-pointer col s12 m12 l12 p-16 substep1">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s12 m6 l6">
                                                <div className="row ">
                                                    <div className="col s12">
                                                        <span className="font-bold text-primary title-3">Parametros de medicion</span>                                                    
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>:
                                    <div className="col s12 m12 l12 p-16 substep1">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s11 offset-s1">
                                                <div className="">
                                                    <div className="">
                                                        <span className="font-bold text-primary title-3">parametros de medicion</span>                                                    
                                                    </div>
                                                    <div className="flex">
                                                    {this.state.medio === 'SUELO'?
                                                        <div className="px-8 py-10 my-6 mx-16 bg-white rounded-3xl col s4 cards-med">
                                                            <div className="col s12 mt-4">
                                                                <span className="text-primary title-3">Suelo</span> 
                                                            </div>                                                   
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="suelo_ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeParametros(e, 'suelo_ph')}
                                                                        value={this.state.paramMedicion["suelo_ph"]}/>
                                                                    <label htmlFor="suelo_ph">PH: </label>
                                                                    <span className="helper-text">{this.state.error["suelo_ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="suelo_conductividad"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeParametros(e, 'suelo_conductividad')}
                                                                        value={this.state.paramMedicion["suelo_conductividad"]}/>
                                                                    <label htmlFor="suelo_conductividad">Conductividad: </label>
                                                                    <span className="helper-text">{this.state.error["suelo_conductividad"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="suelo_humedad_rango_actual"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeParametros(e, 'suelo_humedad_rango_actual')}
                                                                        value={this.state.paramMedicion["suelo_humedad_rango_actual"]}/>
                                                                    <label htmlFor="suelo_humedad_rango_actual">Humedad: </label>
                                                                    <span className="helper-text">{this.state.error["suelo_humedad_rango_actual"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            
                                                         </div>
                                                    :   
                                                        <div>  
                                                            <div className="px-8 py-10 my-6 mx-10 bg-white rounded-3xl col s3 cards-med">
                                                                <div className="col s12 mt-4">
                                                                    <span className="text-primary title-3">Solucion Nutritiva</span> 
                                                                </div>                                                   
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="sustrato_ph"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'sustrato_ph')}
                                                                            value={this.state.paramMedicion["sustrato_ph"]}/>
                                                                        <label htmlFor="sustrato_ph">PH: </label>
                                                                        <span className="helper-text">{this.state.error["sustrato_ph"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="sustrato_ce"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'sustrato_ce')}
                                                                            value={this.state.paramMedicion["sustrato_ce"]}/>
                                                                        <label htmlFor="sustrato_ce">Conductividad: </label>
                                                                        <span className="helper-text">{this.state.error["sustrato_ce"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="sustrato_vol"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'sustrato_vol')}
                                                                            value={this.state.paramMedicion["sustrato_vol"]}/>
                                                                        <label htmlFor="sustrato_vol">Volumen: </label>
                                                                        <span className="helper-text">{this.state.error["sustrato_vol"]}</span>
                                                                    </div>                                                                      
                                                                </div> 
                                                            </div>
                                                            <div className="px-8 py-10 my-6 mx-10 bg-white rounded-3xl col s3 cards-med">
                                                                <div className="col s12 mt-4">
                                                                    <span className="text-primary title-3">Exprimido de sustrato</span> 
                                                                </div>                                                   
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="exp_sustrato_ph"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'exp_sustrato_ph')}
                                                                            value={this.state.paramMedicion["exp_sustrato_ph"]}/>
                                                                        <label htmlFor="exp_sustrato_ph">Exprimido PH: </label>
                                                                        <span className="helper-text">{this.state.error["exp_sustrato_ph"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="exp_sustrato_ce"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'exp_sustrato_ce')}
                                                                            value={this.state.paramMedicion["exp_sustrato_ce"]}/>
                                                                        <label htmlFor="exp_sustrato_ce">Exprimido Conductividad: </label>
                                                                        <span className="helper-text">{this.state.error["exp_sustrato_ce"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="exp_sustrato_vol"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'exp_sustrato_vol')}
                                                                            value={this.state.paramMedicion["exp_sustrato_vol"]}/>
                                                                        <label htmlFor="exp_sustrato_vol">Exprimido Volumen: </label>
                                                                        <span className="helper-text">{this.state.error["exp_sustrato_vol"]}</span>
                                                                    </div>                                                                      
                                                                </div> 
                                                            </div>
                                                            <div className="px-8 py-10 my-6 mx-10 bg-white rounded-3xl col s3 cards-med">
                                                                <div className="col s12 mt-4">
                                                                    <span className="text-primary title-3">Drenaje</span> 
                                                                </div>                                                   
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="drenaje_ph"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'drenaje_ph')}
                                                                            value={this.state.paramMedicion["drenaje_ph"]}/>
                                                                        <label htmlFor="drenaje_ph">Drenaje PH: </label>
                                                                        <span className="helper-text">{this.state.error["drenaje_ph"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="drenaje_ce"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'drenaje_ce')}
                                                                            value={this.state.paramMedicion["drenaje_ce"]}/>
                                                                        <label htmlFor="drenaje_ce">Drenaje Conductividad: </label>
                                                                        <span className="helper-text">{this.state.error["drenaje_ce"]}</span>
                                                                    </div>                                                                      
                                                                </div>
                                                                <div className="col s10">
                                                                    <div className="input-field">
                                                                        <input id="drenaje_vol"
                                                                            className="input-2 validate"
                                                                            type="text"
                                                                            onChange={(e) => this.changeParametros(e, 'drenaje_vol')}
                                                                            value={this.state.paramMedicion["drenaje_vol"]}/>
                                                                        <label htmlFor="drenaje_vol">Drenaje Volumen: </label>
                                                                        <span className="helper-text">{this.state.error["drenaje_vol"]}</span>
                                                                    </div>                                                                      
                                                                </div> 
                                                            </div>
                                                        </div>    
                                                    }
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                }
                                {/*this.state.substep2 === false?
                                    <div onClick={(e) => this.setTabs(2)} className="cursor-pointer col s12 m12 l12 p-16 substep2">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s12 m6 l6">
                                                <div className="row ">
                                                    <div className="col s12">
                                                        <span className="font-bold text-primary title-3">Análisis fisicoquímicos</span> 
                                                     </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>:
                                    <div className="col s12 m12 l12 p-16 substep2">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s12 m12 l12">
                                                <div className="">
                                                    <div className="">
                                                        <span className="font-bold text-primary title-3">Análisis fisicoquímicos</span>   
                                                        <p className="text-primary title-3">Selecciona un recuadro para adjuntar documentos con los resultados de los análisis, puedes pasar a la siguiente sección y terminar este parte posteriormente.</p>                                                   
                                                    </div>
                                                    <div className="flex">
                                                        <div className="px-8 py-10 my-6 mx-16 col s6 flex">
                                                            <div className="col s12 mt-4 flex justify-center items-center">
                                                                <span className="text-primary title-3">Agua</span> 
                                                            </div> 
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data1.agua !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="agua"
                                                                            id="0"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data1.agua !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data1.agua !== '' ? data1.agua.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data2.agua !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="agua"
                                                                            id="1"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data2.agua !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data2.agua !== '' ? data2.agua.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data3.agua !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="agua"
                                                                            id="2"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi}
                                                                            disabled={this.state.analisisfisi.length===2 ? false:true} />  
                                                                            <div className="dataPreview">
                                                                                {data3.agua !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data3.agua !== '' ? data3.agua.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>                                                            
                                                        </div>
                                                        
                                                        <div className="px-8 py-10 my-6 mx-16 col s6 flex">
                                                            <div className="col s12 mt-4 flex justify-center items-center">
                                                                <span className="text-primary title-3">Fibra</span> 
                                                            </div> 
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data1.fibra !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fibra"
                                                                            id="0"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data1.fibra !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data1.fibra !== '' ? data1.fibra.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data2.fibra !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fibra"
                                                                            id="1"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data2.fibra !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data2.fibra !== '' ? data2.fibra.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data3.fibra !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fibra"
                                                                            id="2"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi}
                                                                            disabled={this.state.analisisfisi.length===2 ? false:true} />  
                                                                            <div className="dataPreview">
                                                                                {data3.fibra !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data3.fibra !== '' ? data3.fibra.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>                                                            
                                                        </div> 
                                                    </div>

                                                    <div className="flex">
                                                        <div className="px-8 py-10 my-6 mx-16 col s6 flex">
                                                            <div className="col s12 mt-4 flex justify-center items-center">
                                                                <span className="text-primary title-3">Suelo</span> 
                                                            </div> 
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data1.suelo !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="suelo"
                                                                            id="0"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data1.suelo !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data1.suelo !== '' ? data1.suelo.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data2.suelo !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="suelo"
                                                                            id="1"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data2.suelo !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data2.suelo !== '' ? data2.suelo.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data3.suelo !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="suelo"
                                                                            id="2"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi}
                                                                            disabled={this.state.analisisfisi.length===2 ? false:true} />  
                                                                            <div className="dataPreview">
                                                                                {data3.suelo !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data3.suelo !== '' ? data3.suelo.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>                                                            
                                                        </div>
                                                        
                                                        <div className="px-8 py-10 my-6 mx-16 col s6 flex">
                                                            <div className="col s12 mt-4 flex justify-center items-center">
                                                                <span className="text-primary title-3">Fertibilidad</span> 
                                                            </div> 
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data1.fertibilidad !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fertibilidad"
                                                                            id="0"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data1.fertibilidad !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data1.fertibilidad !== '' ? data1.fertibilidad.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data2.fertibilidad !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fertibilidad"
                                                                            id="1"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi} />  
                                                                            <div className="dataPreview">
                                                                                {data2.fertibilidad !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data2.fertibilidad !== '' ? data2.fertibilidad.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                            <div className="col s8">
                                                                <div className="input-field">
                                                                   <div className={data3.fertibilidad !== '' ? "custom-input-file-anafis-2":"custom-input-file-anafis"}>
                                                                        <input className="file-input" 
                                                                            type="file" 
                                                                            name="fertibilidad"
                                                                            id="2"
                                                                            accept=".pdf,.svg"
                                                                            onChange={this.handleFileAnFi}
                                                                            disabled={this.state.analisisfisi.length===2 ? false:true} />  
                                                                            <div className="dataPreview">
                                                                                {data3.fertibilidad !== '' ? $iconpdf:"+"}
                                                                            </div>
                                                                    </div>
                                                                    <div className="dataPreview text-center">
                                                                        {data3.fertibilidad !== '' ? data3.fertibilidad.name:""}
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>                                                            
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                }
                                {this.state.substep3 === false?
                                    <div onClick={(e) => this.setTabs(3)} className="cursor-pointer col s12 m12 l12 p-16 substep3">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s12 m6 l6">
                                                <div className="row ">
                                                    <div className="col s12">
                                                        <span className="font-bold text-primary title-3">Análisis fitopatológicos</span>                                                    
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>:
                                    <div className="col s12 m12 l12 p-16 substep3">
                                        <div className="row pt-32 pl-10">
                                            <div className="col s12 m12 l12">
                                                <div className="">
                                                    <div className="">
                                                        <span className="font-bold text-primary title-3">Análisis fitopatológicos</span>                                                    
                                                    </div>
                                                    <div className="flex">
                                                         <div className="px-8 py-10 my-6 mx-16 bg-white rounded-3xl col s4 cards-med">
                                                         <div className="col s12 mt-4">
                                                                <span className="text-primary title-3">Suelo</span> 
                                                            </div>                                                   
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                            <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            
                                                         </div>
                                                         <div className="px-8 py-10 my-6 mx-16 bg-white rounded-3xl col s4 cards-med">
                                                            <div className="col s12 mt-4">
                                                                <span className="text-primary title-3">Hidroponia</span> 
                                                            </div>                                                   
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                            <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                            <div className="col s10">
                                                                <div className="input-field">
                                                                    <input id="ph"
                                                                        className="input-2 validate"
                                                                        type="text"
                                                                        onChange={(e) => this.changeCultivo(e, 'ph')}
                                                                        value={this.state.infoCultivo["ph"] || ''}/>
                                                                    <label htmlFor="ph">ph: </label>
                                                                    <span className="helper-text">{this.state.error["ph"]}</span>
                                                                </div>                                                                      
                                                            </div>
                                                         </div>
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                */}
                                <div className="button-send">
                                    <button className="bg-transparent float-right" onClick={(e) => this.saveMonitoreo(e)} type="button">
                                        <div className="">
                                            <img src={next1} className="next-1"/>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            

                            
                        </div>
                    }
                    
                    {this.state.step7 &&
                        <div className="container-fluid mx-auto h-screen stepend flex items-center">
                            <div className="row">
                                <div className="col s12 m12 l12">
                                        <div className="grid justify-items-center ">
                                            <div className=""><span className="font-bold text-primary title-3">Datos Registrados Exitosamente!</span></div>
                                            <div className="pb-3"><span className="text-primary title-3">Elija una opción para continuar</span></div>
                                            <div className="p-3"> 
                                                <button type="button" className="cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                    onClick={(e) => this.clearData(2)}>Agregar otro Sector</button>
                                                <button type="button" className="cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                    onClick={(e) => this.clearData(1)}>Agregar otro Rancho</button>
                                            </div>
                                            <button type="button" className="cursor-pointer inline-block mx-2 py-2 px-8 font-bold text-center rounded-full text-white transition bg-green shadow ripple "
                                                    onClick={(e) => this.clearData(3)}>Menu Pricipal</button>
                                        </div>                                     
                                </div>            
                               
                            </div>        
                        </div>
                    }


                </LayoutTwo>

            </>
        )

    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}


export default withRouter(connect(mapStateToProps)(GoogleApiWrapper({
    apiKey: 'AIzaSyBjmEetWBMYt7YMitfzk6wj_5BMqWrKfGg'
})(AltaProductor)))