import React from "react";
import Layout from "../../layouts/Layout";

import HomeIngenieros from '../../components/homes/HomeIngenieros';
import HomeAdmin from '../../components/homes/HomeAdmin';
import HomeQuimico from '../../components/homes/HomeQuimico';
import HomeProductor from '../../components/homes/HomeProductor';
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            auth: null,
            type: null
        };
    }

    render() {
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes h-screen';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        if(this.props.user.is_quimico) {
            clase = 'quimico admin-ordenes h-screen';
        }
        if(this.props.user.is_productor) {
            clase = 'productor admin-ordenes';
        }
        return (
            <>
                <Layout title = "Bienvenido de nuevo" className = { clase } classText = { clase }>
                    { this.props.user.is_ingeniero_de_Campo ? ( <HomeIngenieros /> ) : '' }
                    { this.props.user.is_administrador ? ( <HomeAdmin /> ) : '' }
                    { this.props.user.is_quimico ? ( <HomeQuimico /> ) : '' }
                    { this.props.user.is_productor ? ( <HomeProductor /> ) : '' }
                </Layout>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication
    return {
        user, token
    }
};
export default withRouter(connect(mapStateToProps)(Home))