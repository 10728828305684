import React, {Component} from "react";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import LayoutDiag from "../../layouts/LayoutDiag";
import next1 from "../../assets/img/partials/next-1.png";

class Diagnosticos extends Component{

    render() { 

        return (
            <>

            <LayoutDiag></LayoutDiag>
                <div class="container-fluid mx-auto">
                <div class="row"> 
                <div class="col s11 m11 l11"> 
                <ul class="collapsible">
                <li class="active tab1 bg-four"> 
                <div class="collapsible-header bg-four">
                <div className="flex">
                <h3 className="font-bold fr text-primary title-4 pad30">Realización de diagnóstico</h3>
                </div>
                </div>
                <div class="collapsible-body">
                 <div class="row pad40">
                <div class="col s12 m4 l4"> 
                <div className="box-space"> 
                <div class="card-white2"> 
                <div class="col s12 m4 l4"> 
                <p class="text-primary">field:</p>
                <div class="input-field inline">
                    <input id="email_inline" type="email" class="validate"/>
                    <label for="email_inline">Email</label>
                    <span class="helper-text" data-error="wrong" data-success="right">Helper text</span>
                </div>
                


                </div>
                <div class="col s12 m8 l8"> 
                <p class="text-primary">field:</p>
                <div class="input-field inline">
                    <input id="email_inline" type="email" class="validate"/>
                    <label for="email_inline">Email</label>
                    <span class="helper-text" data-error="wrong" data-success="right">Helper text</span>
                </div>  
                </div>
                </div>
                </div>
                </div>

                <div class="col s12 m4 l4"> 
                <div className="box-space"> 
                <div class="card-white2">
                <div class="col s12 m8 l8">
               
               

                </div>
                <div class="col s12 m6 l6"> 
              

                </div>
                </div>
                </div>
                </div>

                
                <div class="col s12 m4 l4"> 
                <div className="box-space"> 
                <div class="card-white3">
                <div class="col s12 m6 l6"> 
                <p>sdfsdf</p>
                </div>
                <div class="col s12 m6 l6"> 
                <p>sdfsdf</p>
                </div>
                </div>
                </div>
                </div>

                </div>

               
                </div>
                </li>
                <li class="tab2 bg-six"> 
                <div class="collapsible-header bg-six">
                <div className="flex">
                <h3 className="font-bold fl text-primary title-4 pad30">Análisis fisicoquímicos</h3>
                </div>
                </div>
                <div class="collapsible-body">
                <div class="mleft30">
                <div className="flex">
                <p class="title-5 text-primary">Selecciona un recuadro para adjuntar documentos con los resultados de los análisis, puedes pasar a la siguiente sección y terminar este parte posteriormente.</p>
                </div>
                </div>
                </div>
                </li>

                <li class="tab3 bg-seven"> 
                <div class="collapsible-header bg-seven">
                <div className="flex">
                <h3 className="font-bold fl text-primary title-4 pad30">Análisis fitopatológicos</h3>
                </div>
                </div>
                <div class="collapsible-body">
                <div class="mleft30">
                
                </div>
               
                </div>
                </li>
                </ul> 
                </div>
                </div>                            
                <div>
                <Link to="/parametro-medicion">
                <div class="superponer">
                <img src={next1} className="next-1"  />
                </div>
                </Link>
                </div> 

                </div>

            </>
        )
    
    } 
}

export default withRouter(Diagnosticos) 