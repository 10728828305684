import React, {Component} from 'react';
import './App.css';
import { BrowserRouter, Router , Switch, Route } from 'react-router-dom';
import {connect} from 'react-redux'

import Login from "./pages/Login";
import Home from "./pages/Auth/Home";
import PrivateRoute from "./components/PrivateRoute";
import Usuarios from "./pages/Auth/Usuarios";
import Nutricion from "./pages/Auth/Nutricion";
import Nutrientes from "./pages/Auth/Nutrientes";
import AppPlagas from "./pages/Auth/AppPlagas";
import Plagas from "./pages/Auth/Plagas";
import CultivoTemporada from "./pages/Auth/Cultivo";
import Productos from "./pages/Auth/Productos";
import Ordenes from "./pages/Auth/Ordenes";
import ReporteLab from "./pages/Auth/RepoProdutorLab"
import ReporteAdmin from "./pages/Auth/RepoProdutorAdmin"
import ReporteProd from "./pages/productor/RepoProdutorProd"
import Agrocostos from "./pages/productor/Agrocostos"
import OrdenesProd from "./pages/productor/Ordenes"
import RefNutricion from "./pages/Auth/RefNutricion"
import Dolar from "./pages/Auth/Dolar"
import ParametrosHumedad from "./pages/Auth/ParametrosHumedad"

import AltaProd from "./pages/ingenieros/AltaProductor";
import CrearCita from "./pages/ingenieros/CrearCita";
import AltaProdMap from "./pages/ingenieros/AltaProductorMap";
import InfoCultivo from "./pages/ingenieros/InfoCultivo";
import SistemaRiego from "./pages/ingenieros/SistemaRiego";
import ParaMedicion from "./pages/ingenieros/ParaMedicion";
import AgendaTecnico from "./pages/ingenieros/AgendaTecnico";
import QR from "./pages/ingenieros/QR";
import Diagnosticos from "./pages/diagnostico/Diagnosticos";

class App extends Component {

    componentDidMount() {
  
    }

    render() {
        return (
            <BrowserRouter forceRefresh={true}>
                <Switch>
                    <Route exact path='/'>
                        <Login />
                    </Route>
                    <Route exact path='/agenda-tecnico'>
                        <AgendaTecnico />
                    </Route>
                    <Route exact path='/diagnosticos'>
                        <Diagnosticos />
                    </Route>
                    <PrivateRoute exact path='/home'>
                        <Home />
                    </PrivateRoute>
                    <PrivateRoute exact path='/usuarios'>
                        <Usuarios /> 
                    </PrivateRoute>
                    <PrivateRoute exact path='/nutricion'>
                        <Nutricion />
                    </PrivateRoute>                    
                    <PrivateRoute exact path='/nutrientes'>
                        <Nutrientes />
                    </PrivateRoute>
                    <PrivateRoute exact path='/referencia-nutricion-cultivo'>
                        <RefNutricion/>
                    </PrivateRoute>
                    <PrivateRoute exact path='/aplicacion-plagas-enfermedades'>
                        <AppPlagas />
                    </PrivateRoute>
                    <PrivateRoute exact path='/plagas-enfermedades'>
                        <Plagas />
                    </PrivateRoute>
                    <PrivateRoute exact path='/cultivo-temporadas'>
                        <CultivoTemporada />
                    </PrivateRoute>
                    <PrivateRoute exact path='/parametros-humedad'>
                        <ParametrosHumedad />
                    </PrivateRoute>
                    <PrivateRoute exact path='/productos-nutricion'>
                        <Productos />
                    </PrivateRoute>
                    <PrivateRoute exact path='/productos'>
                        <Productos />
                    </PrivateRoute>
                    <PrivateRoute exact path='/ordenes'>
                        <Ordenes />
                    </PrivateRoute>
                    <PrivateRoute exact path='/agrocostos'>
                        <Agrocostos />
                    </PrivateRoute>                    
                    <PrivateRoute exact path='/ordenes-productor'>
                        <OrdenesProd />
                    </PrivateRoute>
                    <PrivateRoute exact path='/reporte-lab/:id'>
                        <ReporteLab />
                    </PrivateRoute>
                    <PrivateRoute exact path='/reporte-admin/:id'>
                        <ReporteAdmin />
                    </PrivateRoute>                     
                    <PrivateRoute exact path='/precio-dolar'>
                        <Dolar />
                    </PrivateRoute>                  
                    <PrivateRoute exact path='/reporte-prod/:id'>
                        <ReporteProd />
                    </PrivateRoute>
                    <PrivateRoute exact path='/altaproductor'>
                        <AltaProd />
                    </PrivateRoute>
                    <PrivateRoute exact path='/crearcita'>
                        <CrearCita />
                    </PrivateRoute>
                    <PrivateRoute exact path='/qr'>
                        <QR />
                    </PrivateRoute>
                    <PrivateRoute exact path='/altaproductor-map'>
                        <AltaProdMap />
                    </PrivateRoute>
                    <PrivateRoute exact path='/informacion-cultivo'>
                        <InfoCultivo />
                    </PrivateRoute>
                    <PrivateRoute exact path='/sistema-riego'>
                        <SistemaRiego />
                    </PrivateRoute>
                    <PrivateRoute exact path='/parametro-medicion'>
                        <ParaMedicion />
                    </PrivateRoute>
                </Switch>
            </BrowserRouter>
        );
    }

}

export default App;
