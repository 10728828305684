import React from 'react';
import '../styles.css';
class TabNav extends React.Component {
  render() {
    
    let arr= this.props.tabs;
    //console.log(arr)
    //console.log('a',arr[0],'b',arr[1],'c',arr[2] )
    
    const orden =arr[1];
    const idn =arr[2];
    const ordenn =arr[3];
    const idp =arr[4];
    const ordenp =arr[5];
    const active = (orden === this.props.selected ? 'activetab ' : '' );
    return (
        <div >
            <ul className="flex">
                <li className="p-5" >
                    <a className={"tab-link text-white  rp-select rounded-full p-3 " + active }>
                    { orden }
                    </a>
                </li>
                {ordenn === null ? '' :
                    <li className="p-5" >
                        <a title="Oprime para Abrir el Siguiente Reporte" className="tab-link text-white  rp-select rounded-full p-3" onClick={ () => this.props.setSelected(idn) }>
                        { ordenn }
                        </a>
                    </li>
                }
                {ordenp === null ? '' :  
                    <li className="p-5" >
                        <a title="Oprime para Abrir el Anterior Reporte" className="tab-link text-white  rp-select rounded-full p-3" onClick={ () => this.props.setSelected(idp) }>
                        { ordenp }
                        </a>
                    </li>
                }
            {/*
                this.props.tabs.map(tab => {
                    const active = (tab === this.props.selected ? 'activetab ' : '' );
                    console.log(tab);
                    return (
                    <li className="p-5" key={ tab }>
                        <a title="Oprime para Abrir" className={"tab-link text-white  rp-select rounded-full p-3 " + active } onClick={ () => this.props.setSelected(tab) }>
                        { tab[0] }
                        </a>
                    </li>
                    );
                })
            */}  
            </ul>
            { this.props.children }
        </div>
    );
  }
}
export default TabNav;