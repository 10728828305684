import React, {Component} from 'react';
import './HomeAdmin.css';
import { Doughnut } from 'react-chartjs-2';
import {connect} from 'react-redux';
import {url_api} from "../../constants";
import {url} from "../../constants";
import productor from '../../assets/img/menu/productor@2x.png';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
class HomeAdmin extends Component{
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            user: [],
            last: {},
            administrador: 0,
            ingeniero_de_Campo: 0,
            productor: 0,
            chofer: 0,
            quimico: 0,
            cobranza: 0,
            administrativo: 0,
        };
    
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount= async () => {
        const res = await fetch(`${url_api}users`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const user = await res.json();  
        if(!user.detail){
            user.map((x) => {
                if(x.is_administrador === true) { this.state.administrador= this.state.administrador+1}
                if(x.is_ingeniero_de_Campo === true) {this.state.ingeniero_de_Campo = this.state.ingeniero_de_Campo+1} 
                if(x.is_productor === true) {this.state.productor = this.state.productor+1} 
                if(x.is_chofer === true) {this.state.chofer = this.state.chofer+1} 
                if(x.is_quimico === true) {this.state.quimico = this.state.quimico+1} 
                if(x.is_cobranza === true) {this.state.cobranza = this.state.cobranza+1} 
                if(x.is_administrativo === true) {this.state.administrativo = this.state.administrativo+1}  

            })
            
            const item = user[user.length-1];
            this.setState({
                user: user, 
                last:item
            });
        }       
    }
  

    render() {
        
        
        const usuario=this.state.user.length;
        const data = {
            labels: [
                'Administrador',
                'Ingeniero de Campo',
                'Productor',
                'Chofer',
                'Laboratorio',
                'Cobranza',
                'Administración',
                
            ],
            datasets: [{
              data: [
                  this.state.administrador, 
                  this.state.ingeniero_de_Campo,
                  this.state.productor,
                  this.state.chofer,
                  this.state.quimico, 
                  this.state.cobranza, 
                  this.state.administrativo
                ],
              backgroundColor: [
              '#A6D66B',
              '#044C3B',
              '#679e5a',
              '#36f44a',
              '#aef2b7',
              '#1e7a0d',
              '#c0fa2a'
              ]
            }]
          };
        return (
                <div className="grid md:grid-cols-12 grid-cols-1 gap-32">
                    <div className="col-span-3 ">
                        <h1 className="font-bold text-primary-perfil pb-8">Perfil del Usuario</h1>
                        <div className="bg-secondary rounded-full py-8 px-12  text-center">
                            <img  alt="usuarios"  src={`${url}${this.state.auth.user.foto}`} className="img-perfil"/>
                            <h3 className="text-white pt-10 pb-2">Nombre</h3>
                            <p className="text-bold">{this.state.auth.user.first_name } {this.state.auth.user.last_name }</p>
                            <h3 className="text-white pt-10 pb-2">Correo</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.email }</p>
                            <h3 className="text-white pt-10 pb-2">Numero</h3>
                            {/*<p className="text-white  text-bold">{this.state.auth.user.telefono }</p>
                            <h3 className="text-white pt-10 pb-2">Cargo</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.cargo }</p>
                            <h3 className="text-white pt-10 pb-2">Estado</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.estado }</p>
                            <h3 className="text-white pt-10 pb-2">Contraseña</h3>
                            <p className="text-white  text-bold pb-2">{this.state.user.password } 
                                <NavLink to="/usuarios" className="inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none" ><i className='fas fa-pen'></i></NavLink>
                            </p>*/}
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 col-span-4 xl:col-span-8 xl:grid-cols-2  gap-4"> 
                        <div>
                            <h1 className="font-bold text-primary-reciente pb-8 float-none">Reciente </h1>
                            
                            <div className="bg-white  grid grid-cols-2 rounded-lg py-12 px-8 mb-6 text-center">
                                <div className="text-left text-primary-new-user ">
                                    <h3 className="font-bold text-secundary-reciente"> Nuevo Usuario</h3>
                                    <p className="text-secondary-n-user py-2">{this.state.last.first_name} {this.state.last.last_name }</p>
                                    <p className="text-secondary-n-user pb-2">{this.state.last.cargo }</p>
                                    <p className="text-secondary-n-user ">{this.state.last.telefono }</p>
                                </div>
                                <div>
                                      {this.state.last.foto === null  ? 
                                            <img src={productor} alt="foto"  className="img-perfil"/>:
                                            <img className="img-perfil" alt="foto"  src={`${url}${this.state.last.foto}`}/>}
                                </div>
                            </div>
                            <div className="bg-white  grid grid-cols-2 rounded-lg py-12 px-8 mb-6 text-center">
                                <div className="text-left text-primary-new-user ">
                                    <h3 className="font-bold text-secundary-reciente">Cambiaste tu Perfl</h3>
                                    <p className="text-secondary-n-user py-2">{this.state.last.first_name} {this.state.last.last_name }</p>
                                    <p className="text-secondary-n-user pb-2">{this.state.last.cargo }</p>
                                    <p className="text-secondary-n-user ">{this.state.last.telefono }</p>
                                </div>
                                <div>
                                    {this.state.last.foto === null  ? 
                                        <img src={productor} alt="foto"  className="img-perfil"/>:
                                        <img className="img-perfil" alt="foto"  src={`${url}${this.state.last.foto}`}/>}
                                </div>
                            </div>
                        </div>
                        <div className="xl:pt-16 p-0">
                            <div className="bg-white rounded-lg p-5 mb-6 text-center">
                                    
                                    <Doughnut data={data}
                                        width={100}
                                        height={50}
                                        options={{
                                            title:{
                                            display:true,
                                            text:usuario+" Usuarios",
                                            fontSize:20,
                                            fontColor: '#A6D66B'
                                            },
                                            legend:{
                                            display:true,
                                            position:'right',
                                            fontColor: '#A6D66B'
                                            }
                                        }} 
                                        />
                                
                            </div>
                        </div>
                        {/*<div className="xl:pt-16 p-0">
                            <div className="bg-four rounded-lg p-10 ">
                                <h3 className="font-bold text-secundary-reciente">Cambios fertilización</h3>
                                
                            </div>
                                    </div>*/}
                    </div>   
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}

export default connect(mapStateToProps)(HomeAdmin);