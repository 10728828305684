import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '10%',
      left                  : '20%',
      right                 : '20%',
      bottom                : '20%',   
      background            : '#90ec90'
    }
  };
class RefNutricion extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            
            ph: '',
            cultivo_id:'',
            
            establecimiento_20dias_DDP: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },

            vegetativa: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },
            
            diferenciacion: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },

            productiva: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },
            
            en_gap: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },

            segunda_productiva: {
                CE: '',
                NO3: '',
                NH4: '',
                H2PO4: '',
                K: '',
                CA2: '',
            },

               
            id:'',
            editing: false,
            item: '',
            list1: [],
            list2: [],
            list3: [],
            list4: [],
            list5: [],
            list6: [],
            open: 0,
            cultivo: '', 
            cultivos: [],
            control: false,
            
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}referencia_nutricion_cultivo/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`
            },
        });
        const apps = await res.json();  
        if(!apps.detail){
            this.setState({apps:apps}) 
            
            fetch(`${url_api}cultivos/`, options)
            .then(res => res.json())
            .then(cultivos => this.setState({cultivos}))
        }
        

       

    }
      

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
        console.log({[nam]: val});
    }

    
    
    handleInputChange(field, e){
        const id =e.target.id;
        if(id === '1'){
            let establecimiento_20dias_DDP = this.state.establecimiento_20dias_DDP; 
            establecimiento_20dias_DDP[field] = e.target.value;
            this.setState({establecimiento_20dias_DDP});
            console.log({establecimiento_20dias_DDP});
        }
        if(id === '2'){
            let vegetativa = this.state.vegetativa; 
            vegetativa[field] = e.target.value;
            this.setState({vegetativa});
            console.log({vegetativa});
        } 
        if(id === '3'){
            let diferenciacion = this.state.diferenciacion; 
            diferenciacion[field] = e.target.value;
            this.setState({diferenciacion});
            console.log({diferenciacion});
        }   
        if(id === '4'){
            let productiva = this.state.productiva; 
            productiva[field] = e.target.value;
            this.setState({productiva});
            console.log({productiva});
        } 
        if(id === '5'){
            let en_gap = this.state.en_gap; 
            en_gap[field] = e.target.value;
            this.setState({en_gap});
            console.log({en_gap});
        } 
        if(id === '6'){
            let segunda_productiva = this.state.segunda_productiva; 
            segunda_productiva[field] = e.target.value;
            this.setState({segunda_productiva});
            console.log({segunda_productiva});
        }     
    }
   

    //crear y editar
    submit= async (e) =>{
        e.preventDefault();
        if (this.state.editing===false) {
           const res = await fetch(`${url_api}referencia_nutricion_cultivo/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    cultivo_id: this.state.cultivo_id,
                    ph: this.state.ph,
                    establecimiento_20dias_DDP: this.state.establecimiento_20dias_DDP,
                    vegetativa: this.state.vegetativa,
                    diferenciacion: this.state.diferenciacion,
                    productiva: this.state.productiva,
                    en_gap: this.state.en_gap,
                    segunda_productiva: this.state.segunda_productiva
                    
                })
            }) 
            
            const data = await res.json();
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Datos...',
                      showConfirmButton: false,
                      timer: 2000,                      
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente la Referencia Nutricion',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                      cancelButton: 'cancelbutton-class'
                                  },
                              })                        
                          }
                  })
                  this.componentDidMount();

                    this.setState({
                        ph: '',
                        cultivo_id:'',
                        establecimiento_20dias_DDP: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        vegetativa: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },            
                        diferenciacion: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        productiva: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        en_gap: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        segunda_productiva: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                    })

                    
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: 'Revisa tus Datos',
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                          cancelButton: 'cancelbutton-class'
                      },
                    })
              } 
        } else {
            const id=this.state.id;

            const res = await fetch(`${url_api}referencia_nutricion_cultivo/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    cultivo_id: this.state.cultivo_id,
                    ph: this.state.ph,
                    establecimiento_20dias_DDP: this.state.establecimiento_20dias_DDP,
                    vegetativa: this.state.vegetativa,
                    diferenciacion: this.state.diferenciacion,
                    productiva: this.state.productiva,
                    en_gap: this.state.en_gap,
                    segunda_productiva: this.state.segunda_productiva
                }),
              })
              const data = await res.json();
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Actualizando Datos...',
                      showConfirmButton: false,
                      timer: 2000,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Actualizo Correctamente la Referencia Nutricion',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                      cancelButton: 'cancelbutton-class'
                                  },
                              })                        
                          }
                  })
                  this.componentDidMount();

                    this.setState({
                        ph: '',
                        cultivo_id:'',
                        establecimiento_20dias_DDP: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        vegetativa: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },            
                        diferenciacion: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        productiva: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        en_gap: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                        segunda_productiva: {
                            CE: '',
                            NO3: '',
                            NH4: '',
                            H2PO4: '',
                            K: '',
                            CA2: '',
                        },
                    })

                    
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: 'Revisa tus Datos',
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                          cancelButton: 'cancelbutton-class'
                      },
                    })
              } 
            this.state.editing = false;
            this.state.id= "";
        }     
        

        
         

    }

    // muetra datos para editar
    editNutricion = async (id) => {
        this.state.control = true;
        const res = await fetch(`${url_api}referencia_nutricion_cultivo/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        const list1 = data.establecimiento_20dias_DDP;
        const list2 = data.vegetativa;
        const list3 = data.diferenciacion;
        const list4 = data.productiva;
        const list5 = data.en_gap;
        const list6 = data.segunda_productiva;
        const cultivo = data.cultivo;
        
        this.setState({
            ph: data.ph,
            cultivo_id: data.cultivo.id,
            establecimiento_20dias_DDP: data.establecimiento_20dias_DDP,
            vegetativa: data.vegetativa,
            diferenciacion: data.diferenciacion,
            productiva: data.productiva,
            en_gap: data.en_gap,
            segunda_productiva: data.segunda_productiva,
        })
        /*let list = this.state.plings.filter(item_ing => item_ing.plagas_enfermedades_id == data.id);*/

        
    };

    //elimina datos
    deleteNutricion = async (id) => {
        this.state.control = true;
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}referencia_nutricion_cultivo/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                }).then(res => {
                    console.log(res.status);
                    if(res.status === 500){
                        MySwal.fire({
                            icon: 'warning',
                            title: 'No se puede Eliminar!',
                            text: 'La Referencia Nutricion esta Siendo Utilzada por otro Componente',
                            showConfirmButton: true,
                            buttonsStyling: false,
                            customClass: {
                                title:'title-class',
                                popup:'popup-class',
                                confirmButton: 'confirmbutton-class',
                            },
                          });
                    }else{
                         MySwal.fire({
                            icon: 'Success',
                            title: 'Eliminado!',
                            text: 'El Registro ha sido Eliminado Correctamente.',
                            showConfirmButton: true,
                            buttonsStyling: false,
                            customClass: {
                                title:'title-class',
                                popup:'popup-class',
                                confirmButton: 'confirmbutton-class',
                                cancelButton: 'cancelbutton-class'
                            },
                          });
                    }
                   
                })
                this.componentDidMount();
                
            }
            this.componentDidMount();
          })
    };

    //muestra elemento
    getNutricion = async (id) => {
        const res = await fetch(`${url_api}referencia_nutricion_cultivo/${id}/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const item = await res.json(); 
        
        //let list = this.state.nutings.filter(nuting => nuting.aplicacion_nutricion == item.id);
        const list1 = item.establecimiento_20dias_DDP;
        const list2 = item.vegetativa;
        const list3 = item.diferenciacion;
        const list4 = item.productiva;
        const list5 = item.en_gap;
        const list6 = item.segunda_productiva;
        const cultivo = item.cultivo;
        /*list.map((x) => {
            let listit = this.state.products.filter(product => product.id == x.producto);
               
            let id= x.producto;
            x.productob = listit ;  

        })*/
        this.setState({item: item, 
            list1: list1,
            list2: list2, 
            list3: list3,
            list4: list4,
            list5: list5,
            list6: list6,
            open: id, cultivo:cultivo});   
        if(this.state.control === false){
            this.setState({ showModal: true });
        }else{
            this.state.control = false;
        }
        console.log(list1);
      };
    
    handleOpenModal () {
        this.setState({ showModal: true });
    }
      
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render() {
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        
    
        
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Referencia de Nutrición de Cultivos</h2>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="bg-white col-span-12 rounded">
                            <div className="form bg-white rounded shadow-md py-8">
                                <h3 className="font-bold text-primary m-4">Crear Referencia de Nutrición de Cultivos</h3>
                                <form  className="m-6" onSubmit={this.submit}>
                                    <div className=" form bg-white rounded grid-cols-12 overflow-hidden flex">
                                    
                                        <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">Tipo de cultivo</label>
                                                <select id="cultivo_id" name="cultivo_id" value={this.state.cultivo_id}  onChange={this.handleChange} className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res"
                                                title="Favor de Seleccionar un Cultivo de la Lista">
                                                    <option value="">Selecciona un Cultivo</option>
                                                    {this.state.cultivos.map((cultivo, i)=> {
                                                        return (
                                                        <option key={i} value={cultivo.id}>{cultivo.nombre}</option>
                                                        );
                                                    })}
                                                </select>                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                                <label htmlFor="name" className="self-center nut-title-res">PH</label>
                                                <input
                                                    id="ph"
                                                    name="ph"
                                                    type="text"
                                                    value={this.state.ph} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                    placeholder="PH ej. 1.5"
                                                    required
                                                    title="Favor de Agregar el PH. ej. 1.5"
                                                />                                                
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center ">
                                    
                                        <table  className="form bg-white rounded grid-cols-12 overflow-hidden inline ">
                                            <thead>
                                                <tr className="table-res">
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium"></th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">CE</th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">NO3</th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">NH4</th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">H2PO4</th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">K</th>
                                                    <th className="w-full lg:w-auto p-3 nut-title-res my-1 mx-2 font-medium">CA2</th>
                                                </tr>
                                            </thead>
                                            <tbody className="flex-col items-center justify-between overflow-y-scroll w-full">
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>Establecimiento 20 dias DDP</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para CE. ej. -"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para NO3. ej. -"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para NH4. ej. -"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para H2PO4. ej. -"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para K. ej. -"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="1"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.establecimiento_20dias_DDP["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. - "
                                                                required
                                                                title="Favor de Agregar signo + ó - para CA2. ej. -"
                                                            />
                                                        </td>   
                                                            
                                                    </tr> 
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>Vegetativa</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.vegetativa["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. 1.1-1.5 "
                                                                required
                                                                title="Favor de Agregar dos Datos Separados por un Guion(-) para CE. ej. 1.1-1.5"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.vegetativa["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NO3. ej. 4"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.vegetativa["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NH4. ej. 4"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.vegetativa["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para H2PO4. ej. 4"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.vegetativa["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para K. ej. 4"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="2"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.vegetativa["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para CA2. ej. 4"
                                                            />
                                                        </td>   
                                                            
                                                    </tr>  
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>Diferenciación</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.diferenciacion["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. 1.1-1.5 "
                                                                required
                                                                title="Favor de Agregar dos Datos Separados por un Guion(-) para CE. ej. 1.1-1.5"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.diferenciacion["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NO3. ej. 4"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.diferenciacion["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NH4. ej. 4"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.diferenciacion["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para H2PO4. ej. 4"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.diferenciacion["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para K. ej. 4"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="3"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.diferenciacion["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para CA2. ej. 4"
                                                            />
                                                        </td>   
                                                            
                                                    </tr>  
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>Productiva</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.productiva["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. 1.1-1.5 "
                                                                required
                                                                title="Favor de Agregar dos Datos Separados por un Guion(-) para CE. ej. 1.1-1.5"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.productiva["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NO3. ej. 4"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.productiva["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NH4. ej. 4"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.productiva["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para H2PO4. ej. 4"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.productiva["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para K. ej. 4"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="4"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.productiva["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para CA2. ej. 4"
                                                            />
                                                        </td>   
                                                            
                                                    </tr> 
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>En gap</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.en_gap["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. 1.1-1.5 "
                                                                required
                                                                title="Favor de Agregar dos Datos Separados por un Guion(-) para CE. ej. 1.1-1.5"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.en_gap["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NO3. ej. 4"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.en_gap["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NH4. ej. 4"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.en_gap["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para H2PO4. ej. 4"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.en_gap["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para K. ej. 4"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="5"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.en_gap["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para CA2. ej. 4"
                                                            />
                                                        </td>   
                                                            
                                                    </tr> 
                                                    <tr className="border-none" >
                                                        <td className="nut-table-ing">
                                                            <p>Segunda Productiva</p>
                                                        </td> 
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="CE"
                                                                type="text"
                                                                value={this.state.segunda_productiva["CE"]} 
                                                                onChange={this.handleInputChange.bind(this, "CE")}
                                                                className="self-center nut-title-res validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CE ej. 1.1-1.5 "
                                                                required
                                                                title="Favor de Agregar dos Datos Separados por un Guion(-) para CE. ej. 1.1-1.5"
                                                            />  
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="NO3"
                                                                type="text"
                                                                value={this.state.segunda_productiva["NO3"]} 
                                                                onChange={this.handleInputChange.bind(this, "NO3")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NO3 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NO3. ej. 4"
                                                            /> 
                                                        </td>  
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="NH4"
                                                                type="text"
                                                                value={this.state.segunda_productiva["NH4"]} 
                                                                onChange={this.handleInputChange.bind(this, "NH4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="NH4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para NH4. ej. 4"
                                                            /> 
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="H2PO4"
                                                                type="text"
                                                                value={this.state.segunda_productiva["H2PO4"]} 
                                                                onChange={this.handleInputChange.bind(this, "H2PO4")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="H2PO4 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para H2PO4. ej. 4"
                                                            />
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="K"
                                                                type="text"
                                                                value={this.state.segunda_productiva["K"]} 
                                                                onChange={this.handleInputChange.bind(this, "K")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="K ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para K. ej. 4"
                                                            />  
                                                        </td>   
                                                        <td className="nut-table-ing">
                                                            <input
                                                                id="6"
                                                                name="CA2"
                                                                type="text"
                                                                value={this.state.segunda_productiva["CA2"]} 
                                                                onChange={this.handleInputChange.bind(this, "CA2")}
                                                                className="validate w-full border-0 text-green-500 focus:outline-none nut-value-res"
                                                                placeholder="CA2 ej. 4 "
                                                                required
                                                                title="Favor de Agregar un dato para CA2. ej. 4"
                                                            />
                                                        </td>   
                                                            
                                                    </tr> 
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="p-4 btn-box flex">
                                        <div>
                                            <button type="submit" className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none">{this.state.editing ? "Actualizar Referencia" : "Guardar Refernecia"}</button>
                                        </div>
                                    </div>
                                </form>
                                <Modal 
                                    isOpen={this.state.showModal}
                                    ariaHideApp={false}
                                    contentLabel="Modal"
                                    onRequestClose={this.handleCloseModal}
                                    style={customStyles}
                                    >
                                    <div  className="flex justify-between bg-white">
                                        <h3 className="font-bold text-primary m-4">Detalles Referencia de Nutrición de Cultivos</h3>
                                        <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 m-4" onClick={this.handleCloseModal}>Cerrar</button>
                                    </div>   
                                    <div className="shadow-md  bg-white">
                                        
                                        
                                        <div className="border-b-2 border-green-400 form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className="flex"><h6 className=" nutcul-title-res my-1 mx-2">Cultivo: </h6>
                                            <p className="nutcul-value-res my-1 mx-2">{this.state.cultivo.nombre}</p></div>
                                            <div className="flex"><h6 className=" nutcul-title-res my-1 mx-2">Fecha:</h6>
                                            <p className="nutcul-value-res my-1 mx-2">{this.state.item.fecha}</p></div>
                                            <div className="flex"><h6 className=" nutcul-title-res my-1 mx-2">PH:</h6>
                                            <p className="nutcul-value-res my-1 mx-2">{this.state.item.ph}</p></div>
                                        </div>

                                        <div className="form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <table className="nut-cult">
                                                <thead>
                                                    <tr className="table-res">
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium"></th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">CE</th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">NO3</th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">NH4</th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">H2PO4</th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">K</th>
                                                    <th className="w-full lg:w-auto p-3 nutcul-title-res my-1 mx-2 text-center font-medium text-lg">CA2</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="flex-col items-center justify-between overflow-y-scroll w-full">
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                <p>Vegetativa</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list1.CA2} 
                                                            </td>
                                                        </tr>  
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                <p>Establecimiento 20 dias DDP</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list2.CA2} 
                                                            </td>
                                                        </tr> 
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                <p>Diferenciación</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list3.CA2} 
                                                            </td>
                                                        </tr> 
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                <p>Productiva</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list4.CA2} 
                                                            </td>
                                                        </tr> 
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                <p>En gap</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list5.CA2} 
                                                            </td>
                                                        </tr> 
                                                        <tr className="table-res bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 px-6 lg:mb-0">
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                                                                <p>Segunda Productiva</p>
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.CE} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.NO3} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.NH4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.H2PO4} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.K} 
                                                            </td>
                                                            <td className="nutcul-value-res w-full lg:w-auto py-6 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                                                                {this.state.list6.CA2} 
                                                            </td>
                                                        </tr> 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Modal>  
                            </div>
                        </div>
                        <div className="col-span-12 mb-20">
                            <h3 className="font-bold text-primary m-4">Lista de Referencia de Nutrición de Cultivos</h3><br></br>
                            <div className="col-span-12 grid grid-cols-4 gap-4">
                                {this.state.apps.map((app, i)=> {
                                    return (
                                        <a title="Oprime para Ver los Detalles"  onClick={(e) => this.getNutricion(app.id)} key={app.id} id={app.id} className={`cursor-pointer bg-white rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32 ${this.state.open === app.id ? 'activeclass' : ''}`}>
                                            <div  className="col-span-6 ">
                                                <h6 className="nut-title my-1">Cultivo:</h6>
                                                <p className="nut-value my-1">{app.cultivo.nombre} </p>
                                                <h6 className="nut-title my-1 mt-2">Fecha:</h6>
                                                <p className="nut-value nmy-1">{app.fecha}</p>
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                    <button onClick={(e) => this.editNutricion(app.id)} className=" z-50 inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                        <i className="material-icons">edit</i>
                                                    </button>
                                                    <button onClick={(e) => this.deleteNutricion(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                        <i className="material-icons">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                        
                                    );
                                })}    
                            </div>
                        </div>

                        
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(RefNutricion))