import React from "react";
import {connect} from 'react-redux';

import "./Navigation.css";
import menu from "../assets/img/icon-menu.svg";
import logo from '../assets/img/logo-white.svg';

import {authActions} from "../rcrx/actions";
import {withRouter} from "react-router-dom";
import {NavLink} from "react-router-dom";

import user from '../assets/img/icons/user.svg'
import usergreen from '../assets/img/icons/user-green.svg'
import calendar from '../assets/img/icons/calendar.svg'

import {url_api} from "../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
class NavigationAprod extends React.Component {
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            navVisible: false
            
        };
        this.logout = this.logout.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
    }
    componentDidMount= async () => {
          
        console.log(this.props)
        
        /*const res = await fetch(`${url_api}`, {
            headers: {
                'Authorization': `Token ${this.props.token}`
            },
        });
            const api = await res.json();
            
        if(api.detail){
            
            if(api.detail==="Token inválido."){
                let timerInterval
                MySwal.fire({
                    title: 'Cerrando Sesión...',
                    text: 'Tu Sesión Caduco, Vuele a Iniciarla',
                    showConfirmButton: false,
                    timer: 2000,
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    if (
                        result.dismiss === MySwal.DismissReason.timer
                    ) {
                        this.logout();
                        //document.getElementById("btnexit").click();                       
                    }
                })
                
            }
        }*/
    }
    logout() {
        const {dispatch} = this.props;
        dispatch(authActions.logout());
        this.props.history.push("/");
    }

    toggleNav() {
        this.setState({navVisible: !this.state.navVisible})
    }

    render() {
        const {active} = this.props;
        const {step1} = this.props;
        const ruta =this.props.location.pathname; 

        return (
            <>
                <div className="container-fluid bg-secondary items-center justify-between navb pr-8">
                    <div className="row pad10">
                        <div className="col s2 m2 l2">
                            <div className="flex mtop15">
                                <img src={menu} className="w-10 mr-4" onClick={this.toggleNav}/>
                            </div>
                        </div>

                        <div className="col s6 m6 l6">
                            <div className="text-white mtop15">
                                <p className="font18">{this.props.title}</p>
                            </div>
                        </div>

                        <div className="col s4 m4 l4">
                            <img src={logo} className="logo fr mtop15"/>
                        </div>

                    </div>
                </div>

                <aside
                    className={`transform top-0 left-0 w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${this.state.navVisible ? "translate-x-0" : "-translate-x-full"}`}>
                    <img src={logo} className="mx-auto my-2 p-2 pb-8" onClick={this.toggleNav}/>
                    <NavLink to="/home" className="text-center text-white p-2"
                             activeClassName="active bg-active-menu">Home</NavLink>
                    <NavLink to="/usuarios" className="text-center text-white p-2"
                             activeClassName="active bg-active-menu">Usuarios</NavLink>
                    <NavLink to="/nav" className="text-center text-white p-2"
                             activeClassName="active bg-active-menu">Page</NavLink>
                </aside>
                {ruta  === '/altaproductor'  ?
                    <div>
                    { this.props.showProgress &&
                        <div className="card-progress">
                            <ul className="menu-progress">
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 1 ? 'active' : ''}`}>
                                            <img src={active === 1 ? user: usergreen} alt="user" color={active === 1 ? 'white' : 'green'}/>
                                        </button>

                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 2 ? 'active' : ''}`}>
                                            <span className={active === 2 ? "gps": "gps-green"} alt="gps" color={active === 2 ? 'white' : 'green'}></span>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 3 ? 'active' : ''}`}>
                                            <span className={active === 3 ? "plant": "plant-green"} alt="plant" color={active === 3 ? 'white' : 'green'}></span>
                                            {/*<button className={`outline-progress ${active === 3 ? 'active' : ''}`}><span className="plant"></span></button>*/}
                                        </button>                            
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 4 ? 'active' : ''}`}>
                                            <span className={active === 4 ? "water": "water-green"} alt="water" color={active === 4 ? 'white' : 'green'}></span>
                                            {/*<span className="water"></span>*/}
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 5 ? 'active' : ''}`}>
                                            <span className={active === 5 ? "botin": "botin-green"} alt="botin" color={active === 5 ? 'white' : 'green'}></span>
                                        {/* <span className="botin"></span>*/}
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 6 ? 'active' : ''}`}><span className="ensayo"></span></button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className={`outline-progress ${active === 7 ? 'active' : ''}`}><span className="lupa"></span></button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className="outline-progress"><span className="left-arrow"></span></button>
                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className="outline-progress"><span className="logout"></span></button>
                                    </div>
                                </li>
                                <li>
                                </li>
                            </ul>
                        </div>
                        }

                        { this.props.showProgressAdd &&
                            <div className="card-progress">
                                <ul className="menu-progress">

                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 1 ? 'active' : ''}`}><span className="plant"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 2 ? 'active' : ''}`}><span className="water"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 3 ? 'active' : ''}`}><span className="botin"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 4 ? 'active' : ''}`}><span className="ensayo"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 5 ? 'active' : ''}`}><span className="lupa"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className="outline-progress"><span className="left-arrow"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className="outline-progress"><span className="logout"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    : <div></div>
                }
                {ruta === '/crearcita'  ?
                    <div>
                    { this.props.showProgress &&
                        <div className="card-progress">
                            <ul className="menu-progress">
                                <li>
                                    <div className="box-space-tiny">
                                        <button className="outline-progress active">
                                            <img src={calendar} alt="calendar" color='green'/>
                                        </button>

                                    </div>
                                </li>
                                <li>
                                    <div className="box-space-tiny">
                                        <button className="outline-progress"><span className="logout"></span></button>
                                    </div>
                                </li>
                                <li>
                                </li>
                            </ul>
                        </div>
                        }

                        { this.props.showProgressAdd &&
                            <div className="card-progress">
                                <ul className="menu-progress">

                                    <li>
                                        <div className="box-space-tiny">
                                            <button className={`outline-progress ${active === 1 ? 'active' : ''}`}><span className="plant"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className="outline-progress"><span className="left-arrow"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="box-space-tiny">
                                            <button className="outline-progress"><span className="logout"></span></button>
                                        </div>
                                    </li>
                                    <li>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                    : <div></div>
                }

            </>
        )
    }
}


const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};
export default withRouter(connect(mapStateToProps)(NavigationAprod)) 

