import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

import infoprod from "../../assets/img/partials/info-prod.png";
import next1 from "../../assets/img/partials/next-1.png";
import ubicacionExample from "../../assets/img/partials/ubi.png";
import LayoutTwo from "../../layouts/LayoutTwo";
import qr from "../../assets/img/partials/qr-example.png";

class AltaProductorMap extends Component {

    render() {

        return (
            <>
                <LayoutTwo></LayoutTwo>

                <div class="container mx-auto">
                    <div class="row">
                        <div class="col s11 m5 l5">
                            <div className="flex justify-center">
                                <div className="box-1">
                                    <h3 className="font-bold text-five title-2">Información de contacto</h3>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div class="card-productor">
                                    <div class="box-green">
                                        <img src={infoprod} class="human1"/>
                                        <div className="box-space-35">
                                            <p class="text-six font-bold title-6">ID Productor</p>
                                        </div>
                                        <div className="box-space-35">
                                            <input type="text"
                                                   disabled
                                                   className="input-new"
                                                   placeholder="66 77 55 00 55 "
                                                   value={this.props.id}
                                                   onChange={this.props.onChange}/>
                                            <span class="helper-text" data-error="wrong"
                                                  data-success="right">{this.props.idError}</span>
                                        </div>
                                        <div className="box-space-35">
                                            <p class="text-six font-bold title-6">Nombre</p>
                                        </div>
                                        <div className="box-space-35">
                                            <input type="text"
                                                   className="input-new"
                                                   placeholder="José Alberto"
                                                   value={this.props.first_name}
                                                   onChange={this.props.onChange}/>
                                            <span class="helper-text" data-error="wrong"
                                                  data-success="right">{this.props.first_nameError}</span>
                                            <div className="box-space-25">
                                                <input type="text"
                                                       className="input-new"
                                                       placeholder="Guerra Lopéz"
                                                       value={this.props.last_name}
                                                       onChange={this.props.onChange}/>
                                                <span class="helper-text" data-error="wrong"
                                                      data-success="right">{this.props.last_nameError}</span>
                                            </div>
                                        </div>
                                        <div className="box-space-105">
                                            <p class="text-six font-bold title-6">QR</p>
                                            <div className="box-space-25">
                                                <div className="box-space-5">
                                                    <div class="space-qr0"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-padding-15">
                                        <a class="btn-floating btn-large waves-effect waves-light "><i
                                            class="material-icons">search</i></a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col s12 m6 l6">
                            <div className="form-layout">
                                <span className="font-bold text-primary mb-2 title-3">Ubicación</span>
                                <div class="row">
                                    <div class="col s12 m6 l6">
                                        <div class="input-field">
                                            <input id="calle3"
                                                   className="input-2"
                                                   type="text"
                                                   value={this.props.calle}
                                                   onChange={this.props.onChange}/>

                                            <label for="calle2">Calle y numero : </label>
                                            <span class="helper-text" data-error="wrong"
                                                  data-success="right">{this.props.calleError}</span>
                                        </div>
                                        <div class="input-field">
                                            <input id="colonia" className="input-2" type="text"/>
                                            <label for="colonia">Colonia / Municipio : </label>
                                        </div>
                                    </div>

                                    <div class="col s12 m6 l6">

                                        <div class="input-field">
                                            <input id="cp2"
                                                   className="input-3"
                                                   type="text"
                                                   value={this.props.cp}
                                                   onChange={this.props.onChange}/>

                                            <label for="cp2">CP : </label>
                                            <span class="helper-text" data-error="wrong"
                                                  data-success="right">{this.props.cpError}</span>
                                        </div>

                                        <div class="input-field">
                                            <input id="estado2"
                                                   className="input-4"
                                                   type="text"
                                                   value={this.props.estado}
                                                   onChange={this.props.onChange}/>

                                            <label for="estado2">Estado : </label>
                                            <span class="helper-text" data-error="wrong"
                                                  data-success="right">{this.props.estadoError}</span>
                                        </div>
                                    </div>
                                </div>
                                <img src={ubicacionExample} className=""/>
                            </div>

                        </div>

                    </div>
                </div>
                <Link to="/informacion-cultivo">
                    <div className="">
                        <img src={next1} className="next-1"/>
                    </div>
                </Link>
            </>
        )
    }
}

export default withRouter(AltaProductorMap)