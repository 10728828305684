import { authHeader, handleResponse } from '../helpers';
import {url_api} from "../../constants";

export const authService = {
    login,
    logout
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${url_api}login2/`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's a jwt token in the response
            if (data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('auth', JSON.stringify(data));
            }
            return data;
        });
}

function logout() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    // remove user from local storage to log user out
    return fetch(`${url_api}logout/`, requestOptions)
        .then(handleResponse)
        .then(data => {
            localStorage.removeItem('auth');
            return data;
        })

}
