import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import LayoutTwo from "../../layouts/LayoutTwo";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

class AgendaTecnico extends Component{

 

    render() { 

        return (
            <>

            <LayoutTwo></LayoutTwo>
            <div class="container-fluid mx-auto"> 
            <div className="row">
            <div className="col l16 m6 s6">
            <Calendar/>
            
            <div>
          
              </div>
                </div>
            </div>

            </div>

            </>
        )
    
    } 
}

export default withRouter(AgendaTecnico) 