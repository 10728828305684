import React, {Component} from 'react';
import './HomeAdmin.css';
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';
import {url_api} from "../../constants";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
 
/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const grid = 4

class HomeQuimico extends Component{
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            user: [],
            last: {},
            list1: [],
            list2: [],
            list3: []
        };
    
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'https://testapi.green-up.xyz';
    }

    componentDidMount= async () => {
        const res = await fetch(`${url_api}ordenes/`, {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const orders = await res.json();  
        

        if(!orders.detail){
          let list1 =orders.filter(order => order.status == 3);
          let list2 =orders.filter(order => order.status == 4);
          let list3 =orders.filter(order => order.status == 5);
          console.log(this.props.authentication.token);
          this.setState({list1: list1, list2: list2, list3: list3}); 
        }
        
    }
    droppableIds = {
        droppable1: 'list1',
        droppable2: 'list2',
        droppable3: 'list3'
      }
    
      getList = id => this.state[this.droppableIds[id]]
    
      onDragEnd = result => {
        const { source, destination } = result
    
        // dropped outside the list
        if (!destination) { return }
    
        if (source.droppableId === destination.droppableId) {
          const items = reorder(
            this.getList(source.droppableId),
            source.index,
            destination.index
          )
    
          let copiedState = Object.assign({}, this.state)
    
          if (source.droppableId === 'droppable1') {
            copiedState.list1 = items
          } else if (source.droppableId === 'droppable2') {
            copiedState.list2 = items
          } else if (source.droppableId === 'droppable3') {
            copiedState.list3 = items
          }
    
          this.setState(copiedState)
        } else {
          const result = move(
            this.getList(source.droppableId),
            this.getList(destination.droppableId),
            source,
            destination
          )
    
          console.warn('resulto', result)
          console.log(result.droppable1,result.droppable2,result.droppable3);
          if(result.droppable1){
            result.droppable1.map((x) => {
              return (
                fetch(`${url_api}orden_status/${x.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${this.props.authentication.token}`
                    },
                    body: JSON.stringify({
                        status: 3,
                        comentarios: [
                          {       
                            comentario: "",
                            user_id: this.props.authentication.user.id
                          }
                        ]
                    })
                }) 
              );
            })
            this.componentDidMount();
          }
          if(result.droppable2){  
            result.droppable2.map((x) => {
              return (
                fetch(`${url_api}orden_status/${x.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${this.props.authentication.token}`
                    },
                    body: JSON.stringify({
                      status: 4,
                      comentarios: [
                        {       
                          comentario: "",
                          user_id: this.props.authentication.user.id
                        }
                      ]
                    })
                }) 
              );
            })
            this.componentDidMount();
          }
          if(result.droppable3){  
            result.droppable3.map((x) => {
              return (
                fetch(`${url_api}orden_status/${x.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${this.props.authentication.token}`
                    },
                    body: JSON.stringify({
                      status: 5,
                      comentarios: [
                        {       
                          comentario: "",
                          user_id: this.props.authentication.user.id
                        }
                      ]
                    })
                }) 
              );
            })
            this.componentDidMount();
          }
          this.setState({
            list1: result.droppable1 ? result.droppable1 : this.state.list1,
            list2: result.droppable2 ? result.droppable2 : this.state.list2,
            list3: result.droppable3 ? result.droppable3 : this.state.list3
          })
        }
        
      }

    render() {
        
        const lists = [
            {
              droppableId: 'droppable1',
              listId: 'list1',
              title: 'Para preparar'
            },
            {
              droppableId: 'droppable2',
              listId: 'list2',
              title: 'Preparadas'
            },
            {
              droppableId: 'droppable3',
              listId: 'list3',
              title: 'Listas para entrega'
            },
          ]
       
        return (
                <div className="">
                    <div className=" mb-4 ">
                        <h1 className="font-bold text-primary">Perfil del Quimico</h1>
                        
                        <div className="flex grid grid-cols-3 gap-4 pt-10">
                            <DragDropContext onDragEnd={this.onDragEnd}>

                                {lists.map((list, listIndex) =>
                                    <Droppable key={'list-droppable-' + listIndex} droppableId={list.droppableId}>
                                    {(provided, snapshot) => (
                                        <div  ref={provided.innerRef}> 
                                        <div className="inline-flex text-white  bg-green-500 w-full h-8 px-10 py-5 justify-center items-center">{list.title}</div>
                                        {this.state[list.listId] && this.state[list.listId].map((item, index) => (
                                            <Draggable
                                            key={item.id}
                                            draggableId={item.id.toString()}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                { ...provided.draggableProps }
                                                { ...provided.dragHandleProps }
                                                className=" bg-white cursor-pointer rounded shadow-md p-4 mt-5">
                                                    <div className="pt-2 pb-2">
                                                        <p className="self-center ">ID Orden:</p>
                                                        <p className="validate w-full border-b-1 text-green-500 focus:outline-none ord-value">
                                                            {item.id}
                                                        </p> 
                                                    </div>
                                                    <div className="pt-2 pb-2">
                                                        <p className="self-center ">Tipo de Aplicacion:</p>
                                                        <p className="validate w-full border-b-1 text-green-500 focus:outline-none ord-value">
                                                            {item.tipo_aplicacion}
                                                        </p> 
                                                    </div>
                                                    <div className="pt-2 pb-2">
                                                        <p className="self-center ">Productor:</p>
                                                        <p className="validate w-full border-b-1 text-green-500 focus:outline-none ord-value">
                                                            {item.nombre_productor} {item.apellidos_productor} 
                                                        </p> 
                                                    </div>
                                                    <div className="pt-2 pb-2">
                                                        <NavLink to={'/reporte-lab/' + item.id} className="inline-block py-1 px-3 m-1 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none" >Ver mas</NavLink>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                )}
                            </DragDropContext>
                        </div>
                    </div>
                      
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}

export default connect(mapStateToProps)(HomeQuimico);