import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import axios from 'axios';
import paisaje from '../../assets/img/menu/paisaje.png';
import {url_api} from "../../constants";
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '10%',
      left                  : '20%',
      right                 : '20%',
      background            : '#90ec90'
    }
  };

class Plagas extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            
            nombre: '',
            foto_referencia: '',
            imagePreviewUrlRef: '',
            observaciones: '',
            foto_observaciones: '',
            imagePreviewUrlObs: '',
            nivel1_max: '',
            nivel1_min: '', 
            nivel2_max: '',
            nivel2_min: '',
            nivel3_max: '',
            nivel3_min: '',
            nivel4_max: '',
            nivel4_min: '',   

            id:'',
            editing: false,

            item: '',
            control: false,
            list: [],

            open: 0,

            img1: '',
            img2: '', 
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);        
        this.handleImageRef = this.handleImageRef.bind(this);       
        this.handleImageObs = this.handleImageObs.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }

        const res = await fetch(`${url_api}plagas_enfermedades/`, options);
    
        const apps = await res.json();
        if(!apps.detail){     
            this.setState({apps:apps}) 
        }              
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }
    handleImageRef(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let file = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            foto_referencia: file,
            imagePreviewUrlRef: reader.result
          });
        }
    
        reader.readAsDataURL(file)
    }

    handleImageObs(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let fileo = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            foto_observaciones: fileo,
            imagePreviewUrlObs: reader.result
          });
        }
    
        reader.readAsDataURL(fileo)
    }
    
    clickImage(e) {
        console.log(e)
        if(e === '1'){
            document.querySelector('#foto1').click();
        }else{
            document.querySelector('#foto2').click();
         }
    }

  
    //crear y editar
    submit= async (e) =>{
        e.preventDefault();
        if(this.state.nivel1_max < this.state.nivel1_min ||
            this.state.nivel2_max < this.state.nivel2_min||
            this.state.nivel3_max < this.state.nivel3_min ||
            this.state.nivel4_max < this.state.nivel4_min ){
            MySwal.fire({
                icon: 'warning',
                title:'Alto!',
                text: 'Los niveles maximos no puede ser menor que los minimos',
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                    title:'title-class',
                    popup:'popup-class',
                    confirmButton: 'confirmbutton-class',
                },
              })
        }else if(this.state.nivel1_max > this.state.nivel2_min ){
            MySwal.fire({
                icon: 'warning',
                title:'Alto!',
                text:  'Los datos del nivel 2 deben ser mayores que el nivel 1',
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                    title:'title-class',
                    popup:'popup-class',
                    confirmButton: 'confirmbutton-class',
                },
              })
        }else if(this.state.nivel2_max > this.state.nivel3_min ){
            MySwal.fire({
                icon: 'warning',
                title:'Alto!',
                text: 'Los datos del nivel 3 deben ser mayores que el nivel 2',
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                    title:'title-class',
                    popup:'popup-class',
                    confirmButton: 'confirmbutton-class',
                },
              })
        }else if(this.state.nivel3_max > this.state.nivel4_min ){
            MySwal.fire({
                icon: 'warning',
                title:'Alto!',
                text: 'Los datos del nivel 4 deben ser mayores que el nivel 3',
                showConfirmButton: true,
                buttonsStyling: false,
                customClass: {
                    title:'title-class',
                    popup:'popup-class',
                    confirmButton: 'confirmbutton-class',
                },
              })
        }else{

            if (this.state.editing===false) {
                    let form_data = new FormData();
                    form_data.append('foto_observaciones', this.state.foto_observaciones);
                    form_data.append('foto_referencia', this.state.foto_referencia);
                    form_data.append('nombre', this.state.nombre);
                    form_data.append('observaciones', this.state.observaciones);
                    form_data.append('nivel1_max', this.state.nivel1_max);
                    form_data.append('nivel1_min', this.state.nivel1_min);
                    form_data.append('nivel2_max', this.state.nivel2_max);
                    form_data.append('nivel2_min', this.state.nivel2_min);
                    form_data.append('nivel3_max', this.state.nivel3_max);
                    form_data.append('nivel3_min', this.state.nivel3_min);
                    form_data.append('nivel4_max', this.state.nivel4_max);
                    form_data.append('nivel4_min', this.state.nivel4_min);

                    axios.post(`${url_api}plagas_enfermedades/`, form_data, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': `Token ${this.props.token}`
                        }
                    })
                    .then(res => {
                        console.log(res);
                       let timerInterval
                       MySwal.fire({
                           title: 'Guardando Registro...',
                           showConfirmButton: false,
                           timer: 2000,
                           customClass: {
                               title:'title-class',
                               popup:'popup-class',
                           },
                           willOpen: () => {
                               MySwal.showLoading()
                                                      
                           },
                           willClose: () => {
                               clearInterval(timerInterval)
                           }
                       }).then((result) => {
                               if (
                                   result.dismiss === MySwal.DismissReason.timer
                               ) {
                                   MySwal.fire({
                                       icon: 'success',
                                       title: 'Se Creo Correctamente el Registro de Plagas y Enfermedades',
                                       showConfirmButton: true,
                                       buttonsStyling: false,
                                       customClass: {
                                           title:'title-class',
                                           popup:'popup-class',
                                           confirmButton: 'confirmbutton-class',
                                       },
                                   })                        
                               }
                       })
                       
                
                        this.componentDidMount();
                        this.setState({
                            nombre: '',
                            foto_referencia: '',
                            imagePreviewUrlRef:'',
                            observaciones: '',
                            foto_observaciones: '',
                            imagePreviewUrlObs: '',
                            nivel1_max: '',
                            nivel1_min: '', 
                            nivel2_max: '',
                            nivel2_min: '',
                            nivel3_max: '',
                            nivel3_min: '',
                            nivel4_max: '',
                            nivel4_min: '',
                        })
                       
                    }).catch(
                       function (error) {
                           console.log(error.response);
                           MySwal.fire({
                               icon: 'warning',
                               title: 'Algo Salio mal!!',
                               text: [error.response.data.nombre],
                               showConfirmButton: true,
                               buttonsStyling: false,
                               customClass: {
                                   title:'title-class',
                                   popup:'popup-class',
                                   confirmButton: 'confirmbutton-class',
                               },
                             })
                       }
                     )
                    
            } else {
                const id=this.state.id;
                const object = 'object';

                
                const a = this.state.foto_referencia;
                if (a != null){
                    const type =typeof (this.state.foto_referencia);
                    if (type === object){this.state.img1 = this.state.foto_referencia;}
                }
                
                
                const b = this.state.foto_observaciones;
                if (b != null){
                    const typeb =typeof (this.state.foto_observaciones);
                    if (typeb === object){this.state.img2 = this.state.foto_observaciones;}
                }

                let form_data = new FormData();
                form_data.append('nombre', this.state.nombre);
                form_data.append('foto_referencia', this.state.img1);
                form_data.append('observaciones', this.state.observaciones);
                form_data.append('foto_observaciones', this.state.img2); 
                form_data.append('nivel1_max', this.state.nivel1_max);
                form_data.append('nivel1_min', this.state.nivel1_min);
                form_data.append('nivel2_max', this.state.nivel2_max);
                form_data.append('nivel2_min', this.state.nivel2_min);
                form_data.append('nivel3_max', this.state.nivel3_max);
                form_data.append('nivel3_min', this.state.nivel3_min);
                form_data.append('nivel4_max', this.state.nivel4_max);
                form_data.append('nivel4_min', this.state.nivel4_min);
                
                axios.put(`${url_api}plagas_enfermedades/${id}/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Token ${this.props.token}`
                    }
                }).then(res => {
                    console.log(res);
                   let timerInterval
                   MySwal.fire({
                       title: 'Actualizando Registro...',
                       showConfirmButton: false,
                       timer: 2000,
                       customClass: {
                           title:'title-class',
                           popup:'popup-class',
                       },
                       willOpen: () => {
                           MySwal.showLoading()
                                                  
                       },
                       willClose: () => {
                           clearInterval(timerInterval)
                       }
                   }).then((result) => {
                           if (
                               result.dismiss === MySwal.DismissReason.timer
                           ) {
                               MySwal.fire({
                                   icon: 'success',
                                   title: 'Se Actualizo Correctamente',
                                   showConfirmButton: true,
                                   buttonsStyling: false,
                                   customClass: {
                                       title:'title-class',
                                       popup:'popup-class',
                                       confirmButton: 'confirmbutton-class',
                                   },
                               })                        
                           }
                   })
                   
            
                   this.componentDidMount();
                   this.setState({
                        nombre: '',
                        foto_referencia: '',
                        imagePreviewUrlRef:'',
                        observaciones: '',
                        foto_observaciones: '',
                        imagePreviewUrlObs: '',
                        nivel1_max: '',
                        nivel1_min: '', 
                        nivel2_max: '',
                        nivel2_min: '',
                        nivel3_max: '',
                        nivel3_min: '',
                        nivel4_max: '',
                        nivel4_min: '',
                        img1:'',
                        img2:''
                       
                   })
                   
                }).catch(function (error) {
                       console.log(id);
                       
                       MySwal.fire({
                           icon: 'warning',
                           title: [error.response.data.nombre],
                           showConfirmButton: true,
                           buttonsStyling: false,
                           customClass: {
                               title:'title-class',
                               popup:'popup-class',
                               confirmButton: 'confirmbutton-class',
                           },
                        })
                        
                        this.editNutricion(id);                
                   }.bind(this)
                 )
                this.state.editing = false;
                this.state.id= "";

                
            }     
        }

        

      
    }

    // muetra datos para editar
    editNutricion = async (id) => {
        
        const res = await fetch(`${url_api}plagas_enfermedades/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.control = true;
        this.state.id= id;
        
        this.setState({
            nombre: data.nombre,
            foto_referencia: data.foto_referencia,
            imagePreviewUrlRef: data.foto_referencia,
            observaciones: data.observaciones,
            foto_observaciones: data.foto_observaciones,
            imagePreviewUrlObs: data.foto_observaciones,
            nivel1_max: data.nivel1_max,
            nivel1_min: data.nivel1_min, 
            nivel2_max: data.nivel2_max,
            nivel2_min: data.nivel2_min,
            nivel3_max: data.nivel3_max,
            nivel3_min: data.nivel3_min,
            nivel4_max: data.nivel4_max,
            nivel4_min: data.nivel4_min,
        })
        
        console.log(data);
        
    };

    //elimina datos
    deleteNutricion = async (id) => {
        this.state.control = true;
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}plagas_enfermedades/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                
                MySwal.fire({
                    icon: 'Success',
                    title: 'Eliminado!',
                    text: 'El Registro ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  });
            }
            this.componentDidMount();
          })
          
    };

    //muestra elemento
    getNutricion = async (id) => {
        const res = await fetch(`${url_api}plagas_enfermedades/${id}/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const item = await res.json(); 
        
        this.setState({item: item, open: id});
        if(this.state.control === false){
            this.setState({ showModal: true });
        }else{
            this.state.control = false;
        } 
        
    };
    handleOpenModal () {
        this.setState({ showModal: true });
    }
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render() {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        let {imagePreviewUrlRef, imagePreviewUrlObs} = this.state;
        let $imagePreviewRef = null;
        let $imagePreviewObs = null;
        if (imagePreviewUrlRef) {
            $imagePreviewRef = (<img alt="plagas" src={imagePreviewUrlRef} />);
        } else {
            $imagePreviewRef = (<div className="previewText">Selecciona una foto de Referencia:</div>);
        }
        if (imagePreviewUrlObs) {
            $imagePreviewObs = (<img src={imagePreviewUrlObs} />);
        } else {
            $imagePreviewObs = (<div className="previewText">Selecciona una foto de Observacion:</div>);
        }

        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Plagas y Enfermedades </h2>
                    <div className="grid grid-cols-12 gap-4 ">
                        <div className="bg-white col-span-12 rounded ">
                            <div className="form bg-white rounded shadow-md py-8">
                                <h3 className="font-bold text-primary m-4">Crear Plagas y Enfermedades</h3>
                                <form className="m-6" onSubmit={this.submit}>
                                    <div className=" form bg-white rounded grid-cols-12 overflow-hidden flex">
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nombre:   </label>
                                            <input
                                                id="nombre"
                                                name="nombre"
                                                type="text"
                                                value={this.state.nombre} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nombre ej. Mancha Angular"
                                                required
                                                title="Favor de Agregar el Nombre de la plaga y Enfermedad. ej. Mancha Angular"
                                            />                                                
                                        </div>
                                        <div className="custom-input-pl my-1 mx-2">
                                            <div className="custom-input-file-pl">
                                                <input className="file-input" 
                                                    type="file" 
                                                    name="foto_referencia"
                                                    id="foto1"
                                                    title="Favor de Agregar Foto de Referencia"
                                                    onChange={this.handleImageRef} />  
                                                  <div className="imgPreview">
                                                    {$imagePreviewRef}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage('1') } className="icon-img-pl inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Observaciones:   </label>
                                            <input
                                                id="observaciones"
                                                name="observaciones"
                                                type="text"
                                                value={this.state.observaciones} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Observaciones ej. En Follaje"
                                                required
                                                title="Favor de Agregar una Observación de la plaga y Enfermedad. ej. En Follaje"
                                            />                                                
                                        </div>
                                        
                                        <div className="custom-input-pl my-1 mx-2">
                                            <div className="custom-input-file-pl">
                                                <input className="file-input" 
                                                type="file" 
                                                name="foto_observaciones"
                                                id="foto2"
                                                title="Favor de Agregar una Foto de observaciones"
                                                onChange={this.handleImageObs} />
                                                <div className="imgPreview">
                                                    {$imagePreviewObs}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage('2') } className="icon-img-pl inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" form bg-white rounded grid-cols-12 overflow-hidden flex">
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 1 minimo:   </label>
                                            <input
                                                id="nivel1_min"
                                                name="nivel1_min"
                                                type="text"
                                                value={this.state.nivel1_min} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 1 minimo ej. 0.1"
                                                required
                                                title="Favor de Agregar Valor Minimo para el Nivel 1. ej. 0.1"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 1 máximo:   </label>
                                            <input
                                                id="nivel1_max"
                                                name="nivel1_max"
                                                type="text"
                                                value={this.state.nivel1_max} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 1 máximo ej. 5"
                                                required
                                                title="Favor de Agregar Valor Máximo para el Nivel 1 no Mayor al Minimo. ej. 5"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 2 minimo:   </label>
                                            <input
                                                id="nivel2_min"
                                                name="nivel2_min"
                                                type="text"
                                                value={this.state.nivel2_min} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 2 minimo ej. 5.1"
                                                required
                                                title="Favor de Agregar Valor Minimo para el Nivel 2 no Menor al Nivel 1. ej. 5.1"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 2 máximo:   </label>
                                            <input
                                                id="nivel2_max"
                                                name="nivel2_max"
                                                type="text"
                                                value={this.state.nivel2_max} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 2 máximo ej. 10"
                                                required
                                                title="Favor de Agregar Valor Máximo para el Nivel 2 no Mayor al Minimo. ej. 10"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 3 minimo:   </label>
                                            <input
                                                id="nivel3_min"
                                                name="nivel3_min"
                                                type="text"
                                                value={this.state.nivel3_min} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 3 minimo ej. 10.1"
                                                required
                                                title="Favor de Agregar Valor Minimo para el Nivel 3 no Menor al Nivel 2. ej. 10.1"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 3 máximo:   </label>
                                            <input
                                                id="nivel3_max"
                                                name="nivel3_max"
                                                type="text"
                                                value={this.state.nivel3_max} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 3 máximo ej. 15"
                                                required
                                                title="Favor de Agregar Valor Máximo para el Nivel 3  no Mayor al Minimo. ej. 15"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 4 minimo:   </label>
                                            <input
                                                id="nivel4_min"
                                                name="nivel4_min"
                                                type="text"
                                                value={this.state.nivel4_min} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 4 minimo ej. 15.1"
                                                required
                                                title="Favor de Agregar Valor Minimo para el Nivel 4 no Menor al Nivel 3. ej. 15.1"
                                            />                                                
                                        </div>
                                        <div className="my-1 mx-2">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Nivel 4 máximo:   </label>
                                            <input
                                                id="nivel4_max"
                                                name="nivel4_max"
                                                type="text"
                                                value={this.state.nivel4_max} 
                                                onChange={this.handleChange}
                                                className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Nivel 4 máximo ej. 20"
                                                required
                                                title="Favor de Agregar Valor Máximo para el Nivel 4 no Mayor al Minimo. ej. 20"
                                            />                                                
                                        </div>
                                        
                                        
                                    </div>

                                   
                                    
                                    <div className="p-4 btn-box flex">
                                        
                                        <div>
                                            <button type="submit" className="inline-block py-1 px-3 m-5 text-center rounded-full text-white transition bg-green-500  shadow ripple hover:shadow-lg hover:bg-green-600 focus:outline-none">{this.state.editing ? "Actualizar Plaga y Enfermedad" : "Guardar Plaga y Enfermedad"}</button>
                                        </div>
                                    </div>
                                </form>
                                    <Modal 
                                    isOpen={this.state.showModal}
                                    ariaHideApp={false}
                                    contentLabel="Modal"
                                    onRequestClose={this.handleCloseModal}
                                    style={customStyles}
                                    >
                                    <div  className="flex justify-between bg-white">
                                        <h3 className="font-bold text-primary m-4">Detalles Plagas y Enfermedades</h3>
                                        <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 m-4" onClick={this.handleCloseModal}>Cerrar</button>
                                    </div>    
                                    
                                    <div className="shadow-md divide-y divide-green-400 bg-white">
                                        <div className=" form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nombre: </h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nombre}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Observaciones:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.observaciones}</p></div>
                                        </div>
                                        <div className=" form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className=""><h6 className="pl-title my-1 mx-2">Foto Referencia:</h6>
                                            <p className="pl-value my-1 mx-2">
                                                {this.state.item.foto_referencia === null  ? 
                                                    <img className="detail-img-pl mx-5" src={paisaje}/>:
                                                    <img className="detail-img-pl mx-5" src={this.state.item.foto_referencia}/>}
                                            </p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Foto Observación:</h6>
                                            <p className="pl-value my-1 mx-2">
                                                {this.state.item.foto_observaciones === null  ? 
                                                    <img className="detail-img-pl mx-5" src={paisaje}/>:
                                                    <img className="detail-img-pl mx-5" src={this.state.item.foto_observaciones}/>}
                                            </p></div>
                                        </div>
                                        <div className=" form bg-white rounded grid-cols-2 px-2 py-8 overflow-hidden flex">
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 1 minimo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel1_min}</p></div>   
                                            <div className=""><h6 className="pl-title my-1 mx-2 ">Nivel 1 maximo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel1_max}</p></div>   
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 2 minimo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel2_min}</p></div>                                      
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 2 maximo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel2_max}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 3 minimo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel3_min}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 3 maximo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel3_max}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 4 minimo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel4_min}</p></div>
                                            <div className=""><h6 className="pl-title my-1 mx-2">Nivel 4 maximo:</h6>
                                            <p className="pl-value my-1 mx-2">{this.state.item.nivel4_max}</p></div>
                                        </div>
                                        
                                    </div>
                                </Modal> 
                            </div>
                        </div>
                        <div className="col-span-12 mb-20">
                            <h3 className="font-bold text-primary m-4">Lista Plagas y Enfermedades</h3><br></br>
                            <div className="col-span-12 grid grid-cols-4 gap-4">
                                {this.state.apps.map((app, i)=> {
                                    return (
                                        <a title="Oprime para Ver los Detalles" onClick={(e) => this.getNutricion(app.id)}  key={app.id} id={app.id} className={`cursor-pointer bg-white rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32 ${this.state.open === app.id ? 'activeclass' : ''}`}>
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Nombre:</h6>
                                                <p className="pl-value my-1">{app.nombre} </p>
                                                <h6 className="pl-title my-1 mt-2">observación</h6>
                                                <p className="pl-value nmy-1">{app.observaciones}</p>
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editNutricion(app.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteNutricion(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                })}  
                            </div>  
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Plagas))

