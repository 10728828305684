import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
class Cultivo extends Component {
    

    constructor() {
        super();
        this.state = {
            apps : [],
            appsback : [],
            
            nombre: "",
            kg_lt_ha: "",
            precio_unitario: "",
            precio_mn: "",
            usd_tonelada: "",
            importe_100: "",
            existencia: "",
            unidad_medida: "",
            presentaciones: "",
                        
            id:'',
            editing: false,
            item: '',
            currentPage: 1,
            todosPerPage: 10,
            text:  '',
            vacio: false
        }
        this.submit = this.submit.bind(this);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClicknext = this.handleClicknext.bind(this);
        this.handleClickprev = this.handleClickprev.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
       
        var URL = window.location.pathname;
        if(URL === '/productos-nutricion') {
            const res = await fetch(`${url_api}productos_nutricion/`, {
                headers: {
                    'Authorization': `Token ${this.props.token}`
                },
            });
            const productos = await res.json();     
            console.log(productos);
            if(!productos.detail){     
                this.setState({apps:productos, appsback:productos})
            } 
        }
        
            
    }

   
    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

   
    //crear y editar temporadas
    submit= async (e) =>{
        e.preventDefault();
        if (this.state.editing===false) {
            const res = await fetch(`${url_api}productos_nutricion/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    nombre: this.state.nombre,
                    kg_lt_ha: this.state.kg_lt_ha,
                    precio_unitario: this.state.precio_unitario,
                    precio_mn: this.state.precio_mn,
                    usd_tonelada: this.state.usd_tonelada,
                    importe_100: '0',
                    inventario: {
                        existencia: this.state.existencia,
                        unidad_medida: this.state.unidad_medida,
                        presentaciones: this.state.presentaciones,
                    }
                })
            }) 
            const data = await res.json();
            if(data.id){
                let timerInterval
                MySwal.fire({
                    title: 'Guardando Producto...',
                    showConfirmButton: false,
                    timer: 2000,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },

                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Creo Correctamente el Producto',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                            })                        
                        }
                })
                this.componentDidMount();

                this.setState({
                    nombre: "",
                    kg_lt_ha: "",
                    precio_unitario: "",
                    precio_mn: "",
                    usd_tonelada: "",
                    importe_100: "",
                    existencia: "",
                    unidad_medida: "",
                    presentaciones: "",
                })
            }else{
                MySwal.fire({
                    icon: 'warning',
                    title: [data.nombre,'</br>',data.importe_100],
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  })
            }             
            
        } else {
            const id=this.state.id;
           
            const res = await fetch(`${url_api}productos_nutricion/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    nombre: this.state.nombre,
                    kg_lt_ha: this.state.kg_lt_ha,
                    precio_unitario: this.state.precio_unitario,
                    precio_mn: this.state.precio_mn,
                    usd_tonelada: this.state.usd_tonelada,
                    importe_100: '0',
                    inventario: {
                        existencia: this.state.existencia,
                        unidad_medida: this.state.unidad_medida,
                        presentaciones: this.state.presentaciones,
                    }
                }),
              });
            const data = await res.json();
            if(data.id){
                let timerInterval
                MySwal.fire({
                    title: 'Actualizando Producto...',
                    showConfirmButton: false,
                    timer: 2000,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente el Producto',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                            })                        
                        }
                })
                this.componentDidMount();

                this.setState({
                    nombre: "",
                    kg_lt_ha: "",
                    precio_unitario: "",
                    precio_mn: "",
                    usd_tonelada: "",
                    importe_100: "",
                    existencia: "",
                    unidad_medida: "",
                    presentaciones: "",
                })
            }else{
                MySwal.fire({
                    icon: 'warning',
                    title: [data.nombre],
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                    },
                  })
            }  
            this.state.editing = false;
            this.state.id= "";
        }     
        

        

    }

    // muetra datos para editar cultivos
    editProducto = async (id) => {
            
        const res = await fetch(`${url_api}productos_nutricion/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
        });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        
        this.setState({
            nombre: data.nombre,
            kg_lt_ha: data.kg_lt_ha,
            precio_unitario: data.precio_unitario,
            precio_mn: data.precio_mn,
            usd_tonelada: data.usd_tonelada,
            importe_100: data.importe_100,
            existencia: data.inventario.existencia,
            unidad_medida: data.inventario.unidad_medida,
            presentaciones: data.inventario.presentaciones,
        })
    
        
    };

    //elimina datos
    deleteProducto = async (id) => {
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}productos_nutricion/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                }).then(res => {
                    MySwal.fire({
                        icon: 'Success',
                        title: 'Eliminado!',
                        text: 'El Registro ha sido Eliminado Correctamente.',
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                            cancelButton: 'cancelbutton-class'
                        },
                      });
                    this.componentDidMount();
                })
                .catch(err => console.log(err))
                          
            }
            this.componentDidMount();
          })
    };

    handleClick(event) {
        this.setState({
          currentPage: Number(event.target.id)
        });
        
      }
    handleClicknext(event) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.apps.length / this.state.todosPerPage); i++) {
          pageNumbers.push(i);
        }
        
        console.log(pageNumbers.length);
        
        if (pageNumbers.length !== this.state.currentPage){
            const next = this.state.currentPage+1;
            this.setState({
                currentPage: next
             });
        }
     }
     handleClickprev(event) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.apps.length / this.state.todosPerPage); i++) {
          pageNumbers.push(i);
        }
        console.log(pageNumbers);
        
        
        if (1 !== this.state.currentPage){
            const prev = this.state.currentPage-1;
            this.setState({
                currentPage: prev
             });
             
        }
     }

     filter(event){
        var text = event.target.value;
        const data = this.state.appsback;

        
        const newData = data.filter(function(item){
            const itemDataNombre = item.nombre.toUpperCase();
            const itemDataCod = item.id;
            const campo = itemDataNombre+" "+itemDataCod;
            const textDate = text.toUpperCase();
            const numDate = text;
            return campo.indexOf(textDate||numDate) > -1;
        });
        if (newData.length===0){
            const vacio = true;
            this.setState({
                text: text,
                apps:newData,
                vacio:vacio
            });
        }else{
            const vacio = false;
        this.setState({
            text: text,
            apps:newData,
            vacio:vacio
        });   }        
     }
    render() {
        
        const { apps, currentPage, todosPerPage } = this.state;
         
        // Logic for displaying current todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = apps.slice(indexOfFirstTodo, indexOfLastTodo);

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(apps.length / todosPerPage); i++) {
          pageNumbers.push(i);
        }
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes h-screen';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Productos Nutrición</h2>
                    <div className="grid grid-cols-1 gap-4 ">
                        <div className="bg-white rounded pt-10">
                        <h2 className="font-bold text-primary m-4">Agregar un Nuevo Producto</h2>
                                <form onSubmit={this.submit} className="m-6">
                                        <div className=" form bg-white rounded grid grid-cols-4 gap-4 my-4 overflow-hidden ">
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Nombre:   </label>
                                                <input
                                                    id="nombre"
                                                    name="nombre"
                                                    type="text"
                                                    value={this.state.nombre} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Nombre del Producto ej. Fertiazul"
                                                    required
                                                    title="Favor de Agregar Nombre del Producto. ej. Fertiazul"
                                                    
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Precio Unitario:   </label>
                                                <input
                                                    id="precio_unitario"
                                                    name="precio_unitario"
                                                    type="text"
                                                    value={this.state.precio_unitario} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Precio Unitario ej. 50"
                                                    required
                                                    title="Favor de Agregar el precio unitario del Producto. ej. 50"
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Precio mn:   </label>
                                                <input
                                                    id="precio_mn"
                                                    name="precio_mn"
                                                    type="text"
                                                    value={this.state.precio_mn} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Precio mn ej. 1"
                                                    required
                                                    title="Favor de Agregar el precio mn del Producto. ej. 1"
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">usd tonelada:   </label>
                                                <input
                                                    id="usd_tonelada"
                                                    name="usd_tonelada"
                                                    type="text"
                                                    value={this.state.usd_tonelada} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="usd_tonelada ej.50"
                                                    required
                                                    title="Favor de Agregar usd_tonelada del Producto. ej. 50"
                                                /> 
                                            </div>
                                        </div>
                                        <div className=" form bg-white rounded grid grid-cols-4 gap-4 my-4 overflow-hidden ">    
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">kg lt hectarea:   </label>
                                                <input
                                                    id="kg_lt_ha"
                                                    name="kg_lt_ha"
                                                    type="text"
                                                    value={this.state.kg_lt_ha} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="kg_lt_ha ej. 50"
                                                    required
                                                    title="Favor de Agregar kg_lt_ha. ej. 50"
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Existencia:   </label>
                                                <input
                                                    id="existencia"
                                                    name="existencia"
                                                    type="text"
                                                    value={this.state.existencia} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Existencia ej. 50"
                                                    required
                                                    title="Favor de Agregar Cantidad del Producto en Existencia. ej. 50"
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Unidad Medida:   </label>
                                                <input
                                                    id="unidad_medida"
                                                    name="unidad_medida"
                                                    type="text"
                                                    value={this.state.unidad_medida} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Unidad Medida ej.KG "
                                                    required
                                                    title="Favor de Agregar la Unicad de Medida del Producto. ej. KG0"
                                                /> 
                                            </div>
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Presentaciones:   </label>
                                                <input
                                                    id="presentaciones"
                                                    name="presentaciones"
                                                    type="text"
                                                    value={this.state.presentaciones} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Presentaciones ej. 1"
                                                    required
                                                    title="Favor de Agregar la Cantidad de Presentaciones del Producto. ej. 1"
                                                /> 
                                            </div>
                                        </div>
                                        {/*<div className=" form bg-white rounded grid grid-cols-4 gap-4 my-4 overflow-hidden ">    
                                            <div className="my-1 mx-2 ">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Importe:   </label>
                                                <input
                                                    id="importe_100"
                                                    name="importe_100"
                                                    type="text"
                                                    value={this.state.importe_100} 
                                                    onChange={this.handleChange}
                                                    className="validate w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="importe ej. 50"
                                                    required
                                                    title="Favor de Agregar importe. ej. 50"
                                                /> 
                                            </div>
                                        </div>*/}
                                        <div className="p-4">
                                            <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center ">{this.state.editing ? "Actualizar Producto" : "Guardar Producto"}</button>
                                        </div>
                                </form>
                                
                        </div>
                        <div className="flex justify-between items-center text-center">
                            
                            <div className="my-1 mx-2 flex items-center  ">
                                <h2 className="font-bold text-primary mr-4">Lista de Productos </h2>                
                                <div className="border-2 border-green-200 bg-white text-green-500 rounded text-center flex items-center search">
                                    <input value={this.state.text} onChange={(text) => this.filter(text)}
                                        className="in-search"
                                        placeholder="Buscar por ID o Nombre"
                                    /> 
                                    <i className="material-icons">search</i>
                                </div>
                            </div>
                            <div>
                                <button  
                                    className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                    onClick={(e) => this.handleClickprev(this.state.currentPage)}>Anterior</button>
                                <p className="bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center">
                                    {this.state.currentPage} 
                                </p>  
                                {/*pageNumbers.map(number => {
                                    return (
                                        
                                        <button key={number}
                                        id={number}
                                        onClick={this.handleClick}
                                        className={number == this.state.currentPage ? "bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center": "bg-white w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-black border-2 border-green-500 rounded-full hover:bg-green-500 inline-flex items-center justify-center"}
                                        >{number}</button>
                                        
                                    );
                                })*/}                                     
                                <button  
                                    className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                    onClick={(e) => this.handleClicknext(this.state.currentPage)}>Siguiente</button>
                                <p>Páginas {this.state.currentPage} de {pageNumbers.length} </p>
                            </div>
                        </div>
                        <div className="flex  ">
                        
                            <table  className="form bg-white rounded overflow-hidden">
                                <thead>
                                    <tr className="table-res bg-green-300">
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Nombre</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Existencia</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Unidad Medida</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">Presentaciones</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">kg_lg_ha</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">precio_unitario</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">precio_mn</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">usd_tonelada</th>
                                        <th className="w-full lg:w-auto p-3 pl-title-res my-1 mx-2 font-medium">importe_100</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {this.state.vacio ? 
                                <tbody >
                                    <tr><td colSpan="9" className="vacio-search text-center">No se encontraron Coincidencias,<br></br> Por favor realiza una nueva busqueda</td></tr>
                                </tbody>:
                                <tbody className="flex-col items-center justify-between overflow-y-scroll w-full mb-10">
                                    {currentTodos.map((app, i)=> {
                                        return (
                                            <tr key={i} id={app.id} className="border-none" >
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.nombre} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.inventario.existencia} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.inventario.unidad_medida} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.inventario.presentaciones} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.kg_lt_ha} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.precio_unitario} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.precio_mn} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.usd_tonelada} </p>
                                                </td> 
                                                <td className="nut-table-ing">
                                                    <p className="pl-value my-1">{app.importe_100} </p>
                                                </td> 
                                                <td>
                                                    <div className="flex">
                                                        <button onClick={(e) => this.editProducto(app.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                            <i className="material-icons">edit</i>
                                                        </button>
                                                        <button onClick={(e) => this.deleteProducto(app.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                            <i className="material-icons">delete</i>
                                                        </button>
                                                        
                                                    </div>
                                                </td>
                                                
                                            </tr>
                                            
                                            
                                        );
                                        
                                    })}    
                                </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Cultivo))