import React from "react";
import NavigationDiag from "../components/NavigationDiag";

function LayoutDiag(props) {
    return (
        <>
            <NavigationDiag title={props.title}/>
            <div className={`${props.classText}`}>
                <div>
                    <div className="">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LayoutDiag