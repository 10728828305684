import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import './styles.css';
import {url_api} from "../../constants";
import {url} from "../../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';

const MySwal = withReactContent(Swal)
class Cultivo extends Component {
    

    constructor() {
        super();
        this.state = {
            cultivos : [],
            nombre_cultivo: '',
            file: '',
            imagePreviewUrl: '',
            descripcion:"",
            temporadas :  [],
            nombre_temporada: '',
            efecto:'',
            id:'',
            editingcul: false,
            editingtemp: false,
            item: '',
            list: [],
            open: 0,
            /*inputList: [{
                nombre: "", 
                image:"",
                descripcion:""
            }],
            inputListTemp: [{
                nombre: "", 
            }]*/
        }
        this.submitCultivo = this.submitCultivo.bind(this);
        this.submitTemporada = this.submitTemporada.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount = async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const cult = await fetch(`${url_api}cultivos/`, options);
        const cultivos = await cult.json();
        console.log(cultivos)
    
        const temp = await fetch(`${url_api}temporadas/`, options);
        const temporadas = await temp.json();
    
        if(!temporadas.detail){     
            this.setState({temporadas:temporadas,  cultivos:cultivos}) 
        }              
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    /*handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.inputList];
        list[index][name] = value;
        this.setState({inputList: list});
        console.log({inputList: list})
    };

    handleInputChangeTemp = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.inputListTemp];
        list[index][name] = value;
        this.setState({inputListTemp: list});
        console.log({inputListTemp: list})
    };
     
    // handle click event of the Remove button
    handleRemoveClick = index => {
        const list = [...this.state.inputList];
        list.splice(index, 1);
        this.setState({inputList: list});
    };

     // handle click event of the Remove button
     handleRemoveClickTemp = index => {
        const list = [...this.state.inputListTemp];
        list.splice(index, 1);
        this.setState({inputListTemp: list});
    };
     
    // handle click event of the Add button
    handleAddClick = () => {
        this.setState((prevState) => ({
            inputList: [...prevState.inputList, {
                nombre: "", 
            }],
        }));
    };

    // handle click event of the Add button
    handleAddClickTemp = () => {
        this.setState((prevState) => ({
            inputListTemp: [...prevState.inputListTemp, {
                nombre: "", 
            }],
        }));
    };*/
    
    handleImageChange(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let file = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
    
        reader.readAsDataURL(file)
      }

    clickImage() {
        let imagen = document.querySelector('#image');
        imagen.click();
    }
    //crear y editar cultivos
    submitCultivo= async (e) =>{
        e.preventDefault();

        if (this.state.editingcul===false) {
            /*const res = await fetch(`${url_api}cultivos/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    nombre: this.state.nombre_cultivo,
                    image: this.state.image,
                    description: this.state.description
                })
            }) 
            
            const data = await res.json();
            console.log(data);
            this.state.inputList.map((x) => {
                let form_data = new FormData();
                form_data.append('nombre', x.nombre);
                form_data.append('cultivo', data.id);
                return (
                    axios.post(`${url_api}variedades/`, form_data, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': `Token ${this.props.token}`
                        }
                    }).then(res => {
                        console.log('res',res);
                        this.componentDidMount();
                    }).catch(
                       function (error) {
                           console.log('err',error.response);
                           
                       }
                     )
                    
                );                                              
            })
            
            
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Cultivo...',
                      showConfirmButton: false,
                      timer: 2000,
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                             result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente el Cultivo',
                                  showConfirmButton: true,
                              })                        
                          }
                  })
                  this.componentDidMount();

                  this.setState({
                      nombre_cultivo: '',
                      image:'',
                      description:''
                  })
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: [data],
                      showConfirmButton: true
                    })
              } */
              let form_data = new FormData();
              form_data.append('imagen', this.state.file);
              form_data.append('nombre', this.state.nombre_cultivo);
              form_data.append('descripcion', this.state.descripcion);
              
              const resu = axios.post(`${url_api}cultivos/`, form_data, {
                  headers: {
                      'content-type': 'multipart/form-data',
                      'Authorization': `Token ${this.props.token}`
                  }
              })
              .then(res => {
                  console.log(res);
                 let timerInterval
                 MySwal.fire({
                     title: 'Guardando Cultivo...',
                     showConfirmButton: false,
                     timer: 2000,
                     customClass: {
                         title:'title-class',
                         popup:'popup-class',
                     },
                     willOpen: () => {
                         MySwal.showLoading()
                                                
                     },
                     willClose: () => {
                         clearInterval(timerInterval)
                     }
                 }).then((result) => {
                         if (
                             /* Read more about handling dismissals below */
                             result.dismiss === MySwal.DismissReason.timer
                         ) {
                             MySwal.fire({
                                 icon: 'success',
                                 title: 'Se Creo Correctamente el Cultivo',
                                 showConfirmButton: true,
                                 buttonsStyling: false,
                                 customClass: {
                                     title:'title-class',
                                     popup:'popup-class',
                                     confirmButton: 'confirmbutton-class',
                                 },
                             })                        
                         }
                 })
                 
          
                 this.componentDidMount();
                 this.setState({
                     file: "",
                     nombre_cultivo: "",
                     descripcion: "",
                     imagePreviewUrl: ""
                 })
                 
              }).catch(
                 function (error) {
                     console.log(error.response.data);
                     MySwal.fire({
                         icon: 'warning',
                         title: [error.response.data.nombre,'</br>', error.response.data.file],
                         showConfirmButton: true,
                         buttonsStyling: false,
                         customClass: {
                             title:'title-class',
                             popup:'popup-class',
                             confirmButton: 'confirmbutton-class',
                         },
                       })
                 }
               )
        } else {
            const id=this.state.id;
            /*this.state.list.map((x) => {
                return (
                       fetch(`${url_api}variedades/${x.id}/`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${this.props.token}`
                        },
                    }) 
                );
                                                
            })*/
            let form_data = new FormData();
            form_data.append('imagen', this.state.file);
            form_data.append('nombre', this.state.nombre_cultivo);
            form_data.append('descripcion', this.state.descripcion);
            
            const res = await axios.put(`${url_api}cultivos/${id}/`, form_data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Token ${this.props.token}`
                }
            })
            .then(res => {
                console.log(res.data);
                let timerInterval
                MySwal.fire({
                    title: 'Actualizando Cultivo...',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente el Cultivo',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                            })                        
                        }
                })
                
         
                this.componentDidMount();

                this.setState({
                    nombre_cultivo: '',
                    image:'',
                    description:''
                })
                
            }).catch(
                function (error) {
                    console.log(error);
                    MySwal.fire({
                        icon: 'warning',
                        title: [error.response],
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                        },
                      })
                }
              )
               
            
           
                this.state.editingcul = false;
                this.state.id= "";
          
        }     
        

        
    }

    // muetra datos para editar cultivos
    editCultivo = async (id) => {
        
        const res = await fetch(`${url_api}cultivos/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const data = await res.json();

        this.state.editingcul = true;
        this.state.id= id;
        /*const res_var = await fetch(`${url_api}variedades/`, {
            method: "GET",
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
          });
        const items_var = await res_var.json();

        let list = items_var.filter(items_var => items_var.cultivo === data.id);
*/
        this.setState({
            nombre_cultivo: data.nombre,
            descripcion: data.descripcion,
            file: data.imagen,
            imagePreviewUrl: data.imagen   
        })
       
        //this.setState({inputList: list, list: list}) 
       
   
        
    };

    //elimina datos
    deleteCultivo = async (id) => {
        
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then( async(result) => {
            if (result.isConfirmed) {
                
                await fetch(`${url_api}cultivos/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                });
                MySwal.fire({
                    icon: 'Success',
                    title: 'Eliminado!',
                    text: 'El Registro ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  });
            }
            this.componentDidMount();
          })
    };


    //crear y editar temporadas
    submitTemporada= async (e) =>{
        e.preventDefault();
        if (this.state.editingtemp===false) {
            const res = await fetch(`${url_api}temporadas/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    nombre: this.state.nombre_temporada,
                })
            }) 
            const data = await res.json();
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Temporada...',
                      showConfirmButton: false,
                      timer: 2000,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente la Temporada',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                  },
                              })                        
                          }
                  })
                   this.componentDidMount();

                    this.setState({
                        nombre_temporada: '',
                    })
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: 'Revisa tus Datos',
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                      },
                    })
              } 

        } else {
            const id=this.state.id;
           
            const res = await fetch(`${url_api}temporadas/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    nombre: this.state.nombre_temporada,
                }),
              });
              const data = await res.json();
              if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Actualizando Temporada...',
                      showConfirmButton: false,
                      timer: 2000,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                      },
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Actualizo Correctamente la Temporada',
                                  showConfirmButton: true,
                                  buttonsStyling: false,
                                  customClass: {
                                      title:'title-class',
                                      popup:'popup-class',
                                      confirmButton: 'confirmbutton-class',
                                  },
                              })                        
                          }
                  })
                   this.componentDidMount();

                    this.setState({
                        nombre_temporada: '',
                    })
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: 'Revisa tus Datos',
                      showConfirmButton: true,
                      buttonsStyling: false,
                      customClass: {
                          title:'title-class',
                          popup:'popup-class',
                          confirmButton: 'confirmbutton-class',
                      },
                    })
              } 
            
            this.state.editingtemp = false;
            this.state.id= "";
        }     
        

        

    }

    // muetra datos para editar cultivos
    editTemporada = async (id) => {
            
        const res = await fetch(`${url_api}temporadas/${id}/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`,
            },
        });
        const data = await res.json();

        this.state.editingtemp = true;
        this.state.id= id;
        
        this.setState({
            nombre_temporada: data.nombre,
        })
    
        
    };

    //elimina datos
    deleteTemporada = async (id) => {
        
        MySwal.fire({
            title: '¿Desea Eliminar?',
            text: "¡No Podra Recuperar este registro!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No Eliminar!',
            confirmButtonText: 'Si, Eliminar!',
            buttonsStyling: false,
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
            },
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}temporadas/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Token ${this.props.token}`,
                    },
                }).then(res => {
                    MySwal.fire({
                        icon: 'Success',
                        title: 'Eliminado!',
                        text: 'El Registro ha sido Eliminado Correctamente.',
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                            cancelButton: 'cancelbutton-class'
                        },
                      });
                    this.componentDidMount();
                })
                .catch(err => console.log(err))
                
                
            }
            this.componentDidMount();
          })
    };

    render() {
        
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = 'admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div className="previewText">Selecciona una foto (opcional)</div>);
        }
        return (
            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    
                    <div className="grid grid-cols-12">
                        <div className="col-span-6 rounded pt-10 m-5">
                            <h2 className="font-bold text-primary my-4">Cultivos </h2>
                            <div className="bg-white rounded pt-10">                            
                                    <form onSubmit={this.submitCultivo} className="form bg-white rounded grid grid-cols-5 shadow-md py-8 mr-2">
                                        <div className=" form bg-white rounded col-span-5 text-center overflow-hidden ">
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Nombre Cultivo:   </label>
                                                <input
                                                    id="nombre_cultivo"
                                                    name="nombre_cultivo"
                                                    type="text"
                                                    value={this.state.nombre_cultivo} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Nombre Cultivo ej. Aguacate"
                                                    required
                                                    title="Favor de Agregar Nombre del Cultivo. ej. Aguacate"
                                                />                                                
                                            </div>                                            
                                        </div>  
                                        <div className=" form bg-white rounded col-span-5 text-center overflow-hidden ">
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Descripcion Cultivo:   </label>
                                                <input
                                                    id="descripcion"
                                                    name="descripcion"
                                                    type="text"
                                                    value={this.state.descripcion} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Descripcion Cultivo ej. Aguacate"
                                                    required
                                                    title="Favor de Agregar una Descripcion del Cultivo. ej. Aguacate"
                                                />                                                
                                            </div>                                            
                                        </div> 
                                        <div className=" custom-input form bg-white rounded col-span-5 text-center overflow-hidden">
                                            <div className="custom-input-file">
                                                <input className="file-input" 
                                                type="file" 
                                                name="img"
                                                id="image"
                                                title="Favor de Agregar una Foto de Perfil (opcional)"
                                                onChange={this.handleImageChange} />
                                                <div className="imgPreview">
                                                    {$imagePreview}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage() } className="icon-img inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>    
                                        <div className="form bg-white rounded col-span-5 text-center overflow-hidden m-2">
                                            <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center ">{this.state.editingcul ? "Actualizar Cultivo" : "Guardar Cultivo"}</button>
                                        </div>                        
                                    </form>                                    
                           </div>
                           <h2 className="font-bold text-primary mt-4">Lista de Cultivos </h2>
                            <div className="col-span-12 grid grid-cols-3  gap-4 mt-6">
                            
                                {this.state.cultivos.map((cultivo, i)=> {
                                    return (
                                        <div key={cultivo.id} id={cultivo.id} className="rounded bg-white shadow-md grid grid-cols-12  gap-4 p-4 ">
                                            <div  className="col-span-12">
                                                <h6 className="pl-title my-1">Nombre del Cultivo</h6>
                                                <p className="pl-value my-1">{cultivo.nombre} </p>
                                                <h6 className="pl-title my-1">Descripcion del Cultivo</h6>
                                                <p className="pl-value my-1">{cultivo.descripcion} </p>
                                                <h6 className="pl-title my-1">Descripcion del Cultivo</h6>
                                                <p className="pl-value my-1"><img className="detail-img mx-5" src={`${cultivo.imagen}`}/> </p>
                                               
                                            </div>
                                            <div  className="col-span-12">
                                                <div>
                                                <button onClick={(e) => this.editCultivo(cultivo.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteCultivo(cultivo.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}    
                            </div>
                        </div>
                        <div className="col-span-6 rounded pt-10 m-5">
                            <h2 className="font-bold text-primary my-4">Temporadas </h2>
                            <div className="bg-white rounded pt-10">                            
                                    <form onSubmit={this.submitTemporada} className="form bg-white rounded grid grid-cols-5 shadow-md py-8 mr-2">
                                        <div className=" form bg-white rounded col-span-5 text-center overflow-hidden ">
                                            <div className="my-3 mx-6">
                                                <label htmlFor="name" className="self-center pl-title-res-form">Temporada:   </label>
                                                <input
                                                    id="nombre_temporada"
                                                    name="nombre_temporada"
                                                    type="text"
                                                    value={this.state.nombre_temporada} 
                                                    onChange={this.handleChange}
                                                    className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                    placeholder="Temporada ej. 2021-2022"
                                                    required
                                                    title="Favor de Agregar la Temporada. ej. 2021-2022"
                                                />                                                
                                            </div>                                            
                                        </div>  
                                        <div className="form bg-white rounded col-span-5 text-center overflow-hidden m-2">
                                            <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center ">{this.state.editingtemp ? "Actualizar Temporada" : "Guardar Temporada"}</button>
                                        </div>                 
                                    </form>                                    
                           </div>
                           <h2 className="font-bold text-primary mt-4">Lista de Temporadas</h2>
                            <div className="col-span-12 grid grid-cols-2  gap-4 mt-6">
                                {this.state.temporadas.map((temporada, i)=> {
                                    return (
                                        <div key={temporada.id} id={temporada.id} className="rounded bg-white shadow-md grid grid-cols-12  gap-4 p-4 ">
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Temporada</h6>
                                                <p className="pl-value my-1">{temporada.nombre} </p>                                                
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editTemporada(temporada.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteTemporada(temporada.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}    
                            </div>
                        </div>
                        {/*<div className="bg-white col-end-10 col-span-4 rounded pt-10">
                            <h2 className="font-bold text-primary">Temporadas </h2>
                            <div className="bg-white col-span-12 rounded pt-10">
                            
                                <form onSubmit={this.submitTemporada} className="form bg-white rounded grid grid-cols-2 shadow-md py-8">
                                    <div className=" form bg-white rounded col-span-2  overflow-hidden ">
                                        <div className="my-1 mx-6 text-center">
                                            <label htmlFor="name" className="self-center pl-title-res-form">Temporada:   </label>
                                            <input
                                                id="nombre_temporada"
                                                name="nombre_temporada"
                                                type="text"
                                                value={this.state.nombre_temporada} 
                                                onChange={this.handleChange}
                                                className="validate  text-center w-full border-0 text-green-500 focus:outline-none pl-value-res-form"
                                                placeholder="Temporada ej. 2021-2022"
                                                required
                                                title="Favor de Agregar Temporada. ej. 2021-2022"
                                            />                                                
                                        </div>
                                        <div className="p-4 text-center">
                                            <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center  ">{this.state.editingtemp ? "Actualizar Temporada" : "Agregar Temporada"}</button>
                                        </div>
                                    </div>
                                </form>
                                
                            </div>
                            <div className="col-span-12 grid grid-cols-2  gap-4 mt-6">
                                {this.state.temporadas.map((temporada, i)=> {
                                    return (
                                        <div key={i} id={temporada.id} className="rounded shadow-md grid grid-cols-12  gap-4 p-4 h-32">
                                            <div  className="col-span-6">
                                                <h6 className="pl-title my-1">Temporada</h6>
                                                <p className="pl-value my-1">{temporada.nombre} </p>
                                            </div>
                                            <div  className="col-span-6">
                                                <div>
                                                <button onClick={(e) => this.editTemporada(temporada.id)} className="inline-block  py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                    <i className="material-icons">edit</i>
                                                </button>
                                                <button onClick={(e) => this.deleteTemporada(temporada.id)} className="inline-block  py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                    <i className="material-icons">delete</i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}    
                            </div>
                            </div>*/}
                    </div>
                </div>
                
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Cultivo))