import React, {Component} from 'react';
import './HomeAdmin.css';
import {NavLink} from "react-router-dom";
import { Line } from 'react-chartjs-2';
import {connect} from 'react-redux';
import {url_api} from "../../constants";
import {url} from "../../constants";
import productor from '../../assets/img/menu/productor@2x.png';
class HomeProductor extends Component{
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            user: [],
            last: {},
            administrador: 0,
            ingeniero_de_Campo: 0,
            productor: 0,
            chofer: 0,
            quimico: 0,
            cobranza: 0,
            administrativo: 0,
            agrositem: '',
            temporada:'',
            cultivo:'',
            variedad :'',
            cajas: [],
            fechasb: [],
            ventas:[],
        };
    
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'http://testapi.green-up.xyz';
    }

    componentDidMount= async () => {
           
        const resagro = await fetch(`${url_api}agrocostos/`,  {
            headers: {
                'Authorization': `Token ${this.props.authentication.token}`
            },
        });
        const agros = await resagro.json(); 
        if(!agros.detail){
            var preciot= 0;var ventat= 0;var cajast= 0;
            for(let x in agros){preciot += agros[x].precio;}
            for(let x in agros){ventat += agros[x].venta_total;}
            for(let x in agros){cajast += agros[x].no_cajas;}
            let cajas = [];
            agros.map((x) => { 
                cajas.push(x.no_cajas)     
            })
            
            let ventas= [];
            agros.map((x) => { 
                ventas.push(x.venta_total)     
            })

            let fechasb = [];
            agros.map((x) => { 
                fechasb.push(x.fecha)     
            })
            
            const agrositem = agros[agros.length-1];
            console.log(agrositem);
            if (agrositem !== undefined){
                const temporada = agrositem.temporada;
                const cultivo = agrositem.cultivo;
                const variedad = agrositem.variedad;
                            
                this.setState({
                    preciot:preciot,
                    ventat: ventat,
                    cajast: cajast, 
                    cajas:cajas,
                    fechasb:fechasb,
                    ventas:ventas,
                    agrositem:agrositem,
                    temporada:temporada,
                    cultivo:cultivo,
                    variedad:variedad,
                });
            }
        }
         
    }
  

    render() {
        
        console.log(this.state.auth.user);
        const usuario=this.state.user.length;
       
        const data = {
            
            labels: this.state.fechasb,
            
            datasets: [{
              label: 'No Cajas',
              data: this.state.cajas,
              fill: true,
              backgroundColor: '#d1f1a9',
              borderColor: '#A6D66B',
              pointBackgroundColor: '#006A4C',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgb(255, 99, 132)'
            }, {
              label: 'Ventas Total',
              data: this.state.ventas,
              fill: true,
              backgroundColor: '#f1de98',
              borderColor: '#cfad2f',
              pointBackgroundColor: '#F4D35B',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgb(54, 162, 235)'
            }
          
          ]
            
          };
          
          const options =   {
             
              legend:{
                  display:true,
                  position:'top',
                  fontColor: '#A6D66B'
              }
          }
        return (
                <div className="grid md:grid-cols-12 grid-cols-1 gap-32">
                    <div className="col-span-3 ">
                        <h1 className="font-bold text-primary-perfil pb-8">Perfil del Productor</h1>
                        <div className="bg-secondary rounded-full py-8 px-12  text-center">
                            {this.state.auth.user.foto === null  ? 
                                <img src={productor} alt="foto"  className="img-perfil"/>:
                                <img className="img-perfil" alt="foto"  src={`${url}${this.state.auth.user.foto}`}/>}
                            <h3 className="text-white pt-10 pb-2">Nombre</h3>
                            <p className="text-bold">{this.state.auth.user.first_name } {this.state.auth.user.last_name }</p>
                            <h3 className="text-white pt-10 pb-2">Correo</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.email }</p>
                            <h3 className="text-white pt-10 pb-2">Numero</h3>
                            {/*<p className="text-white  text-bold">{this.state.auth.user.telefono }</p>
                            <h3 className="text-white pt-10 pb-2">Cargo</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.cargo }</p>
                            <h3 className="text-white pt-10 pb-2">Estado</h3>
                            <p className="text-white  text-bold">{this.state.auth.user.estado }</p>
                            <h3 className="text-white pt-10 pb-2">Contraseña</h3>
                            <p className="text-white  text-bold pb-2">{this.state.user.password } 
                                <NavLink to="/usuarios" className="inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none" ><i className='fas fa-pen'></i></NavLink>
                            </p>*/}
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 col-span-4 xl:col-span-8 xl:grid-cols-3  gap-4"> 
                        <div className="col-span-2">
                            <h1 className="font-bold text-primary-reciente pb-8 float-none">Reciente </h1>
                            <div className="bg-four rounded-lg p-5 mb-6 text-center">
                                <h3 className="font-bold text-secundary-reciente">Estadisticas </h3>
                                    <div className='flex text-center grid grid-cols-2 gap-4'>
                                        <p className="text-secondary-n-user m-4 pb-2">Cajas Totales: {this.state.cajast }</p>
                                        <p className="text-secondary-n-user m-4 pb-2">Ventas Totales: {this.state.ventat }</p>
                                        
                                    </div>
                                    <Line data={data}
                                        width={100}
                                        height={50}
                                        options={options}
                                        /> 
                                
                            </div>
                            <div className="bg-green-100  grid grid-cols-1 rounded-lg py-12 px-8 mb-6 text-center">
                                <div className="text-left text-primary-new-user ">
                                    <h3 className="font-bold text-secundary-reciente">Ultimo Agrocosto Agregado </h3>
                                    <p className="text-secondary-n-user pb-2">Fecha: {this.state.agrositem.fecha }</p>
                                    <p className="text-secondary-n-user pb-2">Responsable: {this.state.agrositem.responsable }</p>
                                    <p className="text-secondary-n-user pb-2">Temporada: {this.state.temporada.nombre }</p>
                                    <p className="text-secondary-n-user pb-2">Cultivo: {this.state.cultivo.nombre }</p>
                                    <p className="text-secondary-n-user pb-2">Variedad: {this.state.variedad.nombre }</p>
                                    <p className="text-secondary-n-user pb-2">Cajas: {this.state.agrositem.no_cajas }</p>
                                    <p className="text-secondary-n-user pb-2">Precio: {this.state.agrositem.precio }</p>
                                    <p className="text-secondary-n-user pb-2">Total: {this.state.agrositem.venta_total }</p>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {authentication} = state;
    return {
        authentication
    }
}

export default connect(mapStateToProps)(HomeProductor);