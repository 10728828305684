import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import productor from '../../assets/img/menu/productor@2x.png';
import './styles.css';
import axios from 'axios';
import {url_api} from "../../constants";
import {url} from "../../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
class Usuarios extends Component {
    

    constructor() {
        super();
        this.state = {
            users : [],
            usersback : [],
            form: [],
            first_name: '',
            last_name:'',
            email: '',
            phone: '',
            password:'',
            id:'',
            file: '',
            imagePreviewUrl: '',
            cargo: '',
            editing: false,
            is_administrador: false,
            is_ingeniero_de_Campo: false,
            is_productor: false,
            is_chofer: false,
            is_quimico: false,
            is_cobranza: false,
            is_administrativo: false,
            administrador: 0,
            ingeniero_de_Campo: 0,
            productor: 0,
            chofer: 0,
            quimico: 0,
            cobranza: 0,
            administrativo: 0,
            vacio:false,
            currentPage: 1,
            todosPerPage: 10,
            text:  ''
        }
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        
        this.handleClicknext = this.handleClicknext.bind(this);
        this.handleClickprev = this.handleClickprev.bind(this);
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'https://testapi.green-up.xyz/api';
    }

    componentDidMount = async () => {
       
        const res = await fetch(`${url_api}users/`, {
            headers: {
                'Authorization': `Token ${this.props.token}`
            },
        });
        const users = await res.json();  
        if(!users.detail){
            users.map((x) => {
                if(x.is_administrador === true) { this.state.administrador= this.state.administrador+1}
                if(x.is_ingeniero_de_Campo === true) {this.state.ingeniero_de_Campo = this.state.ingeniero_de_Campo+1} 
                if(x.is_productor === true) {this.state.productor = this.state.productor+1} 
                if(x.is_chofer === true) {this.state.chofer = this.state.chofer+1} 
                if(x.is_quimico === true) {this.state.quimico = this.state.quimico+1} 
                if(x.is_cobranza === true) {this.state.cobranza = this.state.cobranza+1} 
                if(x.is_administrativo === true) {this.state.administrativo = this.state.administrativo+1}  
            })
    
            this.setState({users:users, usersback:users})
        }   
        
            
    }

    handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    handleImageChange(event) {
        event.preventDefault();
    
        let reader = new FileReader();
        let file = event.target.files[0];
        
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result
          });
        }
    
        reader.readAsDataURL(file)
      }

    clickImage() {
        let imagen = document.querySelector('#image');
        imagen.click();
    }

    submit= async (e) =>{
        e.preventDefault();

        if(this.state.cargo === 'is_administrador') {this.state.is_administrador = true;} 
        if(this.state.cargo === 'is_ingeniero_de_Campo') {this.state.is_ingeniero_de_Campo = true;}
        if(this.state.cargo === 'is_productor') {this.state.is_productor = true;}
        if(this.state.cargo === 'is_chofer') {this.state.is_chofer = true;}
        if(this.state.cargo === 'is_quimico') {this.state.is_quimico = true;}
        if(this.state.cargo === 'is_cobranza') {this.state.is_cobranza = true;}
        if(this.state.cargo === 'is_administrativo') {this.state.is_administrativo = true;}

        if (this.state.editing===false) {
            /*const res = await fetch(`${this.url}/api/users/`, {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'multipart/form-data',
                     'Accept': 'application/json',
                     'Authorization': `Token ${this.props.token}`
                 },
                 body: JSON.stringify({
                     first_name: this.state.first_name,
                     last_name: this.state.last_name,
                     email: this.state.email,
                     telefono: this.state.phone,
                     password: this.state.password,
                     foto: this.state.file,
                     is_administrador: this.state.is_administrador,
                     is_ingeniero_de_Campo: this.state.is_ingeniero_de_Campo,
                     is_productor: this.state.is_productor,
                     is_chofer: this.state.is_chofer,
                     is_quimico: this.state.is_quimico,
                     is_cobranza: this.state.is_cobranza,
                     is_administrativo: this.state.is_administrativo,
                     factor: "0",
                     razonSocial: "",
                     rfc: "",
                     nombreResponsableDelCultivo: "",
                     telefonoResponsableCultivo: "",
                     username: this.state.email
                 })
             })
             const respuesta = await res.json();console.log(respuesta);*/
             let form_data = new FormData();
             form_data.append('foto', this.state.file);
             form_data.append('first_name', this.state.first_name);
             form_data.append('last_name', this.state.last_name);
             form_data.append('email', this.state.email);
             form_data.append('telefono', this.state.phone);
             form_data.append('password', this.state.password);
             form_data.append('is_administrador', this.state.is_administrador);
             form_data.append('is_ingeniero_de_Campo', this.state.is_ingeniero_de_Campo);
             form_data.append('is_productor', this.state.is_productor);
             form_data.append('is_chofer', this.state.is_chofer);
             form_data.append('is_quimico', this.state.is_quimico);
             form_data.append('is_cobranza', this.state.is_cobranza);
             form_data.append('is_administrativo', this.state.is_administrativo);
             form_data.append('factor', "0");
             form_data.append('razonSocial', "");
             form_data.append('rfc', "");
             form_data.append('nombreResponsableDelCultivo', "");
             form_data.append('telefonoResponsableCultivo', "");
             form_data.append('username', this.state.email);
             
             const resu = axios.post(`${url_api}users/`, form_data, {
                 headers: {
                     'content-type': 'multipart/form-data',
                     'Authorization': `Token ${this.props.token}`
                 }
             })
             .then(res => {
                 console.log(res);
                let timerInterval
                MySwal.fire({
                    title: 'Guardando Usuario...',
                    showConfirmButton: false,
                    timer: 2000,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                      },
                      
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                        if (
                            /* Read more about handling dismissals below */
                            result.dismiss === MySwal.DismissReason.timer
                        ) {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se Creo Correctamente el Usuario',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                            })                        
                        }
                })
                
         
                this.componentDidMount();
                this.setState({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    cargo: "",
                    password: "",
                    is_administrador: false,
                    is_ingeniero_de_Campo: false,
                    is_productor: false,
                    is_chofer: false,
                    is_quimico: false,
                    is_cobranza: false,
                    is_administrativo: false,
                    
                })
                this.setState({
                    imagePreviewUrl: ""  
                })
                
             }).catch(
                function (error) {
                    console.log(error.response.data);
                    MySwal.fire({
                        icon: 'warning',
                        title: [error.response.data.email,'</br>', error.response.data.username],
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                            cancelButton: 'cancelbutton-class'
                        },
                      })
                }
              )
             
         } else {
            //console.log(this.state.file);
             const id=this.state.id;
             let form_data = new FormData();
             form_data.append('first_name', this.state.first_name);
             form_data.append('last_name', this.state.last_name);
             form_data.append('email', this.state.email);
             form_data.append('telefono', this.state.phone);
             form_data.append('password', this.state.password);
             form_data.append('is_administrador', this.state.is_administrador);
             form_data.append('is_ingeniero_de_Campo', this.state.is_ingeniero_de_Campo);
             form_data.append('is_productor', this.state.is_productor);
             form_data.append('is_chofer', this.state.is_chofer);
             form_data.append('is_quimico', this.state.is_quimico);
             form_data.append('is_cobranza', this.state.is_cobranza);
             form_data.append('is_administrativo', this.state.is_administrativo);
             form_data.append('factor', "0");
             form_data.append('razonSocial', "");
             form_data.append('rfc', "");
             form_data.append('nombreResponsableDelCultivo', "");
             form_data.append('telefonoResponsableCultivo', "");
             form_data.append('username', this.state.email);
            
            const res = await axios.put(`${url_api}users/${id}/`, form_data, {
                 headers: {
                     'content-type': 'multipart/form-data',
                     'Authorization': `Token ${this.props.token}`
             }
             })
             .then(res => {
                 console.log(res.data);
                 let timerInterval
                 MySwal.fire({
                    title: 'Actualizando Usuario...',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                    },
                     willOpen: () => {
                         MySwal.showLoading()
                                                
                     },
                     willClose: () => {
                         clearInterval(timerInterval)
                     }
                 }).then((result) => {
                         if (
                             /* Read more about handling dismissals below */
                             result.dismiss === MySwal.DismissReason.timer
                         ) {
                             MySwal.fire({
                                icon: 'success',
                                title: 'Se Actualizo Correctamente el Usuario',
                                showConfirmButton: true,
                                buttonsStyling: false,
                                customClass: {
                                    title:'title-class',
                                    popup:'popup-class',
                                    confirmButton: 'confirmbutton-class',
                                    cancelButton: 'cancelbutton-class'
                                },
                             })                        
                         }
                 })
                 
          
                 this.componentDidMount();
                 this.setState({
                     first_name: "",
                     last_name: "",
                     email: "",
                     phone: "",
                     cargo: "",
                     password: "",
                     is_administrador: false,
                     is_ingeniero_de_Campo: false,
                     is_productor: false,
                     is_chofer: false,
                     is_quimico: false,
                     is_cobranza: false,
                     is_administrativo: false,
                     
                 })
                 
             }).catch(
                function (error) {
                      MySwal.fire({
                        icon: 'warning',
                        title: [error.response.data.email,'</br>', error.response.data.username],
                        showConfirmButton: true,
                        buttonsStyling: false,
                        customClass: {
                            title:'title-class',
                            popup:'popup-class',
                            confirmButton: 'confirmbutton-class',
                            cancelButton: 'cancelbutton-class'
                        },
                      })
                      this.editUser(id);                
                    }.bind(this)
              )
             
             const type =typeof (this.state.file);
             const  object = 'object';
             if (type === object){
                let form_data = new FormData();
                form_data.append('foto', this.state.file);
            
                const res = await axios.put(`${url_api}foto_perfil/${id}/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Token ${this.props.token}`
                }
                })    
                this.componentDidMount();
                this.setState({
                    imagePreviewUrl: ""  
                })           
             }
             
             this.state.editing = false;
             this.state.id= "";
         }     
         
    }
    editUser = async (id) => {
        const res = await fetch(`${url_api}users/${id}`, {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Token ${this.props.token}`
            },
          });
        const data = await res.json();

        this.state.editing = true;
        this.state.id= id;
        if(data.is_administrador === true) {this.state.cargo = 'is_administrador'; this.state.is_administrador=false;} 
        if(data.is_ingeniero_de_Campo === true) {this.state.cargo = 'is_ingeniero_de_Campo'; this.state.is_ingeniero_de_Campo=false;}
        if(data.is_productor === true) {this.state.cargo = 'is_productor'; this.state.is_productor=false;}
        if(data.is_chofer === true) {this.state.cargo = 'is_chofer'; this.state.is_chofer=false;}
        if(data.is_quimico === true) {this.state.cargo = 'is_quimico'; this.state.is_quimico=false;}
        if(data.is_cobranza === true) {this.state.cargo = 'is_cobranza'; this.state.is_cobranza=false;}
        if(data.is_administrativo === true) {this.state.cargo = 'is_administrativo'; this.state.is_administrativo=false;}
                
        this.setState({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.telefono,
            password: data.password
            
        })

        this.setState({
            file: url+data.foto,
            imagePreviewUrl: url+data.foto    
        })
        
      };
    deleteUser = async (id) => {
        MySwal.fire({
            title: 'Eliminar Usuario',
            text: "Estas seguro, No Podras Recuperarlo!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Borrar!',
            customClass: {
                title:'title-class',
                popup:'popup-class',
                confirmButton: 'confirmbutton-class',
                cancelButton: 'cancelbutton-class'
              },
              buttonsStyling: false
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}users/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'authorization': `Token ${this.props.token}`
                    }
                  });
                  MySwal.fire({
                    icon: 'Success',
                    title: 'Borrado!',
                    text: 'El Usuario ha sido Eliminado Correctamente.',
                    showConfirmButton: true,
                    buttonsStyling: false,
                    customClass: {
                        title:'title-class',
                        popup:'popup-class',
                        confirmButton: 'confirmbutton-class',
                        cancelButton: 'cancelbutton-class'
                    },
                  });
              this.componentDidMount();
            }
            this.componentDidMount();
          })
          
    };
    handleClicknext(event) {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.state.users.length / this.state.todosPerPage); i++) {
        pageNumbers.push(i);
    }
    
    
    if (pageNumbers.length != this.state.currentPage){
        const next = this.state.currentPage+1;
        this.setState({
            currentPage: next
            });
    }
    }
    handleClickprev(event) {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.state.users.length / this.state.todosPerPage); i++) {
        pageNumbers.push(i);
    }
    
    if ( 1 != this.state.currentPage){
        const prev = this.state.currentPage-1;
        this.setState({
            currentPage: prev
            });
    }
    }
    filter(event){
    var text = event.target.value;
    const data = this.state.usersback;

    
    const newData = data.filter(function(item){
        const itemDataNombre = item.first_name.toUpperCase();
        const itemDataApellido = item.last_name.toUpperCase();
        const itemid = item.id;
        const campo = itemDataNombre+" "+itemDataApellido+""+itemid;
        const textDate = text.toUpperCase();
        const numDate = text;
        return campo.indexOf(textDate||numDate) > -1;
    });
    if (newData.length===0){
        const vacio = true;
        this.setState({
            text: text,
            users:newData,
            vacio:vacio
        });
    }else{
        const vacio = false;
    this.setState({
        text: text,
        users:newData,
        vacio:vacio
    });   }    
    }
    render() {
        console.log(this.state.users)
        let clase = '';
        if(this.props.user.is_administrador) {
            clase = ' admin-ordenes';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div className="previewText">Selecciona una foto de perfil (opcional)</div>);
        }
        
        const { users, currentPage, todosPerPage } = this.state;
         
        // Logic for displaying current todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const usuarios = users.slice(indexOfFirstTodo, indexOfLastTodo);

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(users.length / todosPerPage); i++) {
          pageNumbers.push(i);
        }
        
        return (

            <Layout className={clase} classText={clase}>
                <div className="admin-usuarios mb-4">
                    <h2 className="font-bold text-primary my-4">Administración de usuarios</h2>
                    
                    <div className="grid grid-cols-12 gap-4">
                        <div className="form bg-white col-span-10 rounded p-8 shadow-md">
                            <h2 className="font-bold text-primary mb-4">Agregar un nuevo usuario</h2>
                            <form onSubmit={this.submit} >
                                <div className="grid grid-cols-2  gap-4">
                                    <div>
                                        <div className="tooltip flex my-8 mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label htmlFor="name" className="self-center">Nombre(s)</label>
                                            <input
                                                id="first_name"
                                                name="first_name"
                                                value={this.state.first_name} 
                                                onChange={this.handleChange}
                                                type="text"
                                                className="input-user validate w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none "
                                                placeholder="Nombre(s) ej. john"
                                                required
                                                title="Favor de Agregar tu(s) Nombre(s). ej. john"
                                            />
                                        </div>
                                        <div className="flex my-8 mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label htmlFor="lastname" className="self-center">Apellido(s)</label>
                                            <input
                                                id="lastname"
                                                name="last_name"
                                                value={this.state.last_name} 
                                                onChange={this.handleChange}
                                                type="text"
                                                className="input-user validate w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none"
                                                placeholder="Apellidos ej. Perez"
                                                required                                                
                                                title="Favor de Agregar tu(s) Apellido(s). ej. Perez"
                                            />
                                            
                                        </div>
                                        <div className="flex my-8 mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label htmlFor="email" className="self-center" >Correo electrónico</label>
                                            <input
                                                id="email"
                                                name="email"
                                                value={this.state.email} 
                                                onChange={this.handleChange}
                                                type="email"
                                                className="input-user validate w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none"
                                                placeholder="ejemplo@green-up.mx"
                                                required
                                                title="Favor de Agregar tu email. ej. ejemplo@green-up.mx"
                                            />
                                            
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label className="self-center">Cargo</label>
                                            <div className="block appearance-none  w-full bg-white px-4 py-2 pr-8 rounded">
                                                <select id="cargo" name="cargo" value={this.state.cargo} onChange={this.handleChange} className=" w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none">
                                                    <option value="">Selecciona un Cargo</option>
                                                    <option value="is_ingeniero_de_Campo">Ingeniero de Campo</option>
                                                     <option value="is_quimico">Laboratorio</option>
                                                    <option value="is_chofer">Chofer</option>
                                                    <option value="is_administrativo">Administración</option>
                                                    <option value="is_administrador">Administrador</option>
                                                    <option value="is_cobranza">Cobranza</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="flex my-8 mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label htmlFor="tel" className="self-center">Teléfono</label>
                                            <input
                                                id="tel"
                                                name="phone"
                                                value={this.state.phone} 
                                                onChange={this.handleChange}
                                                type="tel"
                                                pattern="[0-9]{10}"
                                                className="input-user validate w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none"
                                                placeholder="Teléfono ej. 4521151278"
                                                required
                                                maxLength="10"
                                                title="Favor de Agregar tu Teléfono de 10 Digitos. ej. 4521151278"
                                            />
                                            
                                        </div>
                                        <div className="flex my-8 mx-4 md:mx-2 border-b-2 border-green-500 text-green-500 hover:border-green-800">
                                            <label htmlFor="pass" className="self-center">Contraseña</label>
                                            <input
                                                id="pass"
                                                name="password"
                                                type="password"
                                                value={this.state.password} 
                                                onChange={this.handleChange}
                                                className="input-user validate w-full py-3 pl-2 md:pl-8 border-0 focus:outline-none"
                                                placeholder="Contraseña, Mayor a 8 Caracteres "
                                                required
                                                minLength="8"
                                                title="Favor de Agregar una Contraseña Mayor a 8 Caracteres."
                                            />
                                            
                                        </div>
                                        <div className="custom-input">
                                            <div className="custom-input-file">
                                                <input className="file-input" 
                                                type="file" 
                                                name="img"
                                                id="image"
                                                title="Favor de Agregar una Foto de Perfil (opcional)"
                                                onChange={this.handleImageChange} />
                                                <div className="imgPreview">
                                                    {$imagePreview}
                                                </div>
                                            </div>
                                            <div  onClick={ (e) => this.clickImage() } className="icon-img inline-block py-2 px-3 text-center text-white transition bg-yellow-400 rounded-full shadow ripple">
                                            <i className="material-icons">edit</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center ">{this.state.editing ? "Actualizar Usuario" : "Guardar Usuario"}</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-span-2 grid grid-cols-1  gap-4">
                            {/*<div className="bg-four rounded shadow-md p-8">
                                Estadisticas
                            </div>*/}
                            <div className="bg-secondary rounded shadow-md p-8 text-center">
                                <img src={productor} alt="usuarios" className="responsive-img"/>
                                <h3 className="text-bold text-white">{this.state.users.length} usuarios</h3>
                                <p className="text-white">{this.state.administrador} Administrador</p>
                                <p className="text-white">{this.state.ingeniero_de_Campo} Ingenieros de Campo</p>
                                <p className="text-white">{this.state.productor} Productores</p>
                                <p className="text-white">{this.state.chofer} Choferes</p>
                                <p className="text-white">{this.state.quimico} Laboratorio</p>
                                <p className="text-white">{this.state.cobranza} Cobranza</p>
                                <p className="text-white">{this.state.administrativo} Administrativos</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="usuarios w-full divide-y bg-white rounded p-12 mb-4 shadow-md">

                    <div className="flex justify-between items-center text-center">
                            
                            <div className="my-1 mx-2 flex items-center">
                                <h2 className="font-bold text-primary mr-4">Listado de usuarios </h2>                
                                <div className="border-2 border-green-200 text-green-500 rounded text-center flex items-center search">
                                <input value={this.state.text} onChange={(text) => this.filter(text)}
                                    className="in-search input-user"
                                    placeholder="Buscar por ID o Nombre"
                                    title="Favor de Agregar acentos si se requiere"
                                /> 
                                <i className="material-icons">search</i>
                                </div>
                            </div>
                            <div>
                                <button  
                                    className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                    onClick={(e) => this.handleClickprev(this.state.currentPage)}>Anterior</button>
                                <p className="bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center">
                                    {this.state.currentPage} 
                                </p>  
                                {/*pageNumbers.map(number => {
                                    return (
                                        
                                        <button key={number}
                                        id={number}
                                        onClick={this.handleClick}
                                        className={number == this.state.currentPage ? "bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center": "bg-white w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-black border-2 border-green-500 rounded-full hover:bg-green-500 inline-flex items-center justify-center"}
                                        >{number}</button>
                                        
                                    );
                                })*/}                                     
                                <button  
                                    className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                    onClick={(e) => this.handleClicknext(this.state.currentPage)}>Siguiente</button>
                                <p>Páginas {this.state.currentPage} de {pageNumbers.length} </p>
                            </div>
                        </div>
                    <table className="table-fixed py-8 table-user">
                        <thead>
                            <tr>
                            <th className="w-1/3 px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 tracking-wider">Usuario</th>
                            <th className="w-1/5 px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 tracking-wider">Cargo</th>
                            <th className="w-1/5 px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 tracking-wider">Correo</th>
                            <th className="w-1/5 px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 tracking-wider">Número</th>
                            <th className="w-1/5 px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 tracking-wider"></th>
                            <th className="w-1/5 text-left"></th>
                            </tr>
                        </thead>
                        {this.state.vacio ? 
                        <tbody >
                            <tr><td colSpan="5" className="vacio-search text-center">No se encontraron Coincidencias,<br></br> Por favor realiza una nueva busqueda</td></tr>
                        </tbody>:
                        <tbody>
                            {usuarios.map((user, i)=> {
                                return (
                                    
                                <tr key={user.id}>
                                    <td className="flex py-2 whitespace-no-wrap border-b border-gray-500 ">
                                        {user.foto === null  ? 
                                            <img src={productor} alt="usuarios" className="detail-img mx-5"/>:
                                            <img className="detail-img mx-5" src={`${url}${user.foto}`}/>}
                                            {user.first_name} {user.last_name} 
                                    </td>
                                    <td className="px-5 py-2 whitespace-no-wrap border-b border-gray-500">
                                        {user.is_administrador ? "Administrador": ""}
                                        {user.is_ingeniero_de_Campo ? "Ingeniero de Campo": ""}
                                        {user.is_productor ? "Productor": ""}
                                        {user.is_chofer ? "Chofer": ""}
                                        {user.is_quimico ? "Laboratorio": ""}
                                        {user.is_cobranza ? "Cobranza": ""}
                                        {user.is_administrativo ? "Administrativo": ""}
                                    </td>
                                    <td className="px-5 py-2 whitespace-no-wrap border-b border-gray-500">{user.email}</td>
                                    <td className="px-5 py-2 whitespace-no-wrap border-b border-gray-500">{user.telefono}</td>
                                    <td className="px-5 py-2 whitespace-no-wrap border-b border-gray-500"> 
                                        <button onClick={(e) => this.editUser(user.id)} className="inline-block py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                            <i className="material-icons">edit</i>
                                        </button>
                                        <button onClick={(e) => this.deleteUser(user.id)} className="inline-block py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                            <i className="material-icons">delete</i>
                                        </button>
                                        
                                    </td> 
                                </tr>
                                
                                
                                );
                            }) }
                        </tbody>}
                    </table>
                    
                           
                           
                </div>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Usuarios))