import React from "react";
import Navigation from "../components/Navigation.js";

function Layout(props) {
    return (
        <div className="font-body">
            <Navigation title={props.title}/>
            <div className={`flex-5 flex w-full flex-col content-center items-center ${props.classText}`}>
                <div>
                    <div className="container box-border p-4 mx-auto">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout