import React from "react";
import {connect} from 'react-redux';

import "./Navigation.css";
import menu from "../assets/img/icon-menu.svg";
import logo from '../assets/img/logo-white.svg'
import {authActions} from "../rcrx/actions";
import {withRouter} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {url_api} from "../constants";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            navVisible: false,
            statusn: false,
            stylen: "text-center text-white p-2 subdrop",
            stylern: "text-center text-white p-2 subdrop",
            stylepn: "text-center text-white p-2 subdrop",
            stylenn: "text-center text-white p-2 subdrop",
            statuspl: false,
            styleapl: "text-center text-white p-2 subdrop",
            stylepl: "text-center text-white p-2 subdrop",
        };
        this.droptownnut = this.droptownnut.bind(this);
        this.droptownpl = this.droptownpl.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
    }
    componentDidMount= async () => {
          
        console.log(this.props)
        /*const res = await fetch(`${url_api}`, {
                headers: {
                    'Authorization': `Token ${this.props.token}`
                },
            });
            const api = await res.json();
        if(api.detail){
            
            if(api.detail==="Token inválido."){
                let timerInterval
                MySwal.fire({
                    title: 'Cerrando Sesión...',
                    text: 'Tu Sesión Caduco, Vuele a Iniciarla',
                    showConfirmButton: false,
                    timer: 2000,
                    willOpen: () => {
                        MySwal.showLoading()
                                               
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    if (
                        
                        result.dismiss === MySwal.DismissReason.timer
                    ) {
                       
                        document.getElementById("btnexit").click();                       
                    }
                })
                
            }
        } else{
            const ruta =this.props.location.pathname;
            if(ruta === "/nutricion"){
                const statusn= true;
                const stylen= "text-center text-white p-2"
                this.setState({ statusn: statusn, stylen:stylen })

            }            
            if(ruta === "/referencia-nutricion-cultivo" ){
                const statusn= true;
                const stylern= "text-center text-white p-2"
                this.setState({ statusn: statusn, stylern:stylern })
            }            
            if(ruta === "/productos-nutricion" ){
                const statusn= true;
                const stylepn= "text-center text-white p-2"
                this.setState({ statusn: statusn, stylepn:stylepn })
            }            
            if(ruta === "/nutrientes" ){
                const statusn= true;
                const stylenn= "text-center text-white p-2"
                this.setState({ statusn: statusn, stylenn:stylenn })
            }
            if(ruta === "/plagas-enfermedades"){
                const statuspl= true;
                const stylepl= "text-center text-white p-2"
                this.setState({ statuspl: statuspl, stylepl:stylepl })

            }            
            if(ruta === "/aplicacion-plagas-enfermedades" ){
                const statuspl= true;
                const styleapl= "text-center text-white p-2"
                this.setState({ statuspl: statuspl, styleapl:styleapl })
            }
            
        }*/
    }
    logout() {
        const {dispatch} = this.props;
        dispatch(authActions.logout());
        this.props.history.push("/");
    }
    droptownnut() {
        if(this.state.statusn === false){
            const statusn= true;
            this.setState({ statusn: statusn })
        }else{
            const statusn= false;
            this.setState({ statusn: statusn })
        }
    }
    droptownpl() {
        if(this.state.statuspl === false){
            const statuspl= true;
            this.setState({ statuspl: statuspl })
        }else{
            const statuspl= false;
            this.setState({ statuspl: statuspl })
        }
    }
    toggleNav() {
        this.setState({ navVisible: !this.state.navVisible })
    }

    render() {
        
        return (
            <>
                <header className="flex w-full p-2 pl-5 bg-secondary items-center justify-between">
                    <div className="flex">
                        <img src={menu} className="w-10 mr-4" onClick={this.toggleNav}/>
                        <img src={logo} className="mr-4 logo"/>
                    </div>
                    <div className="font-bold text-primary">
                        {this.props.title !== undefined ? this.props.title : 'Bienvenido de nuevo ' } {this.state.auth.user.first_name !== '' ? this.state.auth.user.first_name : `@${this.state.auth.user.username}` }
                    </div>
                    <div>
                        <button id="btnexit" className="tab-link text-white  bg-green-500 rounded-full px-4 m-2" onClick={this.logout}>Salir</button>
                    </div>
                </header>
                <>
                    {
                        this.state.navVisible ?
                            <div className="z-10 fixed inset-0 transition-opacity">
                                <div className="absolute inset-0 bg-black opacity-25" onClick={this.toggleNav}></div>
                            </div> :
                            ""
                    }
                </>

               { this.props.user.is_administrador ? (
                    <aside className={`transform top-0 left-0 w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${this.state.navVisible ? "translate-x-0": "-translate-x-full"}`}>
                        <img src={logo} className="mx-auto my-2 p-2 pb-8" onClick={this.toggleNav}/>
                        <NavLink to="/home" className="text-center text-white p-2" activeClassName="active bg-active-menu">Home</NavLink>
                        <NavLink to="/usuarios" className="text-center text-white p-2" activeClassName="active bg-active-menu">Usuarios</NavLink>
                        
                        <a className={this.state.statusn === true ?  "text-white p-2 drop active bg-active-menu flex justify-center items-center" :"text-white p-2 drop flex justify-center items-center"} onClick={this.droptownnut}>Nutrición {this.state.statusn === true ?  <i className="material-icons">expand_less</i> :<i className="material-icons">expand_more</i>}</a>
                        <NavLink to="/nutricion" className={this.state.statusn === true ?  this.state.stylen : "hidden" } activeClassName="active bg-active-menu">Aplicación Nutrición</NavLink>
                        <NavLink to="/referencia-nutricion-cultivo" className={this.state.statusn === true ?   this.state.stylern : "hidden" } activeClassName="active bg-active-menu">Referencia Nutrición Cultivo</NavLink>
                        <NavLink to="/productos-nutricion" className={this.state.statusn === true ?   this.state.stylepn : "hidden" } activeClassName="active bg-active-menu">Productos</NavLink>
                        <NavLink to="/nutrientes" className={this.state.statusn === true ?   this.state.stylenn : "hidden" } activeClassName="active bg-active-menu">Nutrientes</NavLink>

                        <a className={this.state.statuspl === true ?  "text-white p-2 drop active bg-active-menu flex justify-center items-center" :"text-white p-2 drop flex justify-center items-center"} onClick={this.droptownpl}>Plagas y Enfermedades {this.state.statuspl === true ?  <i className="material-icons">expand_less</i> :<i className="material-icons">expand_more</i>}</a>
                        <NavLink to="/plagas-enfermedades" className={this.state.statuspl === true ?  this.state.stylepl : "hidden" } activeClassName="active bg-active-menu">Plagas y Enfermedades</NavLink>
                        <NavLink to="/aplicacion-plagas-enfermedades" className={this.state.statuspl === true ?  this.state.styleapl : "hidden" } activeClassName="active bg-active-menu">Aplicación Plagas y Enfermedades</NavLink>
                        {/*<NavLink to="/productos" className={this.state.statuspl === true ?  this.state.styleapl : "hidden" } activeClassName="active bg-active-menu">Productos</NavLink>*/}

                        <NavLink to="/cultivo-temporadas" className="text-center text-white p-2" activeClassName="active bg-active-menu">Cultivo y temporadas</NavLink>
                        <NavLink to="/ordenes" className="text-center text-white p-2" activeClassName="active bg-active-menu">Ordenes</NavLink>
                        <NavLink to="/precio-dolar" className="text-center text-white p-2" activeClassName="active bg-active-menu">Precio Dolar</NavLink>
                        <NavLink to="/parametros-humedad" className="text-center text-white p-2" activeClassName="active bg-active-menu">Parametros de Humedad</NavLink>
                    </aside>
                ) : '' }
                { this.props.user.is_quimico ? ( 
                    <aside className={`transform top-0 left-0 w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${this.state.navVisible ? "translate-x-0": "-translate-x-full"}`}>
                        <img src={logo} className="mx-auto my-2 p-2 pb-8" onClick={this.toggleNav}/>
                        <NavLink to="/home" className="text-center text-white p-2" activeClassName="active bg-active-menu">Home</NavLink>
                    </aside>
                ) : '' }
                { this.props.user.is_productor ? ( 
                    <aside className={`transform top-0 left-0 w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${this.state.navVisible ? "translate-x-0": "-translate-x-full"}`}>
                        <img src={logo} className="mx-auto my-2 p-2 pb-8" onClick={this.toggleNav}/>
                        <NavLink to="/home" className="text-center text-white p-2" activeClassName="active bg-active-menu">Home</NavLink>                        
                        <NavLink to="/ordenes-productor" className="text-center text-white p-2" activeClassName="active bg-active-menu">Ordenes</NavLink>
                        <NavLink to="/agrocostos" className="text-center text-white p-2" activeClassName="active bg-active-menu">Agrocostos</NavLink>
                    </aside>
                ) : '' }
            
                
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication
    return {
        user, token
    }
}

export default withRouter(connect(mapStateToProps)(Navigation))