import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layouts/Layout";
import connect from "react-redux/es/connect/connect";
import {url_api} from "../../constants";
import { Redirect , Route} from "react-router-dom";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import './agrocostos.css';
import { Line } from 'react-chartjs-2';
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
const customStyles = {
    content : {
      top                   : '10%',
      left                  : '15%',
      right                 : '15%',
      botton                : '15%' 
    }
  };

class Agrocostos extends Component {
    

    constructor() {
        super();
        
        this.state = {
            agros : [],
            agrosback : [],
            ranchos: [],
            temporadas: [],
            cultivos:[],
            variedades: [], 
            cajas: [],
            fechasb: [],
            ventas:[],
            
            temporada_id: '',
            no_cajas:'',
            rancho_id: '',
            responsable: '',
            precio: '',
            presentacion: '',
            cultivo_id: '',
            variedad_id: '',
            sector: '',
            medio: '',

            showModal: false,
            preciot: '',
            ventat: '',
            cajast: '',
            currentPage: 1,
            todosPerPage: 10,
            text:  '',
            editing: false,
            fechamax: '',
            fechamin: '',
            id:'',            
            vacio: false
        }
        //this.url = 'http://127.0.0.1:5000';
        //this.url = 'https://testapi.green-up.xyz';
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClicknext = this.handleClicknext.bind(this);
        this.handleClickprev = this.handleClickprev.bind(this);
    }
   
   

    componentDidMount= async () => {
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}agrocostos/`, options);
        const agros = await res.json(); 

        const resranchos = await fetch(`${url_api}ranchos_productor/${this.props.user.id}`, options);
        const ranchos = await resranchos.json(); 

        const restemp = await fetch(`${url_api}temporadas/`, options);
        const temporadas = await restemp.json();  

        const rescult = await fetch(`${url_api}cultivos/`, options);
        const cultivos = await rescult.json(); 
        
        if(!agros.detail){       
            var preciot= 0;var ventat= 0;var cajast= 0;
            for(let x in agros){preciot += agros[x].precio;}
            for(let x in agros){ventat += agros[x].venta_total;}
            for(let x in agros){cajast += agros[x].no_cajas;}

            let cajas = [];
            agros.map((x) => { 
                cajas.push(x.no_cajas)     
            })
            let ventas= [];
            agros.map((x) => { 
                ventas.push(x.venta_total)     
            })
            let fechas = [];
            agros.map((x) => { 
                fechas.push(new Date(x.fecha+'T00:00:00'))     
            })

            let fechasb = [];
            agros.map((x) => { 
                fechasb.push(x.fecha)     
            })

            var option = { day: '2-digit', month: '2-digit',year: 'numeric' };
            var max = new Date(Math.max(...fechas)).toLocaleString('es-MX', option).replace(/\//g, '-');
            var fechamax=max.split("-").reverse().join("-");
            
            var min = new Date(Math.min(...fechas)).toLocaleString('es-MX', option).replace(/\//g, '-');
            var fechamin=min.split("-").reverse().join("-");

            this.setState({
                agros: agros,
                agrosback: agros,
                ranchos:ranchos.ranchos,
                temporadas: temporadas, 
                cultivos: cultivos,
                preciot:preciot,
                ventat: ventat,
                cajast: cajast,
                fechamax:fechamax,
                fechamin:fechamin,
                cajas:cajas,
                fechasb:fechasb,
                ventas:ventas,
            });
        }
    }
   
    handleChange = async (event) =>{
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});        
        
        if(nam == 'cultivo_id'){
            const options = {
                headers: {
                    'Authorization': `Token ${this.props.token}`
                }
            }
            const resvari = await fetch(`${url_api}variedades/`, options);
            const vari = await resvari.json();
            const variedades = vari.filter(vari => vari.cultivo == this.state.cultivo_id);
        
            this.setState({
                variedades: variedades
            });
        }
            
    }

    //crear y editar temporadas
    submit= async (e) =>{
        e.preventDefault();
        if (this.state.editing==false) {
            const res = await fetch(`${url_api}agrocostos/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    temporada_id: this.state.temporada_id,
                    no_cajas: this.state.no_cajas,
                    rancho_id: this.state.rancho_id,
                    responsable: this.state.responsable,
                    precio: this.state.precio,
                    presentacion: this.state.presentacion,
                    cultivo_id: this.state.cultivo_id,
                    variedad_id: this.state.variedad_id,
                    sector: this.state.sector,
                    medio: this.state.medio,
                })
            }) 
            const data = await res.json();
            if(data.id){
                  let timerInterval
                  MySwal.fire({
                      title: 'Guardando Agrocosto...',
                      showConfirmButton: false,
                      timer: 2000,
                      willOpen: () => {
                          MySwal.showLoading()
                                                 
                      },
                      willClose: () => {
                          clearInterval(timerInterval)
                      }
                  }).then((result) => {
                          if (
                              /* Read more about handling dismissals below */
                              result.dismiss === MySwal.DismissReason.timer
                          ) {
                              MySwal.fire({
                                  icon: 'success',
                                  title: 'Se Creo Correctamente el Agrocosto',
                                  showConfirmButton: true,
                              })                        
                          }
                  })
                  this.componentDidMount();
                  this.handleCloseModal();
                  this.setState({
                      temporada_id: '',
                      no_cajas: '',
                      rancho_id: '',
                      responsable: '',
                      precio: '',
                      presentacion: '',
                      cultivo_id: '',
                      variedad_id: '',
                      sector: '',
                      medio: '',
                  })
            }else{
                MySwal.fire({
                    icon: 'warning',
                    title: [data.presentacion],
                    showConfirmButton: true
                  })
            }
            console.log(data);
            
        } else {
            const id=this.state.id;
           
            const res = await fetch(`${url_api}agrocostos/${id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${this.props.token}`
                },
                body: JSON.stringify({
                    temporada_id: this.state.temporada_id,
                    no_cajas: this.state.no_cajas,
                    rancho_id: this.state.rancho_id,
                    responsable: this.state.responsable,
                    precio: this.state.precio,
                    presentacion: this.state.presentacion,
                    cultivo_id: this.state.cultivo_id,
                    variedad_id: this.state.variedad_id,
                    sector: this.state.sector,
                    medio: this.state.medio,
                }),
              });
              const data = await res.json();
              if(data.id){
                    let timerInterval
                    MySwal.fire({
                        title: 'Actualizando Agrocosto...',
                        showConfirmButton: false,
                        timer: 2000,
                        willOpen: () => {
                            MySwal.showLoading()
                                                   
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                            if (
                                /* Read more about handling dismissals below */
                                result.dismiss === MySwal.DismissReason.timer
                            ) {
                                MySwal.fire({
                                    icon: 'success',
                                    title: 'Se Actualizo Correctamente el Agrocosto',
                                    showConfirmButton: true,
                                })                        
                            }
                    })
                    this.componentDidMount();
                    this.handleCloseModal();
                    this.setState({
                        temporada_id: '',
                        no_cajas: '',
                        rancho_id: '',
                        responsable: '',
                        precio: '',
                        presentacion: '',
                        cultivo_id: '',
                        variedad_id: '',
                        sector: '',
                        medio: '',
                    })
                    this.state.editing = false;
                    this.state.id= "";
              }else{
                  MySwal.fire({
                      icon: 'warning',
                      title: [data.presentacion],
                      showConfirmButton: true
                    })
              }
            
            console.log(data);
        }     
        

       

    }
    editAgro = async (id) => {
        this.setState({ showModal: true });
        const options = {
            headers: {
                'Authorization': `Token ${this.props.token}`
            }
        }
        const res = await fetch(`${url_api}agrocostos/${id}`, options);
        const agros = await res.json();

        const resvari = await fetch(`${url_api}variedades/`, options);
        const vari = await resvari.json();
        const variedades = vari.filter(vari => vari.cultivo == agros.cultivo.id);
    
        this.state.editing = true;
        this.state.id= id;
                
        this.setState({
            temporada_id: agros.temporada.id,
            no_cajas: agros.no_cajas,
            rancho_id: agros.rancho.id,
            responsable: agros.responsable,
            precio: agros.precio,
            presentacion: agros.presentacion,
            cultivo_id: agros.cultivo.id,
            variedades:variedades,
            variedad_id: agros.variedad.id,
            sector: agros.sector,
            medio: agros.medio,
        })
       console.log(agros);  
        
        
      };
    deleteAgro = async (id) => {
        MySwal.fire({
            title: 'Eliminar Registro',
            text: "Estas seguro, No Podras Recuperarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Borrar!'
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${url_api}agrocostos/${id}/`, {
                    method: "DELETE",
                    headers: {
                        'authorization': `Token ${this.props.token}`
                    }
                  });
              MySwal.fire(
                'Borrado!',
                'El Registro ha sido Elimidado Correctamente.',
              );
              this.componentDidMount();
            }
            this.componentDidMount();
          })
          
      };

    handleOpenModal () {
        this.setState({ showModal: true });
      }
      
    handleCloseModal () {
        this.setState({ showModal: false });
      }
      
    handleClicknext(event) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.agros.length / this.state.todosPerPage); i++) {
          pageNumbers.push(i);
        }
        
        console.log(pageNumbers.length);
        
        if (pageNumbers.length !== this.state.currentPage){
            const next = this.state.currentPage+1;
            this.setState({
                currentPage: next
             });
        }
    }
    
    handleClickprev(event) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.agros.length / this.state.todosPerPage); i++) {
          pageNumbers.push(i);
        }
        console.log(pageNumbers);
        
        
        if (1 !== this.state.currentPage){
            const prev = this.state.currentPage-1;
            this.setState({
                currentPage: prev
             });
             
        }
    }
    
    filter(event){
        var text = event.target.value;
        const data = this.state.agrosback;

        
        const newData = data.filter(function(item){
            const itemDataNombre = item.fecha.toUpperCase();
            /*const itemDataApellido = item.last_name.toUpperCase();
            const itemDataCod = item.id;
            const campo = itemDataNombre+" "+itemDataApellido+" "+itemDataCod;*/
            const textDate = text.toUpperCase();
            const numDate = text;
            return itemDataNombre.indexOf(textDate||numDate) > -1;
        });
        var preciot= 0;var ventat= 0;var cajast= 0;
        for(let x in newData){preciot += newData[x].precio;}
        for(let x in newData){ventat += newData[x].venta_total;}
        for(let x in newData){cajast += newData[x].no_cajas;}
         
        if (newData.length===0){
            const vacio = true;
            this.setState({
                text: text,
                agros:newData,
                preciot:preciot,
                ventat: ventat,
                cajast: cajast,
                vacio:vacio
            });
        }else{
            const vacio = false;
            this.setState({
                text: text,
                agros:newData,
                preciot:preciot,
                ventat: ventat,
                cajast: cajast,
                vacio:vacio
            });   
        }  
        console.log(text);   
    } 
    render() {
        
        
        let clase = '';
         
        if(this.props.user.is_administrador) {
            clase = 'repo-productor';
        }
        if(this.props.user.is_ingeniero_de_Campo) {
            clase = 'ingenieros';
        }
        if(this.props.user.is_quimico) {
            clase = 'repo-productor10';
        }
        if(this.props.user.is_productor) {
            clase = 'repo-agrocostos h-full';
        }

        const { agros, currentPage, todosPerPage } = this.state;
         
        // Logic for displaying current todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const agrocostos = agros.slice(indexOfFirstTodo, indexOfLastTodo);

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(agros.length / todosPerPage); i++) {
          pageNumbers.push(i);
        }
        const data = {
            
          labels: this.state.fechasb,
          
          datasets: [{
            label: 'No Cajas',
            data: this.state.cajas,
            fill: true,
            backgroundColor: '#d1f1a9',
            borderColor: '#A6D66B',
            pointBackgroundColor: '#006A4C',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)'
          }, {
            label: 'Ventas Total',
            data: this.state.ventas,
            fill: true,
            backgroundColor: '#f1de98',
            borderColor: '#cfad2f',
            pointBackgroundColor: '#F4D35B',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
          }
        
        ]
          
        };
        
        const options =   {
           
            legend:{
                display:true,
                position:'top',
                fontColor: '#A6D66B'
            }
        }
        return (

            <Layout className={clase} classText={clase}>
                
                
                <div className=" mb-4">
                    <div className="flex items-center">
                        <h2 className="font-bold text-primary">Agrocostos </h2>
                        <button className="tab-link text-white  bg-green-500  rounded-full p-3 m-2" onClick={this.handleOpenModal}>Agregar Agrocosto</button>
                    </div>
                    <Modal 
                        isOpen={this.state.showModal}
                        ariaHideApp={false}
                        contentLabel="Modal #1 Global Style Override Example"
                        onRequestClose={this.handleCloseModal}
                        style={customStyles}
                        >
                        <div  className="flex justify-between">
                            <p>Agrega Agrocosto</p>
                            <button className="tab-link text-white  bg-red-500  rounded-full py-1 px-3 " onClick={this.handleCloseModal}>Cerrar</button>
                        </div>    
                        <div >
                            <form onSubmit={this.submit} >
                                <div className=" grid grid-cols-2 gap-4 rounded-lg bg-white flex ">
                                    {/*<div className="m-4 flex items-center"> <img className="qr-img mx-5" src=""/><p>ID Productor</p><p>0000001</p></div>*/}
                                    <div className=" m-4">   
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">Rancho</label>
                                                <select id="rancho_id" name="rancho_id" value={this.state.rancho_id}  onChange={this.handleChange} className="w-full text-green-500 focus:outline-none input-agro col-span-2  select-agro" 
                                                title="Favor de Seleccionar un Rancho de la Lista" >
                                                    <option value="">Selecciona una Rancho</option>
                                                    {this.state.ranchos.map((rancho, i)=> {
                                                        return (
                                                        <option key={i} value={rancho.id}>{rancho.nombre}</option>
                                                        );
                                                    })}
                                                </select>  
                                        </div>    
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">Responsable</label>
                                                <input
                                                    id="responsable"
                                                    name="responsable"
                                                    type="text"
                                                    value={this.state.responsable} 
                                                    onChange={this.handleChange}
                                                    className="w-full text-green-500 focus:outline-none col-span-2  input-agro"
                                                    placeholder="Responsable ej. Juan Torres"
                                                    required
                                                    title="Favor de Agregar al Responsable. ej. Juan Torres"
                                                />                                                
                                        </div>     
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">Precio</label>
                                                <input
                                                    id="precio"
                                                    name="precio"
                                                    type="text"
                                                    value={this.state.precio} 
                                                    onChange={this.handleChange}
                                                    className="w-full text-green-500 focus:outline-none col-span-2  input-agro"
                                                    placeholder="Precio ej. 1200"
                                                    required
                                                    title="Favor de Agregar el Precio. ej. 1200"
                                                />                                                
                                        </div>   
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">No cajas</label>
                                                <input
                                                    id="no_cajas"
                                                    name="no_cajas"
                                                    type="text"
                                                    value={this.state.no_cajas} 
                                                    onChange={this.handleChange}
                                                    className="w-full text-green-500 focus:outline-none col-span-2  input-agro"
                                                    placeholder="No cajas ej. 20"
                                                    required
                                                    title="Favor de Agregar el Número de Cajas. ej. 20"
                                                />                                                
                                        </div> 
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">Sector</label>
                                                <input
                                                    id="sector"
                                                    name="sector"
                                                    type="text"
                                                    value={this.state.sector} 
                                                    onChange={this.handleChange}
                                                    className="w-full text-green-500 focus:outline-none col-span-2  input-agro"
                                                    placeholder="sector ej. 1"
                                                    required
                                                    title="Favor de Agregar un Sector. ej. 1"
                                                />                                                
                                        </div>  
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                                <label htmlFor="name" className="self-center nut-title-res">Presentación</label>
                                                <input
                                                    id="presentacion"
                                                    name="presentacion"
                                                    type="text"
                                                    value={this.state.presentacion} 
                                                    onChange={this.handleChange}
                                                    className="w-full text-green-500 focus:outline-none col-span-2  input-agro"
                                                    placeholder="Presentación ej. 2 LB"
                                                    required
                                                    title="Favor de Agregar la Presentacion en Mayúsculas. ej. 2 LB"
                                                />                                                
                                        </div>                            
                                    </div>  
                                    <div className=" m-4"> 
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                            <label htmlFor="name" className="self-center nut-title-res">Cultivo</label>
                                            <select id="cultivo_id" name="cultivo_id" value={this.state.cultivo_id}  onChange={this.handleChange} className="w-full text-green-500 focus:outline-none col-span-2 input-agro select-agro"
                                            title="Favor de Seleccionar un Cultivo de la Lista" >
                                                <option value="">Selecciona un Cultivo</option>
                                                {this.state.cultivos.map((cultivo, i)=> {
                                                        return (
                                                        <option key={i} value={cultivo.id}>{cultivo.nombre}</option>
                                                        );
                                                    })}
                                            </select>  
                                        </div> 
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                            <label htmlFor="name" className="self-center nut-title-res">Variedad</label>
                                            <select id="variedad_id" name="variedad_id" value={this.state.variedad_id}  onChange={this.handleChange} className="w-full text-green-500 focus:outline-none col-span-2  input-agro select-agro"
                                            title="Favor de Seleccionar una Variedad de la Lista, Debes Tener un Cultivo" >
                                                <option value="">Selecciona una Variedad</option>
                                                {this.state.cultivo_id === '' ?  <option value="">Selecciona un cultivo primero</option>: 
                                                
                                                this.state.variedades.map((variedad, i)=> {
                                                        return (
                                                        <option key={i} value={variedad.id}>{variedad.nombre}</option>
                                                        );
                                                    })}
                                            </select>  
                                        </div>                       
                                        
                                        <div className="my-1 mx-2 grid grid-cols-3 gap-4 ">
                                            <label htmlFor="name" className="self-center nut-title-res">Temporada</label>
                                            <select id="temporada_id" name="temporada_id" value={this.state.temporada_id} onChange={this.handleChange} className="w-full text-green-500 focus:outline-none col-span-2  input-agro select-agro"
                                            title="Favor de Seleccionar la Temporada de la Lista" >
                                                <option value="">Selecciona una Temporada</option>
                                                {this.state.temporadas.map((temporada, i)=> {
                                                        return (
                                                        <option key={i} value={temporada.id}>{temporada.nombre}</option>
                                                        );
                                                    })}
                                            </select>  
                                        </div>   
                                        <div className="my-6 mx-2 grid grid-cols-3 gap-4 ">
                                            <label htmlFor="name" className="self-center nut-title-res">Medio</label>
                                            <select id="medio" name="medio" value={this.state.medio}  onChange={this.handleChange} className="w-full text-green-500 focus:outline-none  col-span-2 input-agro select-agro"
                                            title="Favor de Seleccionar un Medio de la Lista" >
                                                <option value="">Selecciona una Medio</option>
                                                <option value="sutrato">Sutrato</option>
                                                <option value="suelo">Suelo</option>
                                            </select>  
                                        </div>                       
                                    </div>
                                </div>
                                                            
                                <div className="">
                                    <button type="submit" className="inline-flex bg-green-600 text-white rounded-full h-8 px-3 justify-center items-center ">{this.state.editing ? "Actualizar Agrocosto" : "Guardar Agrocosto"}</button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                    <div className="grid grid-cols-12 gap-4 ">
                        
                        <div className="container mx-auto col-span-12">
                            <div className="pt-8">
                                <div className="flex justify-between items-center text-center">
                                
                                    <div className="my-1 mx-2 flex items-center">
                                        <h2 className="font-bold text-primary mr-4">Listado de Agrocostos </h2>                
                                        <div className="border-2 border-green-200 text-green-500 rounded text-center flex items-center search bg-white">
                                        <i className="material-icons">search</i>
                                        <input type='date' value={this.state.text} onChange={(text) => this.filter(text)}
                                            min={this.state.fechamin} max={this.state.fechamax}
                                            className="in-search input-user"
                                            placeholder="Buscar por Fecha"
                                            title="Favor de Seleccionar Una Fecha para Ver los Registros de ese Dia" 
                                        /> 
                                        
                                        </div>
                                        <button  
                                            className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                            value='' onClick={(text) => this.filter(text)}
                                            title="Favor de Limpiar despues de la busqueda" >Limpiar Busqueda</button>
                                    </div>
                                    <div>
                                        <button  
                                            className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                            onClick={(e) => this.handleClickprev(this.state.currentPage)}>Anterior</button>
                                        <p className="bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center">
                                            {this.state.currentPage} 
                                        </p>  
                                        {/*pageNumbers.map(number => {
                                            return (
                                                
                                                <button key={number}
                                                id={number}
                                                onClick={this.handleClick}
                                                className={number == this.state.currentPage ? "bg-green-500 w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-white rounded-full  inline-flex items-center justify-center": "bg-white w-6 h-6 p-4 m-2 text-sm font-bold tracking-wider text-black border-2 border-green-500 rounded-full hover:bg-green-500 inline-flex items-center justify-center"}
                                                >{number}</button>
                                                
                                            );
                                        })*/}                                     
                                        <button  
                                            className="bg-white px-4 m-2 text-sm font-bold tracking-wider text-gray-600 border-2 border-green-400 rounded-full hover:bg-green-400 inline-flex items-center justify-center"
                                            onClick={(e) => this.handleClicknext(this.state.currentPage)}>Siguiente</button>
                                        <p>Páginas {this.state.currentPage} de {pageNumbers.length} </p>
                                    </div>
                                </div>
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-xl overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Fecha</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">No Cajas</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Presentación</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Rancho</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Cultivo</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Variedad</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Sector</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Medio Precio</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Venta Total Acumulado</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider"></th>
                                                </tr>
                                            </thead>
                                            {this.state.vacio ? 
                                            <tbody >
                                                <tr><td colSpan="9" className="vacio-search text-center">No se encontraron resultados en esta fecha,<br></br> Por favor realiza una nueva busqueda</td></tr>
                                            </tbody>:
                                            <tbody>
                                            {agrocostos.map((x, i)=> {
                                                return (
                                                    <tr key={i}>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.fecha}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.no_cajas}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.presentacion}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.rancho.nombre}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.cultivo.nombre}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.variedad.nombre}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">{x.sector}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">$ {x.precio}</p>
                                                        </td>
                                                        <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                            <p className="text-gray-900 whitespace-no-wrap">$ {x.venta_total}</p>
                                                        </td>
                                                        <td className="px-5 py-2 whitespace-no-wrap border-b border-gray-500"> 
                                                            <button onClick={(e) => this.editAgro(x.id)} className="inline-block py-1 px-2 text-center text-white transition bg-yellow-400 rounded-full shadow ripple hover:shadow-lg hover:bg-yellow-300 focus:outline-none">
                                                                <i className="material-icons">edit</i>
                                                            </button>
                                                            <button onClick={(e) => this.deleteAgro(x.id)} className="inline-block py-1 px-2 mx-1 text-center text-white transition bg-red-500 rounded-full shadow ripple hover:shadow-lg hover:bg-red-600 focus:outline-none">
                                                                <i className="material-icons">delete</i>
                                                            </button>
                                                            
                                                        </td>
                                                    </tr>
                                                );
                                            })}    
                                            </tbody>}
                                        </table>
                                    </div>
                                    
                                </div>
                                <table className="min-w-full">
                                    <thead  className="totales-ind">
                                        <tr className="tr-rp ">
                                            <th className="total-text px-6 py-2 bg-totales-ind rounded-l-full">Total</th>
                                            <th className="total-num px-8 py-2 text-white bg-totales-ind rounded-r-full">{this.state.cajast}</th>
                                            <th className="total-num px-40 py-2 text-transparent"></th>
                                            <th className="total-num px-40 py-2 text-transparent"></th>
                                            <th className="total-text px-4 py-2 bg-totales-ind rounded-l-full">Total</th>
                                            <th className="total-num px-8 py-2 text-white bg-totales-ind">$ {this.state.preciot}</th>
                                            <th className="total-num px-10 py-2 text-white bg-totales-ind rounded-r-full">$ {this.state.ventat}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        
                        <div className="container mx-auto col-span-4  ">
                            <div className="pb-8">
                                                            
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-xl overflow-hidden bg-white pb-10">
                                        <table className="min-w-full leading-normal bg-white ">
                                            <thead >
                                                <tr className="tr-rp">
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Fecha</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Suma No Cajas</th>
                                                    <th className="p-5 text-left text-xs text-green-600 uppercase tracking-wider">Suma Venta Total</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {agrocostos.map((x, i)=> {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.fecha}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.no_cajas}</p>
                                                            </td>
                                                            <td className="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                                                                <p className="text-gray-900 whitespace-no-wrap">{x.venta_total}</p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}                                                    
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                <table className="min-w-full flex justify-center items-center text-center">
                                    <thead  className="totales-ind">
                                        <tr className="tr-rp ">
                                            <th className="total-text px-6 py-2 bg-totales-ind rounded-l-full">Total</th>
                                            <th className="total-num px-12 py-2 text-white bg-totales-ind">{this.state.cajast}</th>
                                            <th className="total-num px-12 py-2 text-white bg-totales-ind rounded-r-full">$ {this.state.ventat}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        

                        <div className="container mx-auto col-span-8">
                            <div className="pb-8">
                                
                                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                                    <div className="inline-block min-w-full shadow rounded-xl overflow-hidden bg-grafic p-10">
                                    <Line data={data}
                                        width={90}
                                        height={50}
                                        options={options}
                                        /> 
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                      
                        
                    
                    </div>
                </div>
                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    const {user, token} = state.authentication;
    return {
        user, token
    }
};

export default withRouter(connect(mapStateToProps)(Agrocostos))
