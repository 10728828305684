import React from "react";
import {connect} from 'react-redux';

import "./Navigation.css";
import menu from "../assets/img/icon-menu.svg";
import logo from '../assets/img/logo-white.svg'
import {authActions} from "../rcrx/actions";
import {withRouter} from "react-router-dom";
import {NavLink} from "react-router-dom";



class NavigationDiag extends React.Component {
    constructor(props) {
        super(props);
        const auth = JSON.parse(localStorage.getItem('auth'));
        this.state = {
            auth: auth,
            navVisible: false
        };
        this.logout = this.logout.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
    }

    logout() {
        const {dispatch} = this.props;
        dispatch(authActions.logout());
        this.props.history.push("/");
    }

    toggleNav() {
        this.setState({ navVisible: !this.state.navVisible })
    } 

    render() {
        return (
            <> 
               <div className="flex w-full p-2 pl-5 bg-altaproductor items-center justify-between">
               <div className="flex">
                 <img src={menu} className="w-10 mr-4" onClick={this.toggleNav}/>
                  <img src={logo} className="mr-4 logo"/>
                    </div>
                    <div className="font-bold text-primary">
                      <p>nuevo layout diagnostico</p>
                     </div>
                   <div>
                   <button className="link text-primary" onClick={this.logout}>Salir</button>
                     </div>
                  </div>
            <aside className={`transform top-0 left-0 w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${this.state.navVisible ? "translate-x-0": "-translate-x-full"}`}>
            <img src={logo} className="mx-auto my-2 p-2 pb-8" onClick={this.toggleNav}/> 
            <NavLink to="/home" className="text-center text-white p-2" activeClassName="active bg-active-menu">Home</NavLink>
            <NavLink to="/usuarios" className="text-center text-white p-2" activeClassName="active bg-active-menu">Usuarios</NavLink>
            <NavLink to="/nav" className="text-center text-white p-2" activeClassName="active bg-active-menu">Hola</NavLink>
          </aside>

          <div class="card-progress">
             <ul class="menu-progress">
             <li>  
             <div className="box-space-tiny">   
              <button class="outline-progress"><i class="small material-icons">account_circle</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress"  ><i class="small material-icons">gps_fixed</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress"><i class="small material-icons">ac_unit</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress"><i class="small material-icons">brightness_3</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress" ><i class="small material-icons">check_box</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress" ><i class="small material-icons">cloud_queue</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress" ><i class="small material-icons">bubble_chart</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress" ><i class="small material-icons">change_history</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">   
              <button class="outline-progress"  ><i class="small material-icons">arrow_back</i></button>
             </div>
            </li>
            <li>  
            <div className="box-space-tiny">      
              <button class="outline-progress" ><i class="small material-icons">arrow_forward</i></button>
             </div>
            </li>
              </ul>
             </div>


            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {user} = state.authentication
    return {
        user
    }
}

export default withRouter(connect(mapStateToProps)(NavigationDiag)) 